import React from 'react';
import PropTypes from 'prop-types';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { Skeleton } from '@material-ui/lab';
import { texts } from '../../../texts/GeneralTexts';

const TeamTableBody = (props) => {
    const { loading, error } = props;

    if (loading)
        return (
            <>
                <TableRow>
                    <TableCell colSpan={10}>
                        <Skeleton />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell colSpan={10}>
                        <Skeleton />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell colSpan={10}>
                        <Skeleton />
                    </TableCell>
                </TableRow>
            </>
        );

    if (error)
        return (
            <TableRow>
                <TableCell colSpan={10}>{texts.error}</TableCell>
            </TableRow>
        );

    return props.children;
};

TeamTableBody.propTypes = {
    loading: PropTypes.bool,
    error: PropTypes.object,
};

export default TeamTableBody;
