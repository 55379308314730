import { Response } from '../../../util/DataTypes/Response';
import { api } from '../AxiosConfig/Config';
import { downloadFile } from '../Files/FileActions';
import { fetchMutator } from './FinalizationMutator';

export function fetchFinalizationData(proposalId) {
    return function () {
        return new Promise(async (resolve, reject) => {
            try {
                const { data } = await api().get(`/projects/${proposalId}`);
                const { data: accountData } = await api().get(
                    `/action/account-profile`
                );
                resolve(fetchMutator(data, accountData));
            } catch (e) {
                const status = e.response?.status;
                if (status > 500) {
                    resolve({ proposalPdf: undefined });
                    return;
                }
                reject(
                    Response.Error('Abschluss konnte nicht geladen werden', e)
                );
            }
        });
    };
}

export function persistFinalizationData({ proposalId, ...data }) {
    return new Promise(async (resolve, reject) => {
        try {
            await api().patch(`/projects/${proposalId}`, data, {
                headers: {
                    'Content-Type': 'application/merge-patch+json',
                },
            });
            await api().put(`/action/project/${proposalId}/apply-transition`, {
                transition: 'to_step_8',
            });

            resolve(
                Response.Success('Der Antrag wurde erfolgreich gespeichert')
            );
        } catch (e) {
            reject(
                Response.Error(
                    'Der Abschluss konnte nicht gespeichert werden',
                    e
                )
            );
        }
    });
}

export function downloadPdf(proposalId) {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await downloadFile(
                `/projects/${proposalId}/pdf-preview`
            );

            resolve(response);
        } catch (e) {
            reject(Response.Error('Datei konnte nicht geladen werden', e));
        }
    });
}

export function downloadFinalPdf(pdfMediaIri) {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await downloadFile(
                `${pdfMediaIri}/download`,
                true
            );

            resolve(response);
        } catch (e) {
            reject(Response.Error('Datei konnte nicht geladen werden', e));
        }
    });
}
