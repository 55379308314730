import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import 'moment/locale/de';
import { FormControl, InputLabel, Typography } from '@material-ui/core';
import FormHelperText from '@material-ui/core/FormHelperText';
import { useMobilityEditStyles } from '../../../styles/components/forms/MobilityForm/MobilityEditStyles';
import {
    mobilityFormTexts,
    mobilityFormTexts as mft,
} from '../../../texts/Forms/MobilityFormTexts';
import moment from 'moment';
import { getProposalDuration } from '../../../util/Forms/MobilityFunctions';
import Grid from '@material-ui/core/Grid';
import MomentUtils from '@date-io/moment';
import { Alert } from '@material-ui/lab';
import { useApplicationRounds } from '../../../stores/ApplicationRounds.store';

const MobilityDurationPicker = (props) => {
    const {
        control,
        errors,
        label,
        name,
        rules,
        triggerValidation,
        required,
    } = props;
    const classes = useMobilityEditStyles();

    const initialDate1 = control.defaultValuesRef.current.duration
        ? moment(control.defaultValuesRef.current.duration[0])
        : undefined;
    const initialDate2 = control.defaultValuesRef.current.duration
        ? moment(control.defaultValuesRef.current.duration[1])
        : undefined;

    const [date1, setDate1] = useState(initialDate1);
    const [date2, setDate2] = useState(initialDate2);
    const [numberOfDays, setNumberOfDays] = useState(0);

    useEffect(() => {
        if (date1 && date2) {
            setNumberOfDays(date2.diff(date1, 'days') + 1);
        }
    }, [date1, date2, setNumberOfDays]);

    const { currentApplicationRound } = useApplicationRounds((state) => ({
        currentApplicationRound: state.currentApplicationRound,
    }));

    const proposalTime = getProposalDuration(currentApplicationRound);

    return (
        <>
            <InputLabel style={{ marginTop: '.8em', marginBottom: '.6em' }}>
                {mobilityFormTexts.fields.duration.label}
            </InputLabel>
            <Alert severity="info">{mft.duration.info}</Alert>
            <Grid container className={classes.input} spacing={2}>
                <Grid item>
                    <InputLabel>
                        {label[0]} {required ? '*' : ''}
                    </InputLabel>
                    <FormControl className={classes.input}>
                        <Controller
                            control={control}
                            name={name[0]}
                            rules={{
                                validate: (value) =>
                                    rules.validate(value, 'start'),
                            }}
                            defaultValue={null}
                            render={(params) => (
                                <MuiPickersUtilsProvider
                                    utils={MomentUtils}
                                    libInstance={moment}
                                    locale={'de'}
                                >
                                    <>
                                        <DatePicker
                                            name={params.name}
                                            onChange={(e) => {
                                                setDate1(moment(e));
                                                params.onChange(e);
                                            }}
                                            onAccept={() =>
                                                triggerValidation([
                                                    name[0],
                                                    name[1],
                                                ])
                                            }
                                            value={params.value}
                                            placeholder="Datum auswählen"
                                            format="DD.MM.yyyy"
                                            autoOk
                                            cancelLabel="Abbrechen"
                                            minDate={proposalTime.start}
                                            maxDate={proposalTime.end}
                                            maxDateMessage={
                                                mft.duration.maxDateError
                                            }
                                            minDateMessage={
                                                mft.duration.minDateError
                                            }
                                        />
                                        {errors[0] && (
                                            <FormHelperText error={true}>
                                                {errors[0].message}
                                            </FormHelperText>
                                        )}
                                    </>
                                </MuiPickersUtilsProvider>
                            )}
                        />
                    </FormControl>
                </Grid>
                <Grid item>
                    <InputLabel>
                        {label[1]} {required ? '*' : ''}
                    </InputLabel>
                    <FormControl className={classes.input}>
                        <Controller
                            control={control}
                            name={name[1]}
                            rules={{
                                validate: (value) =>
                                    rules.validate(value, 'end'),
                            }}
                            defaultValue={null}
                            render={(params) => (
                                <MuiPickersUtilsProvider
                                    utils={MomentUtils}
                                    libInstance={moment}
                                    locale={'de'}
                                >
                                    <>
                                        <DatePicker
                                            name={params.name}
                                            onChange={(e) => {
                                                setDate2(moment(e));
                                                params.onChange(e);
                                            }}
                                            onAccept={() =>
                                                triggerValidation([
                                                    name[0],
                                                    name[1],
                                                ])
                                            }
                                            value={params.value}
                                            placeholder="Datum auswählen"
                                            format="DD.MM.yyyy"
                                            autoOk
                                            cancelLabel="Abbrechen"
                                            minDate={proposalTime.start}
                                            maxDate={proposalTime.end}
                                            maxDateMessage={
                                                mft.duration.maxDateError
                                            }
                                            minDateMessage={
                                                mft.duration.minDateError
                                            }
                                        />
                                        {errors[1] && (
                                            <FormHelperText error={true}>
                                                {errors[1].message}
                                            </FormHelperText>
                                        )}
                                    </>
                                </MuiPickersUtilsProvider>
                            )}
                        />
                    </FormControl>
                </Grid>
            </Grid>
            <Typography
                style={{ marginTop: '.4em', marginBottom: '.6em' }}
                variant="body1"
            >
                Anzahl der Aufenthaltstage: {numberOfDays}
            </Typography>
        </>
    );
};

MobilityDurationPicker.propTypes = {
    control: PropTypes.object,
    errors: PropTypes.array,
    name: PropTypes.array,
    clearErrors: PropTypes.func,
    label: PropTypes.array,
    rules: PropTypes.object,
    setValue: PropTypes.func,
    triggerValidation: PropTypes.func,
    required: PropTypes.bool,
};

MobilityDurationPicker.defaultProps = {
    required: false,
};

export default MobilityDurationPicker;
