import React from 'react';
import PropTypes from 'prop-types';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { texts } from '../../../texts/GeneralTexts';
import { Edit, Lock, LockOpen } from '@material-ui/icons';
import { teamTexts } from '../../../texts/components/Team/TeamTexts';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    root: {
        '& li svg': {
            marginRight: theme.spacing(2),
        },
    },
}));

const TeamTableActions = (props) => {
    const {
        handleEdit,
        handleApplicationRoundEdit,
        handleLock,
        teamMember,
    } = props;

    const classes = useStyles();

    const [anchorEl, setAnchorEl] = React.useState(undefined);

    const handleClick = (evt) => {
        setAnchorEl(evt.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(undefined);
    };

    const handleEditClick = () => {
        handleEdit(teamMember);
        handleClose();
    };

    const handleApplicationRoundEditClick = () => {
        handleApplicationRoundEdit(teamMember);
        handleClose();
    };

    const handleLockClick = () => {
        handleLock(teamMember.id, teamMember.isActive);
        handleClose();
    };

    return (
        <div>
            <IconButton aria-label={'Menü einblenden'} onClick={handleClick}>
                <MoreHorizIcon />
            </IconButton>
            <Menu
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClose}
                className={classes.root}
                aria-hidden={'true'}
            >
                <MenuItem onClick={handleEditClick}>
                    <Edit fontSize={'small'} />
                    {teamTexts.editTeamMember}
                </MenuItem>
                {!teamMember.isManager && (
                    <MenuItem onClick={handleApplicationRoundEditClick}>
                        <Edit fontSize={'small'} />
                        {teamTexts.editApplicationRound}
                    </MenuItem>
                )}
                {!teamMember.isManager && (
                    <MenuItem onClick={handleLockClick}>
                        {teamMember.isActive ? (
                            <>
                                <Lock fontSize={'small'} /> {texts.block}
                            </>
                        ) : (
                            <>
                                <LockOpen fontSize={'small'} /> {texts.unblock}
                            </>
                        )}
                    </MenuItem>
                )}
            </Menu>
        </div>
    );
};

TeamTableActions.propTypes = {
    handleEdit: PropTypes.func.isRequired,
    handleLock: PropTypes.func.isRequired,
    handleApplicationRoundEdit: PropTypes.func.isRequired,
    teamMember: PropTypes.object.isRequired,
};

export default TeamTableActions;
