import moment from 'moment';

export const texts = {
    title: 'Projektbeschreibung',
    headlines: {
        duration: 'Projektbeginn und -ende',
        projectNumber: 'Projektnummer',
        relevance: '1. Relevanz des Antrags',
        quality: '2. Qualität der Durchführung',
        cause: '3. Verbreitung und Wirkung',
    },
    info: {
        duration:
            'Durchführungszeitraum: In diesem Zeitraum können die Aktivitäten stattfinden, also die Aufenthalte inkl. ' +
            'Hin- und Rückflug sowie ggf. bezuschusste Vor- und/oder Nachbereitung.',
    },
    duration: (project) =>
        `Durchführungszeitraum: ${moment(project.begin).format(
            'DD.MM.yyyy'
        )} - ${moment(project.end).format('DD.MM.yyyy')}`,
    placeholder: {
        when_required: 'Nur im Bedarfsfall zu beantworten.',
        preparation_only:
            "Beantragen Sie ausschließlich einen vorbereitenden Besuch, geben Sie hier bitte 'nicht relevant' ein.",
    },
    fields: {
        title: {
            label: 'Projekttitel',
            error: 'Bitte Projekttitel angeben.',
        },
        isFirstProposal: {
            label:
                'Dies ist der erste Antrag der Einrichtung im Förderprogramm AusbildungWeltweit',
        },
        heardAbout: {
            label: 'Wie haben Sie von AWW erfahren?',
            error: 'Bitte angeben',
        },
        partnership: {
            title: 'a) Partnerschaft *',
            label: [
                {
                    type: 'p',
                    text:
                        'Bitte gehen Sie nacheinander auf die folgenden Punkte ein:',
                },
                {
                    type: 'list',
                    text:
                        '[li]Beschreiben Sie in wenigen Sätzen Ihre Einrichtung bzw. Ihr Unternehmen. Wenn Sie den Antrag als Mittlerorganisation stellen, gehen Sie auch auf die ' +
                        'Ausbildungsbetriebe oder -einrichtungen der Teilnehmenden ein.[li]',
                },
                {
                    type: 'list',
                    text:
                        '[li] Welche Ziele verfolgen Sie mit den Auslandsaufenthalten?[br]Gehen Sie dabei auf alle beantragten ' +
                        'Kategorien von Aufenthalten (Auszubildende, Ausbilder/-innen, Vorbereitende Besuche) ein.[li][li]Warum haben Sie sich ' +
                        'für die Zielländer und Partnereinrichtungen entschieden?[br]Beschreiben Sie die ' +
                        'Partnereinrichtungen in wenigen Sätzen. Handelt es sich im Zielland um eine Mittlerorganisation, ' +
                        'gehen Sie bitte auch auf die geplanten aufnehmenden Einrichtungen (Praktikumsbetriebe) ein.[li]',
                },
            ],
            error: 'Bitte angeben',
            maxLength: 8000,
        },
        participant: {
            title: 'b) Teilnehmende *',
            label: [
                {
                    type: 'p',
                    text:
                        'Welchen (ausbildungsbezogenen) Hintergrund haben die Teilnehmenden?',
                },
                {
                    type: 'p',
                    text:
                        'Geben Sie dabei auch an, in welchen konkreten Ausbildungsgängen sich die Teilnehmenden ' +
                        'befinden. Gehen Sie auf alle beantragten Kategorien von Aufenthalten ein. ',
                },
            ],
            error: 'Bitte angeben',
            maxLength: 5000,
        },
        preparation: {
            label: [
                {
                    type: 'p',
                    text: 'a) Wie werden die Auszubildenden vorbereitet? *',
                },
            ],
            error: 'Bitte angeben',
            maxLength: 4000,
        },
        stay: {
            label: [
                {
                    type: 'p',
                    text:
                        'b) Bitte beschreiben Sie die Auslandsaufenthalte vor Ort möglichst konkret nach Art der ' +
                        'beantragten Aufenthalte (Auszubildende, Ausbilder/-innen). Erläutern Sie dabei, was die ' +
                        'Teilnehmerinnen und Teilnehmer an Wissen, Fertigkeiten und Kompetenzen erwerben sollen. *',
                },
            ],
            error: 'Bitte angeben',
            maxLength: 4000,
        },
        support: {
            label: [
                {
                    type: 'p',
                    text:
                        'c) Wie stellen Sie für die Auszubildenden die fachliche Begleitung am Arbeitsplatz sowie ' +
                        'einen Ansprechpartner für die Freizeit sicher? *',
                },
            ],
            error: 'Bitte angeben',
            maxLength: 2000,
        },
        responsibility: {
            label: [
                {
                    type: 'p',
                    text:
                        'd) Bitte erläutern Sie, wer für die Planung und Umsetzung der Auslandsaufenthalte ' +
                        'verantwortlich ist und wie Sie das Management der Auslandsaufenthalte organisiert haben. *',
                },
            ],
            error: 'Bitte angeben.',
            maxLength: 2000,
        },
        preparationProposal: {
            label: [
                {
                    type: 'p',
                    text:
                        'e) Sollten Sie einen Antrag für einen vorbereitenden Besuch stellen: Wer führt den ' +
                        'vorbereitenden Besuch durch? Welche Absprachen sollen am Ende des vorbereitenden Besuchs ' +
                        'getroffen worden sein?',
                },
            ],
            maxLength: 2000,
        },
        accompany: {
            label: [
                {
                    type: 'p',
                    text:
                        'f) Sollten Sie für die Auszubildenden eine Begleitung beantragen, begründen Sie bitte, ' +
                        'warum dies erforderlich ist.',
                },
            ],
            maxLength: 2000,
        },
        evaluation: {
            label: [
                {
                    type: 'p',
                    text:
                        'a) Wie wird die Maßnahme nachbereitet und evaluiert? *',
                },
            ],
            error: 'Bitte angeben.',
            maxLength: 2000,
        },
        result: {
            label: [
                {
                    type: 'p',
                    text:
                        'b) Wie werden Ergebnisse aus der Maßnahme genutzt und welche Aktivitäten sehen Sie für ' +
                        'eine Verbreitung/Öffentlichkeitsarbeit vor?',
                },
                {
                    type: 'p',
                    text:
                        'Planen Sie, Partner in Netzwerken für Nutzung und Verbreitung einzubeziehen? *',
                },
            ],
            error: 'Bitte angeben',
            maxLength: 2000,
        },
        strategy: {
            label: [
                {
                    type: 'p',
                    text:
                        'c) Sind die Auslandsaufenthalte in eine längerfristige Strategie Ihrer Einrichtung ' +
                        'eingebunden und können Sie diese skizzieren? *',
                },
            ],
            error: 'Bitte angeben',
            maxLength: 4000,
        },
    },
};
