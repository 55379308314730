import create from 'zustand';

const initial = {
    applicationRounds: [],
    applicationRoundsById: {},
    currentApplicationRound: null,
};

const withFunctions = (set) => ({
    ...initial,
    setApplicationRounds: (applicationRounds) =>
        set((state) => {
            if (state.applicationRounds.length === 0)
                state.applicationRounds = applicationRounds;
        }),
    setApplicationRoundsById: (applicationRoundsById) =>
        set((state) => {
            if (!state.applicationRoundsById)
                state.applicationRoundsById = applicationRoundsById;
        }),
    setCurrentApplicationRound: (applicationRound) =>
        set((state) => {
            if (!state.currentApplicationRound)
                state.currentApplicationRound = applicationRound;
        }),
});

export const useApplicationRounds = create(withFunctions);
