import React from 'react';
import PropTypes from 'prop-types';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import { Box } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import makeStyles from '@material-ui/core/styles/makeStyles';
import withStyles from '@material-ui/core/styles/withStyles';
import Fade from '@material-ui/core/Fade';
import FormHelperText from '@material-ui/core/FormHelperText';

const useStyles = makeStyles((theme) => ({
    wrapper: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: theme.spacing(2),
    },
    label: {
        marginRight: theme.spacing(1),
    },
}));

const CustomTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: 'white',
        color: 'black',
        border: '1px solid black',
        boxShadow: theme.shadows[5],
    },
}))(Tooltip);

const CheckboxWithInfoText = (props) => {
    const {
        register,
        label,
        info,
        name,
        validation,
        defaultChecked,
        onChange,
        required,
        errors,
        gutterBottom,
        ...rest
    } = props;
    const classes = useStyles();

    return (
        <>
            <Box
                className={classes.wrapper}
                style={{
                    marginBottom: gutterBottom ? '.4em' : '0',
                    paddingLeft: '0',
                }}
            >
                <FormControlLabel
                    className={classes.label}
                    control={
                        <Checkbox
                            inputRef={register(validation)}
                            name={name}
                            color="primary"
                            defaultChecked={defaultChecked}
                            required={required}
                            {...rest}
                        />
                    }
                    label={label}
                    onChange={(evt, checked) => {
                        onChange(evt, checked);
                    }}
                />
                {info && (
                    <CustomTooltip
                        TransitionComponent={Fade}
                        TransitionProps={{ timeout: 600 }}
                        title={<Typography>{info}</Typography>}
                    >
                        <HelpOutlineIcon style={{ cursor: 'help' }} />
                    </CustomTooltip>
                )}
            </Box>
            {errors && <FormHelperText error>{errors.message}</FormHelperText>}
        </>
    );
};

CheckboxWithInfoText.propTypes = {
    register: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    info: PropTypes.string,
    validation: PropTypes.object,
    defaultChecked: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    required: PropTypes.bool,
    errors: PropTypes.object,
};

CheckboxWithInfoText.defaultProps = {
    defaultChecked: false,
    onChange: () => {},
};

export default CheckboxWithInfoText;
