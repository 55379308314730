import React from 'react';
import PropTypes from 'prop-types';
import { Response } from '../../../util/DataTypes/Response';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';

const WizardFormError = (props) => {
    const { error, refetch } = props;

    return (
        <Alert
            severity="error"
            action={
                Boolean(refetch) ? (
                    <Button onClick={refetch}>Neuladen</Button>
                ) : undefined
            }
        >
            <AlertTitle>Fehler</AlertTitle>
            <Typography>{error.message()}</Typography>
        </Alert>
    );
};

WizardFormError.propTypes = {
    error: PropTypes.instanceOf(Response).isRequired,
    refetch: PropTypes.func,
};

export default WizardFormError;
