import { Response } from '../../../util/DataTypes/Response';
import { api } from '../AxiosConfig/Config';
import { fetchMutator, persistMutator } from './ExplanationsMutator';

export function fetchExplanationsData(proposalId) {
    return function () {
        return new Promise(async (resolve, reject) => {
            try {
                const { data } = await api().get(
                    `/projects/${proposalId}/explanation`
                );
                resolve(fetchMutator(data));
            } catch (e) {
                reject(
                    Response.Error(
                        'Erklärungen konnten nicht geladen werden',
                        e
                    )
                );
            }
        });
    };
}

export function persistExplanationData({ proposalId, ...data }) {
    return new Promise(async (resolve, reject) => {
        try {
            await api().patch(
                `/projects/${proposalId}/explanation`,
                persistMutator(data),
                {
                    headers: {
                        'Content-Type': 'application/merge-patch+json',
                    },
                }
            );

            resolve(Response.Success('Erklärungen erfolgreich gespeichert'));
        } catch (e) {
            reject(
                Response.Error(
                    'Erklärungen konnten nicht gespeichert werden',
                    e
                )
            );
        }
    });
}
