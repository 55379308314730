import React from 'react';
import PropTypes from 'prop-types';
import { useMutation, useQuery } from 'react-query';
import {
    deleteMobility,
    getMobilities,
} from '../../../lib/api/Mobility/MobilityActions';
import MobilityList from '../../../components/Forms/Mobility/MobilityList/MobilityList';
import { queryCache } from '../../../QueryCache';
import { useSnackbar } from 'notistack';

const MobilityListContainer = (props) => {
    const { proposalId, onCreate, onEdit, isEditing, onChange } = props;

    const { enqueueSnackbar } = useSnackbar();

    const {
        isLoading,
        isFetching,
        data: mobilities,
        error: getError,
    } = useQuery(['mobilities', proposalId], getMobilities(proposalId), {
        refetchOnMount: 'always',
        staleTime: 5 * 1000 * 60,
        initialStale: true,
        initialData: [],
    });

    const [delMobility, { isLoading: isDeleting }] = useMutation(
        deleteMobility,
        {
            onSuccess: (data, variables) => {
                const { index } = variables;
                const oldData = queryCache.getQueryData([
                    'mobilities',
                    proposalId,
                ]);
                oldData.splice(index, 1);
                queryCache.setQueryData(
                    ['mobilities', proposalId],
                    [...oldData]
                );
            },
            throwOnError: true,
        }
    );

    React.useEffect(() => {
        onChange(mobilities.length);
    }, [mobilities.length]); //eslint-disable-line

    const handleCreateClick = () => {
        onCreate();
    };

    const handleEditClick = (index) => {
        onEdit(index, mobilities[index]);
    };

    const handleDeleteClick = (index) => {
        delMobility({ iri: mobilities[index]['@id'], index })
            .then(() => {
                enqueueSnackbar('Mobilität erfolgreich gelöscht', {
                    variant: 'success',
                });
            })
            .catch((err) => {
                enqueueSnackbar(err.message(), {
                    variant: 'error',
                });
            });
    };

    return (
        <MobilityList
            data={mobilities}
            onCreate={handleCreateClick}
            isEditing={isEditing}
            isLoading={isLoading}
            isFetching={isFetching}
            onDelete={handleDeleteClick}
            onEdit={handleEditClick}
            isDeleting={isDeleting}
            getError={getError}
        />
    );
};

MobilityListContainer.propTypes = {
    proposalId: PropTypes.string,
    onCreate: PropTypes.func,
    onEdit: PropTypes.func,
    isEditing: PropTypes.bool,
    onChange: PropTypes.func,
};

export default MobilityListContainer;
