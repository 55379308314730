import React from 'react';
import PropTypes from 'prop-types';
import { mobilityFormTexts as mft } from '../../../../texts/Forms/MobilityFormTexts';
import { useWatch } from 'react-hook-form';
import {
    calculateOrgaAllowance,
    calculatePrePostAllowance,
    calculateResidenceAllowance,
    calculateTravelAllowance,
} from '../../../../util/Forms/MobilityFunctions';
import { Typography } from '@material-ui/core';
import MobilityDropdown from '../MobilityDropdown';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Collapse from '@material-ui/core/Collapse';
import TextField from '@material-ui/core/TextField';
import { useMobilityEditStyles } from '../../../../styles/components/forms/MobilityForm/MobilityEditStyles';
import MobilityEditSum from './MobilityEditSum';
import Dinero from 'dinero.js';
import { STAY_TYPES } from '../../../../util/constants/States';
import RHFMoneyInput from '../../../Partials/Form/RHFMoneyInput';
import { Alert } from '@material-ui/lab';

const toWatch = [
    mft.fields.attendees.name,
    mft.fields.type.name,
    mft.fields.destination.name,
    mft.fields.start.name,
    mft.fields.end.name,
    mft.fields.reqTravelAllowance.name,
    mft.fields.reqPrePostProcessing.name,
    mft.fields.reqResidenceAllowance.name,
    mft.fields.reqOrgaAllowance.name,
    mft.fields.disabledRequirements.name,
];

/*eslint react-hooks/exhaustive-deps: 0*/
const MobilityFields = (props) => {
    const {
        control,
        data: defaultValues,
        orgaAmount,
        processingAmount,
        errors,
    } = props;

    const classes = useMobilityEditStyles();

    const data = useWatch({
        control,
        name: [...toWatch],
        defaultValue: defaultValues,
    });

    const type = useWatch({
        control,
        name: mft.fields.type.name,
        defaultValue: data[mft.fields.type.name],
    });

    const travelAllowance = React.useMemo(() => {
        if (data[mft.fields.reqTravelAllowance.name] === '1')
            return calculateTravelAllowance(data);
        return new Dinero();
    }, [
        data[mft.fields.reqTravelAllowance.name],
        data[mft.fields.attendees.name],
        data[mft.fields.destination.name],
    ]);

    const processingAllowance = React.useMemo(() => {
        if (
            type === STAY_TYPES.apprentice &&
            data[mft.fields.reqPrePostProcessing.name] === '1'
        )
            return calculatePrePostAllowance(data, processingAmount);
        return new Dinero();
    }, [
        type,
        data[mft.fields.reqPrePostProcessing.name],
        data[mft.fields.attendees.name],
        data[mft.fields.destination.name],
    ]);

    const orgaAllowance = React.useMemo(() => {
        if (
            type !== STAY_TYPES.companion &&
            data[mft.fields.reqOrgaAllowance.name] === '1'
        )
            return calculateOrgaAllowance(data, orgaAmount);
        return new Dinero();
    }, [
        type,
        data[mft.fields.reqOrgaAllowance.name],
        data[mft.fields.attendees.name],
        data[mft.fields.destination.name],
    ]);

    const residenceAllowance = React.useMemo(() => {
        if (data[mft.fields.reqResidenceAllowance.name] === '1')
            return calculateResidenceAllowance(data);
        return new Dinero();
    }, [
        type,
        data[mft.fields.reqResidenceAllowance.name],
        data[mft.fields.attendees.name],
        data[mft.fields.destination.name],
        data.start,
        data.end,
    ]);

    const showCalculations = React.useMemo(() => {
        return (
            data[mft.fields.reqTravelAllowance.name] === '1' ||
            data[mft.fields.reqPrePostProcessing.name] === '1' ||
            data[mft.fields.reqOrgaAllowance.name] === '1' ||
            data[mft.fields.reqResidenceAllowance.name] === '1'
        );
    }, [data]);

    return (
        <>
            <MobilityDropdown
                control={control}
                defaultValue={data[mft.fields.reqTravelAllowance.name]}
                error={errors[mft.fields.reqTravelAllowance.name]}
                {...mft.fields.reqTravelAllowance}
            />
            <MobilityDropdown
                control={control}
                defaultValue={data[mft.fields.reqResidenceAllowance.name]}
                error={errors[mft.fields.reqResidenceAllowance.name]}
                {...mft.fields.reqResidenceAllowance}
            />
            <Collapse in={type !== STAY_TYPES.companion} unmountOnExit>
                <MobilityDropdown
                    control={control}
                    defaultValue={data[mft.fields.reqOrgaAllowance.name]}
                    error={errors[mft.fields.reqOrgaAllowance.name]}
                    {...mft.fields.reqOrgaAllowance}
                />
            </Collapse>
            <Collapse in={type === STAY_TYPES.apprentice} unmountOnExit>
                <MobilityDropdown
                    control={control}
                    defaultValue={data[mft.fields.reqPrePostProcessing.name]}
                    error={errors[mft.fields.reqPrePostProcessing.name]}
                    {...mft.fields.reqPrePostProcessing}
                />
            </Collapse>
            <Card className={classes.input}>
                <CardContent>
                    <Typography variant="subtitle1" component="h3">
                        {showCalculations
                            ? mft.showCalculationsText
                            : mft.showCalculationsTextEmpty}
                    </Typography>
                    <TextField
                        inputProps={{
                            'aria-label': mft.fields.travelAllowance.label,
                        }}
                        fullWidth
                        value={travelAllowance.toFormat()}
                        {...mft.fields.travelAllowance}
                    />
                    <TextField
                        inputProps={{
                            'aria-label': mft.fields.residenceAllowance.label,
                        }}
                        fullWidth
                        value={residenceAllowance.toFormat()}
                        {...mft.fields.residenceAllowance}
                    />
                    <TextField
                        inputProps={{
                            'aria-label': mft.fields.orgaAllowance.label,
                        }}
                        fullWidth
                        value={orgaAllowance.toFormat()}
                        {...mft.fields.orgaAllowance}
                    />
                    <TextField
                        inputProps={{
                            'aria-label': mft.fields.prePostProcessing.label,
                        }}
                        fullWidth
                        value={processingAllowance.toFormat()}
                        {...mft.fields.prePostProcessing}
                    />
                    <Alert severity="info">
                        {mft.fields.prePostProcessing.info}
                    </Alert>
                </CardContent>
            </Card>

            <RHFMoneyInput
                control={control}
                name={mft.fields.disabledRequirements.name}
                label={mft.fields.disabledRequirements.label}
            />

            <MobilityEditSum
                travel={travelAllowance}
                orga={orgaAllowance}
                residence={residenceAllowance}
                processing={processingAllowance}
                disabled={data[mft.fields.disabledRequirements.name]}
            />
        </>
    );
};

MobilityFields.propTypes = {
    control: PropTypes.object,
    data: PropTypes.object,
    processingAmount: PropTypes.number,
    orgaAmount: PropTypes.number,
    errors: PropTypes.object,
};

MobilityFields.defaultProps = {
    errors: {},
};

export default MobilityFields;
