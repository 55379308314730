import React from 'react';
import PropTypes from 'prop-types';
import WizardControlBar from './WizardControlBar';

const WizardControls = (props) => {
    const { children, ...rest } = props;

    return (
        <>
            <WizardControlBar {...rest} />
            {children}
            <WizardControlBar {...rest} />
        </>
    );
};

WizardControls.propTypes = {
    children: PropTypes.node,
    currentStep: PropTypes.number,
    isFinished: PropTypes.bool,
    isTransitioning: PropTypes.bool,
    toStep: PropTypes.func,
    back: PropTypes.func,
    forward: PropTypes.func,
    verifying: PropTypes.bool,
};

export default WizardControls;
