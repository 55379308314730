import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { registerFormTexts } from '../../../../texts/Forms/RegisterFormTexts';

const DirectiveSummary = (props) => {
    const { hasLength, hasThree } = props;

    const result = 10 * hasLength + 1 * hasThree;

    return (
        <Typography style={{ marginTop: 4 }}>
            {registerFormTexts.directive[result]}
        </Typography>
    );
};

DirectiveSummary.propTypes = {
    hasLength: PropTypes.bool,
    hasThree: PropTypes.bool,
};

export default DirectiveSummary;
