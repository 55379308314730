import React from 'react';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { Backdrop, CircularProgress, makeStyles } from '@material-ui/core';
import _ from 'lodash';
import { registerFormTexts as rft } from '../../../texts/Forms/RegisterFormTexts';
import checkCircle from '../../../static/images/check-circle.gif';

const useStyles = makeStyles({
    root: {
        color: '#fff',
        zIndex: 999,
    },
    header: {
        marginBottom: '2rem',
    },
});

const RegisterValidateDone = ({ success, error, loading }) => {
    const classes = useStyles();

    const renderLoading = () => (
        <React.Fragment>
            <Typography variant="h3" className={classes.header}>
                {rft.validateDone.confirmEmailEclipsed}
            </Typography>
            <Typography>{rft.validateDone.emailIsBeingConfirmed}</Typography>
        </React.Fragment>
    );

    const renderSuccess = () => (
        <React.Fragment>
            <div style={{ display: 'flex' }}>
                <Typography variant="h3" className={classes.header}>
                    {rft.validateDone.emailHasBeenConfirmed}
                </Typography>
                <img
                    style={{
                        height: '50px',
                        marginTop: '13px',
                        marginLeft: '20px',
                    }}
                    src={checkCircle}
                    alt="check circle image"
                    aria-hidden={true}
                />
            </div>
            <Typography>
                {rft.validateDone.emailHasBeenConfirmedText}
            </Typography>
        </React.Fragment>
    );

    const renderError = () => (
        <React.Fragment>
            <Typography variant="h3" className={classes.header}>
                {rft.validateDone.confirmEmailInvalid}
            </Typography>
            <Typography>{rft.validateDone.tokenInvalid}</Typography>
        </React.Fragment>
    );

    return (
        <React.Fragment>
            {loading ? (
                <React.Fragment>
                    <Backdrop open={loading} className={classes.root}>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                    {renderLoading()}
                </React.Fragment>
            ) : _.isEmpty(error) ? (
                renderSuccess()
            ) : (
                renderError()
            )}
        </React.Fragment>
    );
};

RegisterValidateDone.prototype = {
    error: PropTypes.object,
    loading: PropTypes.bool.isRequired,
    success: PropTypes.object,
};

export default RegisterValidateDone;
