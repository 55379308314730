import React from 'react';
import { Response, TYPE } from '../../util/DataTypes/Response';
import Typography from '@material-ui/core/Typography';
import { useAuth } from '../../stores/Auth.store';
import { removeToken } from '../../lib/api/AuthNew/AuthActions';
import { queryCache } from '../../QueryCache';
import { logoutTexts } from '../../texts/container/LogoutContainerTexts';
import { texts } from '../../texts/GeneralTexts';

const LogoutContainer = () => {
    const [response, setResponse] = React.useState(undefined);
    const setUser = useAuth((state) => state.setUser);

    React.useEffect(() => {
        removeToken()
            .then(() => {
                setResponse(Response.Success(logoutTexts.success));
                setUser(undefined);
                queryCache.removeQueries();
            })
            .catch(() => {
                Response.Error(logoutTexts.error);
            });
        //eslint-disable-next-line
    }, []);

    return (
        <>
            <Typography variant="h4" component="h1" gutterBottom>
                {texts.logout}
            </Typography>
            {!response && (
                <Typography>{logoutTexts.youReBeingLoggedOut}</Typography>
            )}
            {response?.type() === TYPE.SUCCESS && (
                <Typography>{response.message()}</Typography>
            )}
            {response?.type() === TYPE.ERROR && (
                <Typography>{response.message()}</Typography>
            )}
        </>
    );
};

export default LogoutContainer;
