import React from 'react';
import InputAdornment from '@material-ui/core/InputAdornment';
import Tooltip from '@material-ui/core/Tooltip';
import Directive from './Directive/Directive';
import PropTypes from 'prop-types';
import green from '@material-ui/core/colors/green';

import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import withStyles from '@material-ui/core/styles/withStyles';
import { verifyPassword } from '../../../util/Validation/PasswordValidation';
import { Info } from '@material-ui/icons';

const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
    },
}))(Tooltip);

const PasswordAdornment = (props) => {
    const { password } = props;

    const verified = React.useMemo(() => {
        return verifyPassword(password);
    }, [password]);

    const isValid = verified.hasLength && verified.hasThree;

    return (
        <InputAdornment position="end" style={{ cursor: 'default' }}>
            <LightTooltip title={<Directive {...verified} />}>
                {isValid ? (
                    <CheckIcon style={{ color: green[500] }} />
                ) : password.length > 0 ? (
                    <CloseIcon color="error" />
                ) : (
                    <Info />
                )}
            </LightTooltip>
        </InputAdornment>
    );
};

PasswordAdornment.propTypes = {
    password: PropTypes.string,
};

PasswordAdornment.defaultProps = {
    password: '',
};

export default PasswordAdornment;
