export function getSalutation(salutation) {
    switch (salutation) {
        case 'm':
            return 'Herr';
        case 'f':
            return 'Frau';
        case 'd':
            return '';
        default:
            return '';
    }
}
