import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from 'react-query';
import WizardFormError from '../../../components/Partials/Error/WizardFormError';
import FullContentOverlayLoader from '../../../components/Partials/Loader/FullContentOverlayLoader';
import BudgetPlanForm from '../../../components/Forms/BudgetPlan/BudgetPlanForm';
import { fetchBudgetPlanData } from '../../../lib/api/BudgetPlan/BudgetPlanActions';
import Typography from '@material-ui/core/Typography';
import { fiscalYearFormTexts } from '../../../texts/Forms/FiscalYearFormTexts';
import { budgetPlanFormTexts } from '../../../texts/Forms/BudgetPlanFormTexts';
import { fetchFiscalYearData } from '../../../lib/api/FiscalYears/FiscalYearActions';
import { dineroFormat } from '../../../util/Helper/Dinero';
import TextBlock from '../../../components/TextBlock/TextBlock';

const BudgetPlanFormContainer = (props) => {
    const { proposalId } = props;

    const { isLoading, refetch, error, data, isFetching } = useQuery(
        [proposalId, 'budgetPlanForm'],
        fetchBudgetPlanData(proposalId),
        {
            initialStale: true,
            refetchOnWindowFocus: false,
        }
    );

    const dataFiscalYear = useQuery(
        [proposalId, 'fiscalYearForm'],
        fetchFiscalYearData(proposalId),
        {
            initialStale: true,
            refetchOnWindowFocus: false,
        }
    );

    if (
        isLoading ||
        isFetching ||
        dataFiscalYear.isLoading ||
        dataFiscalYear.isFetching
    )
        return <FullContentOverlayLoader loading={true} />;

    if (error) return <WizardFormError error={error} refetch={refetch} />;
    if (dataFiscalYear.error)
        return (
            <WizardFormError
                error={dataFiscalYear.error}
                refetch={dataFiscalYear.refetch}
            />
        );

    return (
        <>
            <Typography gutterBottom style={{ fontWeight: 'bold' }}>
                {fiscalYearFormTexts.requestedTotal}:{' '}
                {dataFiscalYear
                    ? dineroFormat(dataFiscalYear.data.totalSum)
                    : '0,00 €'}
            </Typography>
            <TextBlock textBlock={budgetPlanFormTexts.infoTextBlock} />
            <BudgetPlanForm
                submitFunc={props.save}
                error={props.saveError}
                defaultValues={data}
                saving={props.isSaving}
                proposalId={proposalId}
                fiscalYearTotalSum={dineroFormat(
                    dataFiscalYear.data.totalSum
                ).toString()}
            />
        </>
    );
};

BudgetPlanFormContainer.propTypes = {
    proposalId: PropTypes.string,
    save: PropTypes.func.isRequired,
};

export default BudgetPlanFormContainer;
