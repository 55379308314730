import {
    buildUrl,
    generateCodeChallenge,
    generateCodeVerifier,
    generateState,
} from '../../../util/Auth/AuthFunctions';
import axios from 'axios';
import { api } from '../AxiosConfig/Config';
import { receiveMessage } from '../../../util/Auth/ReceiveMessage';
import { authMutator } from '../Auth/AuthMutator';
import {
    getApplicationRounds,
    getCurrentApplicationRound,
} from '../ApplicationRounds/ApplicationsRoundsActions';
import { useApplicationRounds } from '../../../stores/ApplicationRounds.store';
import _ from 'lodash';
import { loginCookieExists } from '../../../util/Helper/CookieFunctions';

export function loginNewWindow(cb) {
    return new Promise(async (resolve, reject) => {
        const verify = generateCodeVerifier();
        const challenge = generateCodeChallenge(verify);
        const state = generateState();
        const url = buildUrl(challenge, state);

        let data = null;
        const setData = (d) => {
            data = d;
        };
        window.addEventListener(
            'message',
            receiveMessage(window, setData),
            false
        );
        const w = window.open(url, '_blank');

        cb('Sie wurden zum Login weitergeleitet...');
        let interval;
        try {
            await new Promise((resolve, reject) => {
                interval = setInterval(() => {
                    if (w.closed && !data)
                        reject('Das Loginfenster wurde geschlossen');
                    if (!data) return;
                    clearInterval(interval);
                    resolve();
                }, 1000);
            });
        } catch (e) {
            clearInterval(interval);
            reject(e);
        }

        if (!data || state !== data.state) {
            reject('Fehler beim Übertragen der Logindaten');
        }
        try {
            cb('Nutzerdaten akzeptiert, Sie werden eingeloggt...', 2);
            await fetchToken({ verify: verify, code: data.code });
        } catch (e) {
            reject('Fehler beim Einloggen');
        }

        try {
            cb('Lade Nutzerprofil...', 3);
            /*const { data: user } = await axios.get(
                '/api/action/account-profile',
                {
                    withCredentials: true,
                }
            );*/

            const user = await fetchUserData();
            await fetchApplicationRoundData();

            resolve(user);
        } catch (e) {
            reject('Fehler beim Laden des Nutzerprofils');
        }
    });
}

export function fetchToken({ verify, code }) {
    return new Promise(async (resolve, reject) => {
        try {
            const formData = new FormData();
            formData.set('grant_type', 'authorization_code');
            formData.set('client_id', `${process.env.REACT_APP_CLIENT_ID}`);
            formData.set('code', code);
            formData.set('code_verifier', verify);
            formData.set(
                'redirect_uri',
                `${process.env.REACT_APP_HOST}${process.env.REACT_APP_REDIRECT}`
            );

            const response = await axios.post('/oauth/token', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                withCredentials: true,
            });
            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
}

export function fetchUserData() {
    if (!loginCookieExists()) {
        return Promise.reject();
    }
    return new Promise(async (resolve, reject) => {
        try {
            const { data: user } = await api().get('/action/account-profile');
            resolve(authMutator(user));
        } catch (error) {
            reject(error);
        }
    });
}

export async function fetchApplicationRoundData() {
    const applicationRounds = await getApplicationRounds();
    const { setState: setApplicationRounds } = useApplicationRounds;
    setApplicationRounds({
        applicationRounds,
        applicationRoundsById: _.keyBy(applicationRounds, '@id'),
        currentApplicationRound: getCurrentApplicationRound(applicationRounds),
    });
}

export function refreshToken() {
    return new Promise(async (resolve, reject) => {
        try {
            const formData = new FormData();
            formData.set('grant_type', 'refresh_token');
            formData.set('client_id', process.env.REACT_APP_CLIENT_ID);

            const response = await axios.post('/oauth/token', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                withCredentials: true,
            });

            resolve(response);
        } catch (e) {
            reject(e);
        }
    });
}

export function removeToken() {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await axios.get('/oauth/logout');
            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
}
