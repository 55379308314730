import React from 'react';
import { Container, makeStyles } from '@material-ui/core';
import FooterGrid from './FooterGrid';
import { footerData } from '../../../config/FooterConfig';
import FooterCopyright from './FooterCopyright';

const useStyles = makeStyles((theme) => ({
    support: {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        width: '100%',
        padding: '2rem 0',
    },
    copyright: {
        backgroundColor: '#fff', //'#60810E',
        width: '100%',
        color: 'black',
        padding: '2rem 0',
    },
}));

const Footer = () => {
    const classes = useStyles();

    return (
        <React.Fragment>
            <div className={classes.support}>
                <Container>
                    <FooterGrid footerData={footerData} />
                </Container>
            </div>
            <div className={classes.copyright}>
                <Container>
                    <FooterCopyright />
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Footer;
