import React from 'react';
import PropTypes from 'prop-types';
import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';
import Typography from '@material-ui/core/Typography';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';

const DirectiveResult = (props) => {
    const { fulfilled, text } = props;

    return (
        <Typography
            style={{
                display: 'inline-flex',
                alignItems: 'center',
                color: fulfilled ? green[500] : red[500],
            }}
        >
            {fulfilled ? <DoneIcon /> : <ClearIcon />} {text}
        </Typography>
    );
};

DirectiveResult.propTypes = {
    fulfilled: PropTypes.bool,
    text: PropTypes.string,
};

export default DirectiveResult;
