import React from 'react';
import { useParams } from 'react-router';
import { validateAccount } from '../../lib/api/Register/RegisterActions';
import RegisterValidateDone from '../../components/Forms/Register/RegisterValidateDone';

const RegisterValidateContainer = (props) => {
    const { token } = useParams();
    const [state, setState] = React.useState({
        loading: true,
        success: {},
        error: {},
    });

    const { validateAccount } = props;

    React.useEffect(() => {
        validateAccount(token)
            .then((result) =>
                setState({ loading: false, success: result.data, error: {} })
            )
            .catch((err) =>
                setState({ loading: false, success: {}, error: err.response })
            );
    }, [token, validateAccount]);

    return <RegisterValidateDone {...state} />;
};

RegisterValidateContainer.defaultProps = { validateAccount };

export default RegisterValidateContainer;
