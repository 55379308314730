import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import Autocomplete, {
    createFilterOptions,
} from '@material-ui/lab/Autocomplete';
import _ from 'lodash';
import TextField from '@material-ui/core/TextField';
import { getOption } from '../../../util/Helper/AutocompleteGetOption';

const AutocompleteSync = (props) => {
    const {
        name,
        control,
        error,
        defaultValue,
        label,
        variant,
        rules,
        data,
        disabled,
    } = props;

    const filterOptions = createFilterOptions({
        stringify: ({ label, searchTerms }) => `${label} ${searchTerms}`,
    });

    return (
        <Controller
            render={(props) => (
                <Autocomplete
                    {...props}
                    fullWidth
                    options={data}
                    id={name}
                    disabled={disabled}
                    filterOptions={filterOptions}
                    getOptionLabel={(option) => getOption(option, data).label}
                    getOptionSelected={(option, value) => {
                        return _.isEqual(
                            option.label,
                            getOption(value, data).label
                        );
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={label}
                            variant={variant}
                            error={Boolean(error)}
                            helperText={
                                Boolean(error) ? error.message : undefined
                            }
                            required={!!rules.required}
                        />
                    )}
                    onChange={(_, data) => {
                        props.onChange(data);
                    }}
                />
            )}
            name={name}
            control={control}
            defaultValue={defaultValue}
            rules={rules}
        />
    );
};

AutocompleteSync.propTypes = {
    name: PropTypes.string.isRequired,
    control: PropTypes.object.isRequired,
    error: PropTypes.object,
    defaultValue: PropTypes.object,
    label: PropTypes.string,
    variant: PropTypes.oneOf(['outlined', 'standard', 'filled']),
    rules: PropTypes.object,
    data: PropTypes.array,
    disabled: PropTypes.bool,
};

AutocompleteSync.defaultProps = {
    error: undefined,
    defaultValue: null,
    variant: 'standard',
    rules: undefined,
    data: [],
};

export default AutocompleteSync;
