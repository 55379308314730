import React from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
import { fiscalYearFormTexts } from '../../texts/Forms/FiscalYearFormTexts';
import { dineroFormat } from '../../util/Helper/Dinero';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    table: {
        maxWidth: '70%',
        minWidth: 400,
        '& td:last-child, & th:last-child': {
            textAlign: 'right',
        },
    },
    boldRow: {
        '& td, & th': {
            fontWeight: 'bold',
        },
    },
    footerRow: {
        backgroundColor: theme.palette.grey[100],
        '& td, & th': {
            fontWeight: 'bold',
            fontSize: '1rem',
            color: theme.palette.text.primary,
        },
    },
}));

const FiscalYearTable = (props) => {
    const { data } = props;
    const classes = useStyles();

    const subTotal1 =
        parseInt(data.travelAllowance.total) +
        parseInt(data.residenceAllowance.total) +
        parseInt(data.disabledRequirements.total);
    const subTotal2 =
        parseInt(data.prePostProcessing.total) +
        parseInt(data.orgaAllowance.total);
    const total = subTotal1 + subTotal2;

    return (
        <TableContainer className={classes.table} component={Paper}>
            <Table size={'small'}>
                <TableHead>
                    <TableRow className={classes.boldRow}>
                        <TableCell>{fiscalYearFormTexts.type}</TableCell>
                        <TableCell>
                            {fiscalYearFormTexts.requestedAllowance}
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell>
                            {fiscalYearFormTexts.travelAllowance}
                        </TableCell>
                        <TableCell>
                            {dineroFormat(data.travelAllowance.total)}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            {fiscalYearFormTexts.residenceAllowance}
                        </TableCell>
                        <TableCell>
                            {dineroFormat(data.residenceAllowance.total)}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            {fiscalYearFormTexts.disabledRequirements}
                        </TableCell>
                        <TableCell>
                            {dineroFormat(data.disabledRequirements.total)}
                        </TableCell>
                    </TableRow>
                    <TableRow className={classes.boldRow}>
                        <TableCell>{fiscalYearFormTexts.subTotal1}</TableCell>
                        <TableCell>{dineroFormat(subTotal1)}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            {fiscalYearFormTexts.orgaAllowance}
                        </TableCell>
                        <TableCell>
                            {dineroFormat(data.orgaAllowance.total)}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            {fiscalYearFormTexts.prePostProcessing}
                        </TableCell>
                        <TableCell>
                            {dineroFormat(data.prePostProcessing.total)}
                        </TableCell>
                    </TableRow>
                    <TableRow className={classes.boldRow}>
                        <TableCell>{fiscalYearFormTexts.subTotal2}</TableCell>
                        <TableCell>{dineroFormat(subTotal2)}</TableCell>
                    </TableRow>
                </TableBody>
                <TableFooter>
                    <TableRow className={classes.footerRow}>
                        <TableCell>
                            {fiscalYearFormTexts.requestedTotal}
                        </TableCell>
                        <TableCell>{dineroFormat(total)}</TableCell>
                    </TableRow>
                </TableFooter>
            </Table>
        </TableContainer>
    );
};

FiscalYearTable.propTypes = {
    data: PropTypes.object,
};

export default FiscalYearTable;
