import React from 'react';
import PropTypes from 'prop-types';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

const MobilityDialogActions = (props) => {
    const { dismiss, isSaving } = props;

    return (
        <DialogActions>
            {isSaving && <CircularProgress size="1rem" />}
            <Button variant="outlined" onClick={dismiss} disabled={isSaving}>
                Abbrechen
            </Button>
            <Button
                type="submit"
                variant="contained"
                disableElevation
                color="primary"
                disabled={isSaving}
            >
                Speichern
            </Button>
        </DialogActions>
    );
};

MobilityDialogActions.propTypes = {
    dismiss: PropTypes.func,
    isSaving: PropTypes.bool,
};

export default MobilityDialogActions;
