import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { getCoreData } from '../../../lib/api/CoreData/CoreDataActions.new';
import CoreDataWizardDisplay from '../../../components/Partials/CoreData/CoreDataWizardDisplay';
import CoreDataDisplay from '../../../components/Partials/CoreData/Display/CoreDataDisplay';

const CoreDataFormContainer = (props) => {
    const { save } = props;
    const history = useHistory();
    const { data, isLoading, isFetching, error, refetch } = useQuery(
        ['coreData'],
        getCoreData,
        { initialStale: true, refetchOnWindowFocus: false }
    );

    const handleEdit = React.useCallback(() => {
        history.push('/stammdaten');
    }, [history]);

    //const hasSubmittedProject = data?.hasSubmittedProject;

    if (isLoading) return <p>Lädt Stammdaten</p>;

    if (error) return <p>Fehler beim Laden der Stammdaten</p>;

    //if (!hasSubmittedProject)
    //    return <CoreDataFormNew mutate={save} data={data} disabled={true}/>;

    return (
        <CoreDataWizardDisplay save={save}>
            <CoreDataDisplay
                coreData={data}
                isFetching={isFetching}
                handleReload={refetch}
                handleEdit={handleEdit}
                displayButtons={false}
            />
        </CoreDataWizardDisplay>
    );
};

CoreDataFormContainer.propTypes = {
    save: PropTypes.func,
};

export default CoreDataFormContainer;
