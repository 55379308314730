import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import PersonIcon from '@material-ui/icons/Person';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { getSalutation } from '../../../../util/Helper/Salutation';
import { coreDataOverviewTexts as cdot } from '../../../../texts/components/CoreData/CoreDataOverviewTexts';
import { ButtonGroup } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import { deleteViceSignaturePerson } from '../../../../lib/api/CoreData/CoreDataActions.new';

const DisplayUser = (props) => {
    const { user, title, refetch, showDeleteButton } = props;

    const [disabled, setDisabled] = React.useState(false);

    const handleDeleteViceClick = () => {
        setDisabled(true);
        deleteViceSignaturePerson(user)
            .then(() => {
                if (refetch) {
                    refetch().finally(() => {
                        setDisabled(false);
                    });
                }
            })
            .catch(() => {
                setDisabled(false);
            });
    };

    return (
        <Card style={{ height: '100%' }}>
            <CardContent>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'flex-start',
                    }}
                >
                    <Typography variant="h6" component="h2">
                        <PersonIcon
                            style={{ verticalAlign: 'sub', marginRight: 4 }}
                        />
                        {title}
                    </Typography>
                    {showDeleteButton && (
                        <ButtonGroup
                            color="primary"
                            aria-label="Vertetung zeichnungsberechtigte Person Aktionen"
                            size="small"
                            style={{ marginBottom: '.8em' }}
                        >
                            <Tooltip
                                title={
                                    <Typography>
                                        {cdot.representativeDelete}
                                    </Typography>
                                }
                            >
                                <Button
                                    aria-label={cdot.representativeDelete}
                                    onClick={handleDeleteViceClick}
                                    disabled={disabled}
                                >
                                    <DeleteIcon />
                                </Button>
                            </Tooltip>
                        </ButtonGroup>
                    )}
                </div>
                {!user ? (
                    <Typography>{cdot.noData}</Typography>
                ) : (
                    <>
                        <Typography>
                            {user.salutation
                                ? getSalutation(user.salutation) + ' '
                                : ''}
                            {user.title ? user.title + ' ' : ''}
                            {user.firstname} {user.lastname}
                        </Typography>
                        <Typography>
                            <b>E-Mail: </b>
                            {user.email}
                        </Typography>
                        <Typography>
                            <b>Telefon: </b>
                            {user.phone}
                        </Typography>
                        <Typography>
                            <b>Funktion: </b>
                            {user.func}
                        </Typography>
                        <Typography>
                            <b>Abteilung: </b>
                            {user.department}
                        </Typography>
                        <Typography>
                            <b>Adresse: </b>
                        </Typography>
                        <Typography style={{ textIndent: '1em' }}>
                            {user.address?.street}
                        </Typography>
                        <Typography style={{ textIndent: '1em' }}>
                            {user.address?.postcode} {user.address?.city}
                        </Typography>
                        <Typography style={{ textIndent: '1em' }}>
                            {user.address?.state}
                        </Typography>
                    </>
                )}
            </CardContent>
        </Card>
    );
};

DisplayUser.propTypes = {
    user: PropTypes.object,
    title: PropTypes.string,
    showDeleteButton: PropTypes.bool,
    refetch: PropTypes.func,
};

DisplayUser.defaultProps = {
    showDeleteButton: false,
    refetch: null,
};

export default DisplayUser;
