import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import FiscalYearTableRow from './FiscalYearTableRow';
import TableFooter from '@material-ui/core/TableFooter';
import { FormHelperText } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { useColorStyles } from '../../../styles/BasicStyles';
import { dineroFormat } from '../../../util/Helper/Dinero';
import { fiscalYearFormTexts } from '../../../texts/Forms/FiscalYearFormTexts';
import { violationPropertyPathMutator } from '../../../lib/api/FiscalYears/FiscalYearMutators';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useApplicationRounds } from '../../../stores/ApplicationRounds.store';
import moment from 'moment';

const sumUpColumn = (form, column) =>
    Object.keys(form)
        .map((entry) => form[entry][column])
        .reduce((acc, val) => acc + parseInt(val || 0), 0);

const useTableStyles = makeStyles({
    table: {
        '& td:last-child, & th:last-child, & td:last-child *, & th:last-child *': {
            textAlign: 'right',
        },
    },
    tfoot: {
        '& td': {
            color: 'inherit',
            fontSize: 'inherit',
            fontWeight: 'bold',
        },
    },
    thead: {
        '& th': {
            fontWeight: 'bold',
        },
    },
});

const FiscalYearForm = (props) => {
    const { defaultValues, submitFunc, error, proposalId } = props;
    const classes = useColorStyles();
    const tableClasses = useTableStyles();

    const {
        handleSubmit,
        control,
        watch,
        setError,
        errors,
        clearErrors,
    } = useForm({
        defaultValues,
    });

    const [sumYear1, setSumYear1] = useState(0);
    const [sumYear2, setSumYear2] = useState(0);
    const [total, setTotal] = useState(0);
    const [diff, setDiff] = useState(0);
    const [formConfig, setFormConfig] = useState(defaultValues);
    const watchAllFields = watch();

    const { currentApplicationRound } = useApplicationRounds((state) => ({
        currentApplicationRound: state.currentApplicationRound,
    }));

    useEffect(() => {
        const tmpFormConfig = {};
        Object.keys(defaultValues).forEach((field) => {
            tmpFormConfig[field] = {
                name: field,
                label: fiscalYearFormTexts[field],
                year1: defaultValues[field].year1,
                year2: defaultValues[field].year2,
                total: defaultValues[field].total,
            };
        });
        setFormConfig(tmpFormConfig);
    }, [defaultValues, setFormConfig]);

    useEffect(() => {
        if (!error) return;
        const violations = error.d.response?.data?.violations;
        if (violations) {
            violations.forEach((violation) => {
                setError(violationPropertyPathMutator(violation.propertyPath), {
                    type: 'manual',
                    message: violation.message,
                });
            });
        }
    }, [error, setError]);

    const handleSubmitCallback = (values) => {
        submitFunc({
            proposalId,
            data: { ...defaultValues, ...values },
        });
    };

    useEffect(() => {
        const year1 = sumUpColumn(
            {
                ...watchAllFields,
                orgaAllowance: defaultValues.orgaAllowance,
                prePostProcessing: defaultValues.prePostProcessing,
            },
            'year1'
        );
        const year2 = sumUpColumn(
            {
                ...watchAllFields,
                orgaAllowance: defaultValues.orgaAllowance,
                prePostProcessing: defaultValues.prePostProcessing,
            },
            'year2'
        );

        setSumYear1(dineroFormat(year1));
        setSumYear2(dineroFormat(year2));
        setTotal(dineroFormat(year1 + year2));

        const diff = parseInt(defaultValues.totalSum) - (year1 + year2);
        setDiff(diff);
    }, [
        watchAllFields,
        classes.colorRed,
        classes.colorGreen,
        classes.colorPrimaryText,
        classes.colorSecondaryText,
        defaultValues.orgaAllowance,
        defaultValues.prePostProcessing,
        defaultValues.totalSum,
    ]);

    return (
        <form
            id="mainForm"
            onSubmit={handleSubmit(handleSubmitCallback)}
            noValidate
        >
            <Grid container component={Table} className={tableClasses.table}>
                <Grid
                    container
                    component={TableHead}
                    className={tableClasses.thead}
                >
                    <Grid container component={TableRow}>
                        <Grid
                            item
                            component={TableCell}
                            xs={4}
                            style={{ width: '400px' }}
                        >
                            {fiscalYearFormTexts.costs}
                        </Grid>
                        <Grid
                            item
                            component={TableCell}
                            xs={2}
                            style={{ width: '250px' }}
                        >
                            {fiscalYearFormTexts.year1} (
                            {moment(
                                currentApplicationRound.implementationBegin
                            ).format('yyyy')}
                            )
                        </Grid>
                        <Grid
                            item
                            component={TableCell}
                            xs={2}
                            style={{ width: '250px' }}
                        >
                            {fiscalYearFormTexts.year2} (
                            {moment(currentApplicationRound.implementationBegin)
                                .add(1, 'y')
                                .format('yyyy')}
                            )
                        </Grid>
                        <Grid item component={TableCell} xs={4}>
                            {fiscalYearFormTexts.remainingCosts}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container component={TableBody}>
                    <FiscalYearTableRow
                        rowConfig={formConfig.travelAllowance}
                        watch={watchAllFields.travelAllowance}
                        control={control}
                        errors={errors.travelAllowance}
                        clearErrors={clearErrors}
                    />
                    <FiscalYearTableRow
                        rowConfig={formConfig.residenceAllowance}
                        watch={watchAllFields.residenceAllowance}
                        control={control}
                        errors={errors.residenceAllowance}
                        clearErrors={clearErrors}
                    />
                    <FiscalYearTableRow
                        rowConfig={formConfig.orgaAllowance}
                        control={control}
                        disabled={true}
                    />
                    <FiscalYearTableRow
                        rowConfig={formConfig.prePostProcessing}
                        control={control}
                        disabled={true}
                    />
                    <FiscalYearTableRow
                        rowConfig={formConfig.disabledRequirements}
                        watch={watchAllFields.disabledRequirements}
                        control={control}
                        errors={errors.disabledRequirements}
                        clearErrors={clearErrors}
                    />
                </Grid>
                <Grid
                    container
                    component={TableFooter}
                    className={`${classes.backgroundGrey} ${tableClasses.tfoot}`}
                >
                    <Grid container component={TableRow}>
                        <Grid item component={TableCell} xs={4}>
                            {fiscalYearFormTexts.total}
                        </Grid>
                        <Grid item component={TableCell} xs={2}>
                            {sumYear1}
                        </Grid>
                        <Grid item component={TableCell} xs={2}>
                            {sumYear2}
                        </Grid>
                        <Grid item component={TableCell} xs={4}>
                            <div>{total}</div>
                            <FormHelperText>
                                {diff !== 0 && (
                                    <span>
                                        {fiscalYearFormTexts.totalDifferenceWarning(
                                            diff
                                        )}
                                    </span>
                                )}
                            </FormHelperText>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    );
};

FiscalYearForm.propTypes = {
    defaultValues: PropTypes.object,
    submitFunc: PropTypes.func.isRequired,
    saving: PropTypes.bool,
    error: PropTypes.object,
    proposalId: PropTypes.string.isRequired,
};

FiscalYearForm.defaultProps = {
    defaultValues: {},
};

export default FiscalYearForm;
