import {
    AREA_TYPES,
    STAY_TYPES,
    TRAINING_TYPES,
} from '../../util/constants/States';

export const mobilityFormTexts = {
    title: 'Mobilitäten',
    list: {
        header: {
            actions: 'Aktionen',
            destination: 'Zielland',
            type: 'Art der Mobilität',
            attendees: 'Anzahl Teilnehmende',
            total: 'Gesamt',
            start: 'Erster Arbeitstag',
            end: 'Letzter Arbeitstag',
        },
        empty: {
            text: 'Noch keine Mobilität hinzugefügt',
            button: 'Neue Mobilität hinzufügen',
            loading: 'Lädt...',
        },
    },
    edit: {
        title: 'Mobilität bearbeiten',
        request: 'Möchten Sie weitere Gelder beantragen?',
        calculated:
            'Dieser Wert wurde berechnet und kann nicht geändert werden',
        sum: 'Zwischensummen der Mobilität',
    },
    error: {
        title: 'Fehler',
        text:
            'Beim Laden der benötigten Informationen ist ein Fehler aufgetreten',
        button: 'Klicken Sie hier um es erneut zu versuchen',
    },
    fields: {
        type: {
            name: 'type',
            label: 'Art des Aufenthalts',
            rules: {
                required: 'Bitte geben Sie die Art des Aufenthalts an',
            },
            options: [
                {
                    label: 'Auszubildende, Personen in Berufsausbildung',
                    value: STAY_TYPES.apprentice,
                },
                {
                    label: 'Ausbilder/in',
                    value: STAY_TYPES.instructor,
                },
                {
                    label: 'Vorbereitender Besuch',
                    value: STAY_TYPES.preparatoryVisit,
                },
                {
                    label: 'Begleiter/in',
                    value: STAY_TYPES.companion,
                },
            ],
        },
        destination: {
            name: 'financialPlan',
            label: 'Zielland',
            rules: {
                required: 'Bitte geben Sie das Zielland an',
            },
        },
        attendees: {
            name: 'attendees',
            label: 'Anzahl der Teilnehmenden',
            type: 'number',
            rules: {
                required:
                    'Bitte geben Sie die Anzahl der Teilnehmenden für diesen Aufenthalt an',
                min: {
                    value: 1,
                    message: 'Die angegebene Anzahl muss größer als 0 sein',
                },
            },
        },
        area: {
            name: 'area',
            label: 'Berufsbereich',
            rules: {
                required: 'Bitte geben Sie den Berufsbereich an',
            },
            options: [
                {
                    label: 'Erziehung und Soziales',
                    value: AREA_TYPES.educationSocial,
                },
                {
                    label: 'Gastronomie, Hotel und Tourismus',
                    value: AREA_TYPES.hotelTourism,
                },
                {
                    label: 'Gesundheit und Pflege',
                    value: AREA_TYPES.healthcare,
                },
                {
                    label: 'Gewerblich-technische Berufe',
                    value: AREA_TYPES.tech,
                },
                { label: 'Handwerk', value: AREA_TYPES.craft },
                {
                    label:
                        'Informations- und Kommunikationstechnologien, Medien',
                    value: AREA_TYPES.media,
                },
                {
                    label: 'Kaufmännische und Dienstleistungsberufe, Handel',
                    value: AREA_TYPES.trade,
                },
                { label: 'Laborberufe', value: AREA_TYPES.laboratory },
                {
                    label: 'Land- und Forstwirtschaft',
                    value: AREA_TYPES.agricultureForestry,
                },
                {
                    label:
                        'Anderer Berufsbereich (machen Sie in diesem Fall bitte spezifische Angaben in der Projektbeschreibung)',
                    value: AREA_TYPES.other,
                },
            ],
        },
        trainingType: {
            name: 'trainingType',
            label: 'Berufsausbildung',
            rules: {
                required: 'Bitte wählen Sie die Art der Berufsausbildung aus',
            },
            options: [
                {
                    label:
                        'in dualer Berufsausbildung nach Berufsbildungsgesetz/Handwerksordnung',
                    value: TRAINING_TYPES.inTraining,
                },
                {
                    label:
                        'in (schulischer) Berufsausbildung außerhalb von Berufsbildungsgesetz/Handwerksordnung',
                    value: TRAINING_TYPES.outTraining,
                },
            ],
        },
        trainingRegulation: {
            name: 'trainingRegulation',
            label:
                'Angepasste Ausbildungsregelung nach §66 BBIG/§42 HwO für behinderte Menschen',
        },
        start: {
            name: 'start',
            label: 'Erster Arbeitstag',
            rules: {
                required: 'Bitte geben Sie den ersten Arbeitstag an',
            },
        },
        end: {
            name: 'end',
            label: 'Letzter Arbeitstag',
            rules: {
                required: 'Bitte geben Sie den letzten Arbeitstag an',
            },
        },
        duration: {
            name: 'duration',
            label: 'Bitte geben Sie den ersten und letzten Arbeitstag an',
        },
        partner: {
            name: 'partner',
            label: 'Partnerorganisation',
            rules: {
                required: 'Bitte geben Sie die Partnerorganisation an',
            },
        },
        region: {
            name: 'region',
            label: 'Region',
            rules: {
                required: 'Bitte geben Sie die Region an',
            },
        },
        city: {
            name: 'city',
            label: 'Ort',
            rules: {
                required: 'Bitte geben Sie den Ort an',
            },
        },
        reqTravelAllowance: {
            name: 'reqTravelAllowance',
            label: 'Fahrtkosten beantragen?',
            rules: {
                required:
                    'Bitte geben Sie an, ob Sie Fahrtkosten beantragen möchten',
            },
            options: [
                {
                    label: 'Ja',
                    value: '1',
                },
                {
                    label: 'Nein',
                    value: '0',
                },
            ],
        },
        travelAllowance: {
            name: 'travelAllowance',
            label: 'Zuschuss Fahrt',
            readOnly: true,
        },
        reqResidenceAllowance: {
            name: 'reqResAllowance',
            label: 'Aufenthaltskosten beantragen?',
            rules: {
                required:
                    'Bitte geben Sie an, ob Sie Aufenthaltskosten beantragen möchten',
            },
            options: [
                {
                    label: 'Ja',
                    value: '1',
                },
                {
                    label: 'Nein',
                    value: '0',
                },
            ],
        },
        residenceAllowance: {
            name: 'residenceAllowance',
            label: 'Zuschuss Aufenthalt',
            rules: {
                required: 'Bitte geben Sie die Aufenthaltskosten an',
            },
            readOnly: true,
        },
        reqOrgaAllowance: {
            name: 'reqOrgAllowance',
            label: 'Organisationsmittel beantragen?',
            rules: {
                required:
                    'Bitte geben Sie an, ob Sie Organisationsmittel beantragen möchten',
            },
            options: [
                {
                    label: 'Ja',
                    value: '1',
                },
                {
                    label: 'Nein',
                    value: '0',
                },
            ],
        },
        orgaAllowance: {
            name: 'orgaAllowance',
            label: 'Organisationsmittel',
            rules: {
                required: 'Bitte geben Sie die Organisationsmittel an',
            },
            readOnly: true,
        },
        reqPrePostProcessing: {
            name: 'reqProcessing',
            label: 'Vor- und Nachbereitung beantragen?',
            rules: {
                required:
                    'Bitte geben Sie an, ob Sie Vor- und Nachbereitung beantragen möchten',
            },
            options: [
                {
                    label: 'Ja',
                    value: '1',
                },
                {
                    label: 'Nein',
                    value: '0',
                },
            ],
        },
        prePostProcessing: {
            name: 'prePostProcessing',
            label: 'Kosten für Vor- und Nachbereitung',
            rules: {
                required:
                    'Bitte geben Sie die Kosten für Vor- und Nachbereitung an',
            },
            readOnly: true,
            info:
                'z.B. Seminar, Sprachkurs, interkulturelles Training. Die Aktivität muss während des Bewilligungszeitraums stattfinden und bei Abrechnung nachgewiesen werden können.',
        },
        disabledRequirements: {
            name: 'disabledReqs',
            label:
                'Zusätzlicher Bedarf für Menschen mit Behinderungen (in EUR)',
        },
        sumWithoutOrga: {
            name: 'sumWithoutOrga',
            label:
                'Zwischensumme für Fahrt, Aufenthalt und ggf. zusätzlicher Bedarf',
        },
        sumOrga: {
            name: 'sumOrga',
            label: 'Zwischensumme für Organisation und Vor- und Nachbereitung',
        },
        total: {
            name: 'total',
            label: 'Gesamtsumme der Mobilität',
        },
    },
    showCalculationsText:
        'Für diese Mobilität wird folgender Zuschuss beantragt:',
    showCalculationsTextEmpty: 'Sie haben keinen Zuschuss beantragt.',
    durationHint: 'Reisetage sind keine Arbeitstage',
    duration: {
        hint: 'Reisetage sind keine Arbeitstage',
        minDateError: 'Datum sollte nicht vor dem ersten Arbeitstag liegen',
        maxDateError: 'Datum sollte nicht nach dem letzten Arbeitstag liegen',
        info:
            'Die Aufenthaltsdauer wird bestimmt anhand der Tage zwischen dem ersten und dem letzten vollständigen ' +
            'Arbeitstag. Die Tage der Hin- oder Rückreise werden grundsätzlich nicht als Aufenthaltstage gewertet. ' +
            'Dies gilt auch, wenn im individuellen Fall noch Teile des Fachprogramms am An- oder Abreisetag ' +
            'absolviert werden konnten. Die (Mindest-)Aufenthaltsdauer muss mit dem Verwendungsnachweis belegt ' +
            'werden können.',
    },
};
