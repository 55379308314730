import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

const FooterGridElement = ({ element, ...gridProps }) => {
    const { id, render } = element;

    return (
        <Grid key={`footer_grid_element_${id}`} item {...gridProps}>
            {render(element)}
        </Grid>
    );
};

FooterGridElement.propTypes = {
    element: PropTypes.object.isRequired,
};

export default FooterGridElement;
