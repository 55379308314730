import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { Alert } from '@material-ui/lab';
import { Link as RouterLink } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';

const CoreDataWizardDisplay = (props) => {
    const { children, save } = props;

    const { register, handleSubmit } = useForm();

    return (
        <>
            <Alert severity={'info'} style={{ marginBottom: '.6em' }}>
                <Typography variant="body1">
                    Bitte überprüfen Sie die Stammdaten. Um diese zu bearbeiten,
                    klicken Sie&nbsp;
                    <RouterLink to="/stammdaten/edit">hier</RouterLink>
                    &nbsp;und wechseln damit in den Menüpunkt Stammdaten.
                </Typography>
            </Alert>
            <form id="mainForm" onSubmit={handleSubmit(save)} noValidate>
                <input
                    type="hidden"
                    name="skip"
                    value="true"
                    ref={register()}
                />
            </form>
            {children}
        </>
    );
};

CoreDataWizardDisplay.propTypes = {
    children: PropTypes.node,
    save: PropTypes.func,
};

export default CoreDataWizardDisplay;
