import crypto from 'crypto';

export function base64URLEncode(str) {
    return str
        .toString('base64')
        .replace(/\+/g, '-')
        .replace(/\//g, '_')
        .replace(/=/g, '');
}

function sha256(buffer) {
    return crypto.createHash('sha256').update(buffer).digest();
}

export function generateCodes() {
    const verify = base64URLEncode(crypto.randomBytes(43));
    const challenge = base64URLEncode(sha256(verify));
    return { codeVerify: verify, codeChallenge: challenge };
}

export function buildUrl(codeChallenge, state) {
    let query = `${process.env.REACT_APP_HOST}/oauth/authorize`;
    query += '?';
    query += `redirect_uri=${process.env.REACT_APP_HOST}/redirect`;
    query += '&response_type=code';
    query += `&client_id=${process.env.REACT_APP_CLIENT_ID}`;
    query += `&code_challenge=${codeChallenge}`;
    query += '&code_challenge_method=S256';
    query += `&state=${state}`;
    query += '&scope=user';

    return query;
}

export function buildUrlClientAuth(codeChallenge, state) {
    let query = `${process.env.REACT_APP_HOST}/oauth/authorize`;
    query += '?';
    query += 'response_type=code';
    query += `&redirect_uri=${process.env.REACT_APP_HOST}`;
    query += `&client_id=${process.env.REACT_APP_CLIENT_ID}`;
    query += `&code_challenge=${codeChallenge}`;
    query += '&code_challenge_method=S256';
    query += `&state=${state}`;
    query += '&scope=public';

    return query;
}
