import React from 'react';
import InputAdornment from '@material-ui/core/InputAdornment';
import Tooltip from '@material-ui/core/Tooltip';
import PropTypes from 'prop-types';
import green from '@material-ui/core/colors/green';

import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import withStyles from '@material-ui/core/styles/withStyles';
import { validatePhone } from '../../../util/Validation/ValidatePhone';
import { Info } from '@material-ui/icons';
import { registerFormTexts } from '../../../texts/Forms/RegisterFormTexts';
import { Typography } from '@material-ui/core';

const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
    },
}))(Tooltip);

const PhoneAdornment = (props) => {
    const { phone } = props;

    const verified = React.useMemo(() => {
        return validatePhone(phone);
    }, [phone]);

    const isValid = verified === true;

    return (
        <InputAdornment position="end" style={{ cursor: 'default' }}>
            <LightTooltip
                title={
                    <div>
                        <Typography>{registerFormTexts.phoneFormat}</Typography>
                    </div>
                }
            >
                {isValid ? (
                    <CheckIcon style={{ color: green[500] }} />
                ) : phone.length > 0 ? (
                    <CloseIcon color="error" />
                ) : (
                    <Info />
                )}
            </LightTooltip>
        </InputAdornment>
    );
};

PhoneAdornment.propTypes = {
    phone: PropTypes.string,
};

PhoneAdornment.defaultProps = {
    phone: '',
};

export default PhoneAdornment;
