export function registerMutator(values) {
    const copy = { ...values };

    copy.username = copy.email;
    copy.company = {
        name: copy.companyName,
        address: {
            street: copy.street,
            postcode: copy.postcode,
            city: copy.city,
            state: copy.state['value'],
        },
    };

    delete copy.companyName;
    delete copy.street;
    delete copy.city;
    delete copy.state;
    delete copy.postcode;
    delete copy.emailConfirm;
    delete copy.passwordConfirm;

    return copy;
}
