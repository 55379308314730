import React from 'react';
import PropTypes from 'prop-types';
import DoneIcon from '@material-ui/icons/Done';
import Typography from '@material-ui/core/Typography';
import { registerFormTexts as rft } from '../../../texts/Forms/RegisterFormTexts';
import { useRegisterSuccessStyles } from '../../../styles/components/Register/RegisterSuccessStyles';
import scrollTop from '../../../util/Snippets/ScrollTop';
import { Alert } from '@material-ui/lab';

const RegisterSuccess = () => {
    React.useEffect(() => {
        scrollTop();
    }, []);

    const classes = useRegisterSuccessStyles();

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <div
                style={{
                    display: 'inline-flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <div className={classes.box}>
                    <DoneIcon className={classes.icon} />
                    <Typography
                        variant="h5"
                        className={classes.success}
                        gutterBottom
                    >
                        {rft.success.title}
                    </Typography>
                </div>
                <Alert severity={'warning'} className={classes.text}>
                    <Typography variant="subtitle1">
                        {rft.success.body}
                    </Typography>
                </Alert>
            </div>
        </div>
    );
};

RegisterSuccess.propTypes = {
    values: PropTypes.object,
};

export default RegisterSuccess;
