import { handleError } from '../../../util/Error/HandleError';
import { Response } from '../../../util/DataTypes/Response';
import { registerErrorMapper } from './RegisterErrorMapper';
import uniqid from 'uniqid';

export function parseError(e) {
    if (e.response?.status !== 400) {
        let hydraDesc = e.response.data['hydra:description'];
        return handleError(e, {
            generic:
                hydraDesc &&
                hydraDesc.includes(
                    'username: Dieser Wert wird bereits verwendet'
                )
                    ? 'Die Registrierung war nicht erfolgreich. Prüfen Sie, ob die Email-Adresse bereits registriert ist. Bitte wenden Sie sich bei Fragen an den fachlichen Support.'
                    : 'Es tut uns leid, es ist ein unbekannter Fehler aufgetreten.',
        });
    }

    const violations = e.response?.data?.violations;

    const errors = {
        message: 'Es tut uns leid, es ist ein unbekannter Fehler aufgetreten.',
        fields: [],
    };

    if (violations.length > 0) {
        errors.message = '';
        violations.forEach((violation) => {
            if (registerErrorMapper[violation.propertyPath]) {
                errors.message +=
                    registerErrorMapper[violation.propertyPath][
                        violation.message
                    ];
            } else {
                errors.message +=
                    violation.propertyPath + ': ' + violation.message;
            }
            errors.fields.push({
                path: violation.propertyPath,
                message: violation.message,
                key: uniqid(),
            });
        });
    }

    return Response.Error(errors.message, errors);
}
