import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import InfoBalloon from './InfoBalloon';

const RHFSelect = (props) => {
    const {
        control,
        name,
        error,
        validation,
        defaultValue,
        label,
        options,
        disabled,
        className,
        helperText,
        multiple,
        info,
        onChangeCallback,
        ...rest
    } = props;

    const hasError = Boolean(error);

    const defaultEmptyValue = multiple ? [] : '';

    return (
        <Controller
            name={name}
            control={control}
            rules={validation}
            defaultValue={defaultValue || defaultEmptyValue}
            render={({ value, onChange, ref }) => {
                return (
                    <>
                        <InputLabel
                            htmlFor={`${name}id`}
                            error={hasError}
                            shrink={true}
                            required={!!validation?.required}
                        >
                            {label}
                            {info && <InfoBalloon info={info} />}
                        </InputLabel>
                        <Select
                            inputProps={{ id: `${name}id` }}
                            labelId={`${name}id`}
                            fullWidth
                            value={value || defaultEmptyValue}
                            displayEmpty={true}
                            onChange={(e) => {
                                if (onChangeCallback) {
                                    onChangeCallback(e.target.value);
                                }
                                onChange(e.target.value);
                            }}
                            className={className}
                            disabled={disabled}
                            error={Boolean(error)}
                            inputRef={ref}
                            multiple={multiple}
                            required={!!validation?.required}
                            {...rest}
                        >
                            {options &&
                                options.map((option, idx) => (
                                    <MenuItem
                                        value={option.value}
                                        key={`${option.value}.${idx}`}
                                    >
                                        {option.label}
                                    </MenuItem>
                                ))}
                        </Select>
                        {(hasError || helperText) && (
                            <FormHelperText error={hasError}>
                                {hasError ? error.message : helperText}
                            </FormHelperText>
                        )}
                    </>
                );
            }}
        />
    );
};

RHFSelect.propTypes = {
    control: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,
    rules: PropTypes.object,
    error: PropTypes.object,
    defaultValue: PropTypes.any,
    label: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    disabled: PropTypes.bool,
    className: PropTypes.string,
    helperText: PropTypes.string,
    multiple: PropTypes.bool,
    info: PropTypes.string,
    onChangeCallback: PropTypes.func,
};

RHFSelect.defaultProps = {
    rules: {},
    defaultValue: null,
    disabled: false,
    multiple: false,
    onChangeCallback: undefined,
};

export default RHFSelect;
