import _ from 'lodash';

export const authMutator = (user) =>
    _.omit(
        {
            ...user,
            firstName: user.firstname,
            lastName: user.lastname,
            email: user.username,
            isManager: user.roles.indexOf('ROLE_MANAGER') >= 0,
            fullName: `${user.title ? user.title + ' ' : ''}${user.firstname} ${
                user.lastname
            }`,
        },
        ['firstname', 'lastname', 'username']
    );
