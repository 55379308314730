import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Container } from '@material-ui/core';

const useStyles = makeStyles({
    background: {
        backgroundColor: '#f8f8f8',
        width: '100%',
        padding: '2rem 0',
    },
    content: {
        padding: '2rem',
        position: 'relative',
    },
});

const Content = ({ children }) => {
    const classes = useStyles();

    return (
        <div className={classes.background}>
            <Container>
                <Paper className={classes.content} elevation={0}>
                    {children}
                </Paper>
            </Container>
        </div>
    );
};

export default Content;
