import React from 'react';
import PropTypes from 'prop-types';
import { Response } from '../../../util/DataTypes/Response';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';

const RegisterError = (props) => {
    const { error, setError } = props;

    React.useEffect(() => {
        if (!error) return;
        const d = error.data();
        if (d.fields) {
            d.fields.forEach((field) => {
                setError(field.path, {
                    type: 'validation',
                    message: field.message,
                });
            });
        }
    }, [error, setError]);

    if (!error) return null;

    return (
        <Alert severity="error">
            <AlertTitle>{error.message()}</AlertTitle>
        </Alert>
    );
};

RegisterError.propTypes = {
    error: PropTypes.instanceOf(Response),
    setError: PropTypes.func,
};

export default RegisterError;
