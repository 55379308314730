import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';
import NavbarButton from './NavbarButton';
import LoginButton from './LoginButton/LoginButton';
import LoginContainer from '../../../container/Login/LoginContainer';
import LoginButtonModal from './LoginButton/LoginButtonModal';
import { texts } from '../../../texts/GeneralTexts';

const useStyles = makeStyles({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        '& button:first-child': {
            marginRight: 'auto',
        },
    },
});

const AnonNavlist = () => {
    const classes = useStyles();

    return (
        <Container className={classes.root}>
            <NavbarButton href={'/'} enabled={true} title={texts.dashboard} />
            <LoginContainer
                render={LoginButton}
                renderModal={LoginButtonModal}
            />
            <NavbarButton
                href={'/registrieren'}
                enabled={true}
                title={texts.register}
            />
        </Container>
    );
};

export default AnonNavlist;
//            <NavbarButton href={'/anmelden'} enabled={true} title="Anmelden" />
