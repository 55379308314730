export function receiveMessage(window, cb) {
    return function (event) {
        if (event.data.source === 'react-devtools-bridge') return;
        if (event.data.source === 'react-devtools-content-script') return;
        if (event.origin !== `${process.env.REACT_APP_HOST}`) return;
        if (!event.data?.code || !event.data?.state) return;

        window.removeEventListener('message', receiveMessage);
        cb(event.data);
    };
}
