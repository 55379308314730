export const coreDataOverviewTexts = {
    title: 'Stammdatenübersicht',
    coreDataTextInfo: [
        'Bitte überprüfen Sie die Stammdaten und vervollständigen Sie diese, falls nötig.',
        'Zu den Stammdaten gehören die Angaben zur Einrichtung, zur Bankverbindung der Einrichtung, zur ' +
            'zeichnungsberechtigten Person und ihrer Vertretung. Diese Angaben werden in einen Antrag übernommen und auch ' +
            'bei weiteren Bearbeitungsschritten (z. B. Bescheiden) verwendet. Änderungen wirken sich auf alle laufenden ' +
            'Projekte aus. Sind Stammdaten einmal eingetragen, müssen Änderungen erst von der NA beim BIBB freigegeben ' +
            'werden. Bis dahin kann ein Antrag nicht übermittelt werden.',
        'Die Stammdaten können von Personen mit der Rolle "Zugangsmanager" bearbeitet werden.',
    ],
    signatory: 'Zeichnungsberechtigte Person',
    representative: 'Vertretung zeichnungsberechtigte Person (optional)',
    representativeDelete: 'Vertretung zeichnungsberechtigte Person löschen',
    representativeInfo:
        'Wenn eine Vertretung eingetragen wird, müssen alle Felder ausgefüllt werden und die Vertretung muss zusätzlich zur zeichnungsberechtigten Person den Antrag unterschreiben.',
    bankAccountData: 'Bankdaten',
    bankAccountDataInfo:
        'Es darf nur die Bankverbindung der antragstellenden Einrichtung angegeben werden. Bei Beruflichen Schulen kann dies auch die übergeordnete Behörde sein. Ein Verwendungszweck kann bei einer Mittelanforderung mitgeteilt werden. Zuschüsse werden nicht auf Konten von Privatpersonen oder anderen juristischen Personen als der antragstellenden Einrichtung ausgezahlt.',
    noData: 'Keine Daten eingetragen',
    company: 'Einrichtung',
    reload: 'Daten neu laden',
    edit: 'Daten bearbeiten',
    coreDataBeingLoaded: 'Stammdaten werden geladen',
    coreDataError: 'Stammdatenfehler',
    editCoreData: 'Stammdatenänderung beantragen',
    firstTimeEditCoreData:
        'Vervollständigen Sie die Stammdaten Ihrer Einrichtung',
    companyData: 'Einrichtung',
    employees: 'Beschäftigte',
    apprentices: 'Auszubildende',
    confirmSave: 'Sind Sie sicher?',
    confirmSaveText:
        'Wenn Sie auf "Abschicken" drücken, beantragen Sie eine Stammdatenänderung, welche erst von der NA beim BIBB genehmigt werden muss.',
    coreDataInfo:
        'Zu den Stammdaten gehören die Angaben zur Organisation, ihrer Bankverbindung und der zeichnungsberechtigten Person. Diese Angaben werden in einen Antrag übernommen und auch bei weiteren Bearbeitungsschritten (z.B. Bescheiden) verwendet. Änderungen wirken sich auf alle laufenden Projekte aus und müssen daher erst von der NA bestätigt werden, bevor sie wirksam werden.',
    coreDataInfoDisabled:
        'Bitte überprüfen Sie die Stammdaten. Um diese zu bearbeiten, klicken Sie ',
    coreDataChangeRequest:
        'Ihre Stammdatenänderungen werden durch die NA beim BIBB zur Zeit noch geprüft.',
    coreDataUpdateRequired: 'Ihre Stammdaten müssen aktualisiert werden.',
    coreDataSupport:
        'Falls Sie Fragen haben, wenden Sie sich an Tel.: 0228 / 107 – 1611 oder E-Mail: ausbildung-weltweit@bibb.de.',
    legalFormInfo:
        'Rechtsform bei Beruflichen Schulen: Für Berufliche Schulen in öffentlicher Trägerschaft trifft vielfach die Kategorie „Nichtrechtsfähige Anstalt des öffentlichen Rechts“ zu. Bitte prüfen Sie bei Ihrer Zuordnung, ob diese Kategorie tatsächlich zutrifft. ',
    ustAbzugText: {
        '0': 'nicht berechtigt',
        '1': 'teilweise berechtigt',
        '2': 'berechtigt',
    },
};
