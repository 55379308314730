import React from 'react';
import { downloadFinalPdf } from '../../lib/api/Finalization/FinalizationActions';
import { GetApp } from '@material-ui/icons';

const ProposalPdfDownloader = (props) => {
    const { pdfMediaIri, projectId } = props;

    const download = async () => {
        try {
            const response = await downloadFinalPdf(pdfMediaIri);

            const type = response.headers['content-type'];
            const blob = new Blob([response.data], {
                type: type,
            });
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = projectId + '_AusbildungWeltweit_Antrag.pdf';
            link.click();
            link.remove();
        } catch (e) {}
    };

    return <GetApp fontSize="inherit" onClick={download}></GetApp>;
};

export default ProposalPdfDownloader;
