import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import LinearProgress from '@material-ui/core/LinearProgress';
import red from '@material-ui/core/colors/red';
import Typography from '@material-ui/core/Typography';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { texts } from '../../../../texts/GeneralTexts';

const LoginButtonModal = (props) => {
    const { open, isLoading, status, onClose, error, retry } = props;

    const handleClose = () => {
        if (isLoading) return;
        onClose(false);
    };

    return (
        <Dialog
            role="main"
            PaperProps={{
                'aria-label': 'Login Dialog',
            }}
            open={open}
            onClose={handleClose}
        >
            <DialogTitle>{texts.login}</DialogTitle>
            <DialogContent style={{ width: 400 }}>
                <DialogContentText component="div">
                    <Typography variant="subtitle2">{status}</Typography>
                    {error && (
                        <Typography
                            variant="subtitle1"
                            style={{ color: red[700] }}
                        >
                            {error}
                        </Typography>
                    )}
                </DialogContentText>
                <LinearProgress
                    aria-label={texts.login}
                    variant={isLoading ? 'indeterminate' : 'determinate'}
                    value={0}
                    color="primary"
                />
            </DialogContent>
            {error && (
                <DialogActions>
                    <Button
                        color="primary"
                        variant="outlined"
                        onClick={retry}
                        disabled={isLoading}
                        size="small"
                    >
                        {texts.retry}
                    </Button>
                </DialogActions>
            )}
        </Dialog>
    );
};

LoginButtonModal.propTypes = {
    open: PropTypes.bool,
    status: PropTypes.string,
    isLoading: PropTypes.bool,
    onClose: PropTypes.func,
    error: PropTypes.string,
};

export default LoginButtonModal;
