import React from 'react';
import PropTypes from 'prop-types';
import { downloadPdf } from '../../../lib/api/Finalization/FinalizationActions';
import { useParams } from 'react-router-dom';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import { finalizationFormTexts } from '../../../texts/Forms/FinalizationFormTexts';

const FinalizationProjectPdf = (props) => {
    const { onComplete, data } = props;
    const { proposalId } = useParams();

    const [progress, setProgress] = React.useState(-1);

    let downloadText = finalizationFormTexts.pdf;

    const handleClick = async (e) => {
        e.preventDefault();
        try {
            setProgress(0);
            const response = await downloadPdf(proposalId);

            const type = response.headers['content-type'];
            const blob = new Blob([response.data], {
                type: type,
            });
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download =
                data.projectId + '_AusbildungWeltweit_Antragsentwurf.pdf';
            link.click();
            link.remove();

            onComplete();
        } catch (e) {}
        setProgress(-1);
    };

    return (
        <span style={{ display: 'block', textAlign: 'center' }}>
            <Button
                onClick={handleClick}
                size="large"
                variant="contained"
                color="primary"
                style={{ marginTop: '16px' }}
                disabled={progress >= 0}
            >
                <span>
                    <BorderColorIcon style={{ marginRight: '16px' }} />{' '}
                    {downloadText}
                    {progress >= 0 && (
                        <LinearProgress variant="indeterminate" />
                    )}
                </span>
            </Button>
        </span>
    );
};

FinalizationProjectPdf.propTypes = {
    onComplete: PropTypes.func.isRequired,
    data: PropTypes.object,
};

export default FinalizationProjectPdf;
