import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import BundesTtf from './static/fonts/bundessans/BundesSansWeb-Regular.ttf';
import BundesWoff from './static/fonts/bundessans/BundesSansWeb-Regular.woff';
import BundesSvg from './static/fonts/bundessans/BundesSansWeb-Regular.svg';

const bundesRegular = {
    fontFamily: 'BundesSansWeb',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    src: `
        url(${BundesWoff}) format('woff'),
        url(${BundesTtf}) format('truetype'),
        url(${BundesSvg}#fdcee3472cc53561b5916afc05bd2e03) format('svg')
    `,
};

export const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#004F80',
            contrastText: '#fff',
            light: '#0187DE',
            dark: '#002944',
        },
        secondary: {
            main: 'rgba(35,97,78,0.7)',
            contrastText: '#fff',
            light: 'rgba(35,97,78,0.4)',
            dark: 'rgba(35,97,78,1.0)',
        },
        error: {
            main: '#d32f2f',
            contrastText: '#fff',
            light: '#ef5350',
            dark: '#c62828',
        },
    },
    typography: {
        fontFamily: '"BundesSansWeb", Helvetica, Arial, sans-serif',
    },
    overrides: {
        MuiCssBaseline: {
            '@global': {
                '@font-face': [bundesRegular],
            },
        },
    },
});
