import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { Controller } from 'react-hook-form';
import { useMobilityEditStyles } from '../../../styles/components/forms/MobilityForm/MobilityEditStyles';

const MobilityDropdown = (props) => {
    const { error, name, options, control, rules, defaultValue, label } = props;
    const classes = useMobilityEditStyles();
    return (
        <div className={classes.input}>
            <Controller
                render={(params) => (
                    <TextField
                        {...params}
                        id={name}
                        fullWidth
                        select
                        label={label}
                        error={Boolean(error)}
                        helperText={Boolean(error) ? error.message : undefined}
                        required={!!rules.required}
                    >
                        {options.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                )}
                name={name}
                control={control}
                defaultValue={defaultValue}
                rules={rules}
            />
        </div>
    );
};

MobilityDropdown.propTypes = {
    control: PropTypes.object.isRequired,
    rules: PropTypes.object,
    options: PropTypes.array,
    name: PropTypes.string.isRequired,
    error: PropTypes.object,
    defaultValue: PropTypes.string,
    label: PropTypes.string,
};

MobilityDropdown.defaultProps = {
    rules: {},
    options: [],
    error: undefined,
    defaultValue: '',
};

export default MobilityDropdown;
