export function fetchMutator(fiscalYearsResponse) {
    const { fiscalYears, requestedTotal } = fiscalYearsResponse;
    return {
        id: {
            year1: fiscalYears[0].id,
            year2: fiscalYears[1].id,
        },
        travelAllowance: {
            year1: fiscalYears[0].requestedBudget.travel,
            year2: fiscalYears[1].requestedBudget.travel,
            total: requestedTotal.travel,
        },
        residenceAllowance: {
            year1: fiscalYears[0].requestedBudget.residing,
            year2: fiscalYears[1].requestedBudget.residing,
            total: requestedTotal.residing,
        },
        orgaAllowance: {
            year1: fiscalYears[0].requestedBudget.organisation,
            year2: fiscalYears[1].requestedBudget.organisation,
            total: requestedTotal.organisation,
        },
        prePostProcessing: {
            year1: fiscalYears[0].requestedBudget.processing,
            year2: fiscalYears[1].requestedBudget.processing,
            total: requestedTotal.processing,
        },
        disabledRequirements: {
            year1: fiscalYears[0].requestedBudget.disabledReqs,
            year2: fiscalYears[1].requestedBudget.disabledReqs,
            total: requestedTotal.disabledReqs,
        },
        totalSum: requestedTotal.total,
    };
}

/*
    travelAllowance: {
        year1: '100',
        year2: '200',
        max: '600',
    },
    residenceAllowance: {
        year1: '100',
        year2: '200',
        max: '600',
    },
    orgaAllowance: {
        year1: '100',
        year2: '200',
        max: '300',
    },
    prePostProcessing: {
        year1: '100',
        year2: '200',
        max: '300',
    },
    disabledRequirements: {
        year1: '100',
        year2: '200',
        max: '600',
    },
 */
export function persistMutator(data) {
    return {
        fiscalYears: [
            {
                id: data.id.year1,
                requestedBudget: {
                    travel: data.travelAllowance.year1,
                    residing: data.residenceAllowance.year1,
                    organisation: data.orgaAllowance.year1,
                    processing: data.prePostProcessing.year1,
                    disabledReqs: data.disabledRequirements.year1,
                },
            },
            {
                id: data.id.year2,
                requestedBudget: {
                    travel: data.travelAllowance.year2,
                    residing: data.residenceAllowance.year2,
                    organisation: data.orgaAllowance.year2,
                    processing: data.prePostProcessing.year2,
                    disabledReqs: data.disabledRequirements.year2,
                },
            },
        ],
    };
}

export function violationPropertyPathMutator(path) {
    switch (path) {
        case 'first.travel':
            return 'travelAllowance.year1';
        case 'first.residing':
            return 'residenceAllowance.year1';
        case 'first.organisation':
            return 'orgaAllowance.year1';
        case 'first.processing':
            return 'prePostProcessing.year1';
        case 'first.disabledReqs':
            return 'disabledRequirements.year1';
        case 'second.travel':
            return 'travelAllowance.year2';
        case 'second.residing':
            return 'residenceAllowance.year2';
        case 'second.organisation':
            return 'orgaAllowance.year2';
        case 'second.processing':
            return 'prePostProcessing.year2';
        case 'second.disabledReqs':
            return 'disabledRequirements.year2';
        case 'travel.total':
            return 'travelAllowance.total';
        case 'residing.total':
            return 'residenceAllowance.total';
        case 'organisation.total':
            return 'orgaAllowance.total';
        case 'processing.total':
            return 'prePostProcessing.total';
        case 'disabledReqs.total':
            return 'disabledRequirements.total';
        default:
            return '';
    }
}
