import { validateIban } from './ValidateIban';
import isValidBic from './ValidateBic';
import { validateHomepage } from './ValidateHomepage';
import { validatePlz, validatePlzOptional } from './ValidatePlz';
import { validatePassword } from './PasswordValidation';
import { validateFax } from './ValidateFax';
import { validateNumberOfEmployees } from './ValidateNumberOfEmployees';

export const validations = {
    salutation: {
        required: 'Bitte eine Anrede auswählen',
    },
    firstName: {
        required: 'Bitte einen Vornamen angeben',
    },
    lastName: {
        required: 'Bitte einen Nachnamen angeben',
    },
    email: {
        required: 'Bitte eine E-Mail angeben',
        pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,20}$/i,
            message: 'Die angegebene E-Mail-Adresse ist ungültig',
        },
    },
    emailOptional: {
        pattern: {
            value: /^([A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,20})?$/i,
            message: 'Die angegebene E-Mail-Adresse ist ungültig',
        },
    },
    password: {
        required: 'Bitte ein Passwort angeben',
        validate: (value) =>
            validatePassword(
                value,
                'Das Passwort entspricht nicht den Richtlinien'
            ),
    },
    phone: {
        required: 'Bitte eine Telefonnummer angeben',
        pattern: {
            value: /^0[0-9 ]{5,20}$/,
            message:
                'Die angegebene Telefonnummer ist ungültig. Gültiges Format: Vorwahl und Rufnummer ohne Leerstellen und Sonderzeichen (z.B. 04567067345)',
        },
    },
    phoneOptional: {
        pattern: {
            value: /^(0[0-9 ]{5,20})?$/,
            message:
                'Die angegebene Telefonnummer ist ungültig. Gültiges Format: Vorwahl und Rufnummer ohne Leerstellen und Sonderzeichen (z.B. 04567067345)',
        },
    },
    fax: {
        validate: (value) =>
            validateFax(
                value,
                'Die angegebene Faxnummer ist ungültig. Gültiges Format: Vorwahl und Rufnummer ohne Leerstellen und Sonderzeichen (z.B. 04567067345)'
            ),
    },
    street: {
        required: 'Bitte eine Straße und Hausnummer angeben',
    },
    postCode: {
        required: 'Bitte eine PLZ angeben',
        validate: (value) => validatePlz(value, 'Die PLZ ist ungültig'),
    },
    postCodeOptional: {
        validate: (value) => validatePlzOptional(value, 'Die PLZ ist ungültig'),
    },
    city: {
        required: 'Bitte einen Ort angeben',
    },
    state: {
        required: 'Bitte ein Bundesland auswählen',
    },
    func: {
        required: 'Bitte die Funktion angeben',
    },
    department: {
        required: 'Bitte die Abteilung angeben',
    },
    homepage: {
        validate: (value) =>
            validateHomepage(value, 'Die angegebene Homepage ist ungültig'),
    },
    numberOfApprentices: {
        required: 'Bitte die Anzahl der Auszubildenden angeben',
        validate: (value) =>
            validateNumberOfEmployees(
                value,
                'Die Anzahl darf nicht negativ sein'
            ),
    },
    numberOfEmployees: {
        required: 'Bitte die Anzahl der Beschäftigten angeben',
        validate: (value) =>
            validateNumberOfEmployees(
                value,
                'Die Anzahl darf nicht negativ sein'
            ),
    },
    companyName: {
        required: 'Bitte einen Namen angeben',
    },
    bankAccount: {
        name: {
            required: 'Bitte einen Kontoinhaber angeben',
        },
        iban: {
            required: 'Bitte eine IBAN angeben',
            validate: (value) =>
                validateIban(value, 'Die angegebene IBAN ist ungültig'),
        },
        bic: {
            required: 'Bitte eine BIC angeben',
            validate: (value) =>
                isValidBic(value, 'Die angegebene BIC hat ein falsches Format'),
        },
        institute: {
            required: 'Bitte ein Kreditinstitut angeben',
        },
        type: {
            required: 'Bitte einen Typ angeben',
        },
    },
    moneyInputCannotBeNegative: {
        validate: (value) =>
            value >= 0 || 'Dieser Wert darf nicht negativ sein.',
    },
    legalForm: {
        required: 'Bitte eine Rechtsform auswählen',
    },
    ustAbzug: {
        required: 'Bitte eine Option auswählen',
    },
};
