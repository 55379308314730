export const fetchMutator = (data) => ({
    ...data,
    otherwiseFinanced: data.otherwiseFinanced ? '1' : '0',
    furtherSpending: data.furtherSpending ? '1' : '0',
    financedAmount: data.otherwiseFinanced ? data.financedAmount : null,
    financedBy: data.otherwiseFinanced ? data.financedBy : null,
    isForAcademics:
        data.isForAcademics !== undefined && data.isForAcademics !== null
            ? data.isForAcademics.toString()
            : '2',
});

export const persistMutator = (data) => {
    return {
        ...data,
        otherwiseFinanced: data.otherwiseFinanced === '1',
        furtherSpending: data.furtherSpending === '1',
        financedAmount:
            data.otherwiseFinanced === '1' ? data.financedAmount : null,
        financedBy: data.otherwiseFinanced === '1' ? data.financedBy : null,
        isForAcademics:
            data.isForAcademics !== undefined && data.isForAcademics !== null
                ? parseInt(data.isForAcademics)
                : null,
    };
};
