import React from 'react';
import PropTypes from 'prop-types';
import DialogContent from '@material-ui/core/DialogContent';
import MobilityEdit from '../MobilityEdit/MobilityEdit';
import MobilityDialogError from './MobilityDialogError';

const MobilityDialogContent = (props) => {
    const { responseError, ...rest } = props;
    return (
        <DialogContent>
            <MobilityDialogError responseError={responseError} />
            <MobilityEdit {...rest} responseErrors={responseError} />
        </DialogContent>
    );
};

MobilityDialogContent.propTypes = {
    form: PropTypes.object,
    scrollTop: PropTypes.func,
    responseError: PropTypes.object,
};

export default MobilityDialogContent;
