import React from 'react';
import PropTypes from 'prop-types';
import { FormControl } from '@material-ui/core';
import FormLabel from '@material-ui/core/FormLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import FormHelperText from '@material-ui/core/FormHelperText';
import { useMobilityEditStyles } from '../../../styles/components/forms/MobilityForm/MobilityEditStyles';

const MobilityRadioButtons = (props) => {
    const {
        defaultValue,
        error,
        name,
        register,
        label,
        options,
        rules,
    } = props;
    const classes = useMobilityEditStyles();
    const hasError = Boolean(error);

    return (
        <FormControl
            error={hasError}
            component="fieldset"
            className={classes.input}
            required={!!rules.required}
        >
            <FormLabel component="legend">{label}</FormLabel>
            <RadioGroup
                aria-label={label}
                name={name}
                defaultValue={defaultValue}
            >
                {options.map((option, id) => (
                    <FormControlLabel
                        {...option}
                        key={option.label + '_' + id}
                        control={<Radio color="primary" />}
                        inputRef={register(rules)}
                    />
                ))}
            </RadioGroup>
            {hasError && <FormHelperText>{error.message}</FormHelperText>}
        </FormControl>
    );
};

MobilityRadioButtons.propTypes = {
    name: PropTypes.string,
    register: PropTypes.func,
    error: PropTypes.object,
    defaultValue: PropTypes.string,
    label: PropTypes.string,
    options: PropTypes.array.isRequired,
    rules: PropTypes.object,
};

export default MobilityRadioButtons;
