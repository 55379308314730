import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import { Controller } from 'react-hook-form';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import Typography from '@material-ui/core/Typography';

const RadioButtons = (props) => {
    const {
        buttons,
        control,
        defaultValue,
        ariaLabel,
        className,
        groupLabel,
        headlineGroupLabel,
        groupSubLabel,
        name,
        disabled,
        rules,
        errors,
        ...rest
    } = props;

    const hasError = !_.isEmpty(errors);

    return (
        <FormControl
            className={className}
            error={hasError}
            fullWidth
            required={Boolean(rules.required)}
        >
            <div>
                {groupLabel && (
                    <Typography
                        style={{
                            display: 'inline-block',
                            marginRight: '.4em',
                            color: hasError ? false : 'rgba(0, 0, 0, 0.87)',
                            fontSize: headlineGroupLabel ? '1.1rem' : '1rem',
                        }}
                    >
                        {groupLabel}
                    </Typography>
                )}
                {groupSubLabel && (
                    <Typography
                        style={{
                            display: 'block',
                            color: hasError ? false : 'rgba(0, 0, 0, 0.87)',
                        }}
                    >
                        {groupSubLabel}
                    </Typography>
                )}
            </div>
            <Controller
                as={
                    <RadioGroup aria-label={ariaLabel}>
                        {buttons.map((button, idx) => (
                            <FormControlLabel
                                disabled={disabled}
                                control={<Radio color="primary" />}
                                label={button.label}
                                value={button.value}
                                key={`${button.value}.${idx}`}
                            />
                        ))}
                    </RadioGroup>
                }
                name={name}
                control={control}
                defaultValue={defaultValue === undefined ? null : defaultValue}
                rules={rules}
                {...rest}
            />
            {hasError && (
                <FormHelperText error={hasError}>
                    {errors.message}
                </FormHelperText>
            )}
        </FormControl>
    );
};

RadioButtons.propTypes = {
    control: PropTypes.object.isRequired,
    defaultValue: PropTypes.string,
    buttons: PropTypes.array.isRequired,
    groupLabel: PropTypes.string.isRequired,
    headlineGroupLabel: PropTypes.bool,
    groupSubLabel: PropTypes.string,
    ariaLabel: PropTypes.string,
    className: PropTypes.string,
    name: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    rules: PropTypes.object,
    errors: PropTypes.object,
};

RadioButtons.defaultProps = {
    defaultValue: undefined,
    disabled: false,
    rules: {},
    errors: {},
    groupSubLabel: undefined,
    headlineGroupLabel: false,
};

export default RadioButtons;
