export const pages = [
    {
        label: 'Startseite',
        href: '/',
        roles: [],
    },
    {
        label: 'Stammdaten',
        href: '/stammdaten',
        roles: ['ROLE_MANAGER', 'ROLE_CONTACT'],
    },
    {
        label: 'Anträge',
        href: '/antrag',
        roles: ['ROLE_MANAGER', 'ROLE_CONTACT'],
    },
    {
        label: 'Vorhaben',
        href: process.env.REACT_APP_HOST + '/antragsteller/vorhaben',
        roles: ['ROLE_MANAGER', 'ROLE_CONTACT'],
        type: 'external',
    },
    {
        label: 'Team',
        href: '/team',
        roles: ['ROLE_MANAGER', 'ROLE_MANAGER'],
    },
    {
        label: 'Abmelden',
        href: '/abmelden',
        roles: [],
    },
];
