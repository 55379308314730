import makeStyles from '@material-ui/core/styles/makeStyles';
import { fade } from '@material-ui/core';

export const useWizardFormButtonsStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
    },
    spacing: {
        marginLeft: theme.spacing(1),
    },
    reset: {
        color: theme.palette.error.main,
        border: `1px solid ${theme.palette.error.main}`,
        '&:hover': {
            backgroundColor: fade(theme.palette.error.light, 0.04),
            border: `1px solid ${theme.palette.error.main}`,
        },
    },
    progress: {
        verticalAlign: 'middle',
    },
    saving: {
        position: 'absolute',
        left: 'calc(100% + 8px)',
        top: '50%',
        transform: 'translateY(-50%)',
    },
}));
