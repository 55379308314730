import moment from 'moment';
import { STAY_LENGTHS_BY_TYPE, STAY_TYPES } from '../constants/States';

export function diffDuration(duration) {
    if (duration === undefined || duration === null) return 0;
    if (duration.length < STAY_LENGTHS_BY_TYPE.default.min) return 0;

    if (duration.start === null || duration.end === null) return 0;
    if (!duration.start || !duration.end) return 0;

    const start = moment(duration.start).startOf('day');
    const end = moment(duration.end).startOf('day');

    return end.diff(start, 'days') + 1;
}

export function validateDuration(duration, type) {
    if (duration === undefined || duration === null) return;
    if (type === undefined || type === null) return;

    let minLength = STAY_LENGTHS_BY_TYPE.default.min;
    let maxLength = STAY_LENGTHS_BY_TYPE.default.max;

    if (type === STAY_TYPES.apprentice) {
        minLength = STAY_LENGTHS_BY_TYPE.apprentice.min;
        maxLength = STAY_LENGTHS_BY_TYPE.apprentice.max;
    }

    if (type === STAY_TYPES.instructor) {
        minLength = STAY_LENGTHS_BY_TYPE.instructor.min;
        maxLength = STAY_LENGTHS_BY_TYPE.instructor.max;
    }

    if (type === STAY_TYPES.preparatoryVisit) {
        minLength = STAY_LENGTHS_BY_TYPE.preparatoryVisit.min;
        maxLength = STAY_LENGTHS_BY_TYPE.preparatoryVisit.max;
    }

    const diff = diffDuration(duration);

    if (diff === 0)
        return 'Bitte geben Sie den ersten und letzten Arbeitstag an';
    if (diff < minLength)
        return `Die Dauer des Aufenthalts muss mindestens ${minLength} Tage betragen`;
    if (diff > maxLength)
        return `Die Dauer des Aufenthalts kann maximal ${maxLength} Tage betragen`;

    return true;
}
