import React from 'react';
import PropTypes from 'prop-types';

const TextItalic = (props) => {
    const { text } = props;

    return <span style={{ fontStyle: 'italic' }}>{text}</span>;
};

TextItalic.propTypes = {
    text: PropTypes.string.isRequired,
};

export default TextItalic;
