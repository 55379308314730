export const teamTexts = {
    title: 'Team',
    infoText: [
        'Die Person mit der Rolle eines Zugangsmanagers kann hier die Angaben zur eigenen Person vervollständigen. Sie ' +
            'hat alle Bearbeitungsrechte in allen Antragsrunden.',
        'Ein Zugangsmanager kann in diesem Bereich weitere Kontaktpersonen anlegen und verwalten (ändern, Zugang ' +
            'deaktivieren oder neu zuordnen). Die Zuordnung einer Kontaktperson zu einer Antragsrunde erfolgt in Step ' +
            '2 nach dem Anlegen eines Antrags. Eine Kontaktperson erhält damit die Bearbeitungsrechte für diesen ' +
            'Antrag/dieses Vorhaben.',
        'Die Rolle des Zugangsmanagers kann nur von der NA beim BIBB auf eine andere Person übertragen werden.',
    ],
    reloadTooltip: 'Teamdaten erneut herunterladen',
    addDialog: {
        titleAdd: 'Neue Person hinzufügen',
        titleEdit: 'Person bearbeiten',
    },
    addTeamMember: 'Person hinzufügen',
    editTeamMember: 'Person bearbeiten',
    editApplicationRound: 'Antragsrunde zuordnen',
    confirmNewContactPerson:
        'Bitte bestätigen Sie, dass Sie mit dieser Änderung die folgenden Kontaktpersonen aus diesen Antragsrunden entfernen.',
    applicationRoundEditSuccess: 'Antragsrunde erfolgreich zugeordnet.',
    applicationRoundEditFailure: 'Antragsrunde konnte nicht zugeordnet werden.',
    role: {
        user: 'Person',
        contact: 'Kontaktperson',
        manager: 'Zugangsmanager',
    },
    phoneFormat:
        'Format: Vorwahl und Rufnummer ohne Leerstellen und Sonderzeichen (z.B. 04567067345)',
};
