import React from 'react';
import PropTypes from 'prop-types';

const TextBold = (props) => {
    const { text } = props;

    return <span style={{ fontWeight: 'bold' }}>{text}</span>;
};

TextBold.propTypes = {
    text: PropTypes.string.isRequired,
};

export default TextBold;
