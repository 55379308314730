import PropTypes from 'prop-types';
import { useMutation } from 'react-query';
import { handleFormSubmit } from '../../lib/api/Team/TeamActions';
import { useSnackbar } from 'notistack';

const TeamFormContainer = (props) => {
    const { render, ...rest } = props;

    const { enqueueSnackbar } = useSnackbar();

    const [create, createStatus] = useMutation(handleFormSubmit, {
        onSuccess: (response) => {
            enqueueSnackbar(response.message(), { variant: 'success' });
            rest.handleClose();
        },
        onError: (error) => {
            enqueueSnackbar(error.message(), { variant: 'error' });
        },
    });

    return render({ create, createStatus, ...rest });
};

TeamFormContainer.propTypes = {
    render: PropTypes.func.isRequired,
    handleClose: PropTypes.func,
    user: PropTypes.object,
    refetch: PropTypes.func,
};

export default TeamFormContainer;
