import React from 'react';
import PropTypes from 'prop-types';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { useTeamTableRowStyles } from '../../../styles/components/Team/TeamTableRowStyles';
import classNames from 'classnames';
import TeamTableActions from './TeamTableActions';
import { Typography } from '@material-ui/core';
import { queryCache } from '../../../QueryCache';
import { teamTexts } from '../../../texts/components/Team/TeamTexts';

const TeamTableRow = (props) => {
    const { teamMember, ...rest } = props;
    const classes = useTeamTableRowStyles();
    const applicationRounds = queryCache.getQueryData('applicationRounds');

    return (
        <TableRow
            className={classNames({ [classes.row]: !teamMember.isActive })}
        >
            <TableCell>
                <TeamTableActions teamMember={teamMember} {...rest} />
            </TableCell>
            <TableCell>{teamMember.firstName}</TableCell>
            <TableCell>{teamMember.lastName}</TableCell>
            <TableCell>{teamMember.email}</TableCell>
            <TableCell>
                {teamMember.roles.indexOf('ROLE_MANAGER') > -1
                    ? teamTexts.role.manager
                    : teamMember.roles.indexOf('ROLE_CONTACT') > -1
                    ? teamTexts.role.contact
                    : teamTexts.role.user}
            </TableCell>
            <TableCell>{teamMember.phone}</TableCell>
            <TableCell>
                {teamMember.applicationRounds.map((userRound) => (
                    <Typography key={userRound} variant={'body2'}>
                        {
                            applicationRounds?.find(
                                (round) => round['@id'] === userRound
                            )?.label
                        }
                    </Typography>
                ))}
            </TableCell>
        </TableRow>
    );
};

TeamTableRow.propTypes = {
    teamMember: PropTypes.object.isRequired,
    handleEdit: PropTypes.func.isRequired,
    handleLock: PropTypes.func.isRequired,
    handleApplicationRoundEdit: PropTypes.func.isRequired,
};

export default TeamTableRow;
