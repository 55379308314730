import React from 'react';
import { useWizard } from '../../stores/Wizard.store';
import { useWizardTransitioningStyles } from '../../styles/components/Wizard/WizardTransitioning';
import classNames from 'classnames';
import LinearProgress from '@material-ui/core/LinearProgress';

const WizardTransitioning = (props) => {
    const classes = useWizardTransitioningStyles();
    const isTransitioning = useWizard((state) => state.isTransitioning);

    return (
        <>
            <LinearProgress
                className={classNames(classes.progress, classes.top, {
                    [classes.hidden]: !isTransitioning,
                })}
            />
            <LinearProgress
                className={classNames(classes.progress, classes.bottom, {
                    [classes.hidden]: !isTransitioning,
                })}
            />
        </>
    );
};

export default WizardTransitioning;
