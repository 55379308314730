import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import 'moment/locale/de';
import moment from 'moment';
import Dinero from 'dinero.js';

//Set global configurations
moment.locale('de');

Dinero.globalLocale = 'de-DE';
Dinero.defaultCurrency = 'EUR';
Dinero.defaultPrecision = 2;

ReactDOM.render(
    <React.Fragment>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </React.Fragment>,
    document.getElementById('root')
);
