import { Response } from '../../../util/DataTypes/Response';
import { fetchMutator, persistMutator } from './FiscalYearMutators';
import { handleError } from '../../../util/Error/HandleError';
import { api } from '../AxiosConfig/Config';

export function fetchFiscalYearData(proposalId) {
    return function () {
        return new Promise(async (resolve, reject) => {
            try {
                const { data } = await api().get(
                    `/projects/${proposalId}/fiscal_year`
                );
                resolve(fetchMutator(data));
            } catch (e) {
                reject(
                    handleError(e, {
                        generic:
                            'Beim Laden der benötigten Daten ist ein Fehler aufgetreten.',
                        401: 'Sie haben nicht die Berechtigung, die Formulardaten zu laden.',
                        403: 'Sie haben nicht die Erlaubnis, die Formulardaten zu laden.',
                        404: 'Die benötigten Formulardaten können nicht gefunden werden.',
                        500: 'Beim Versuch die Formulardaten zu laden ist ein Fehler aufgetreten.',
                        502: 'Beim Laden der Formulardaten gab es eine Zeitüberschreitung',
                        503: 'Beim Laden der Formulardaten gab es eine Zeitüberschreitung',
                        504: 'Beim Laden der Formulardaten gab es eine Zeitüberschreitung',
                    })
                );
            }
        });
    };
}

export function persistFiscalYearData({ proposalId, data }) {
    return new Promise(async (resolve, reject) => {
        try {
            await api().put(
                `/projects/${proposalId}/fiscal_year`,
                persistMutator(data),
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );
            resolve(Response.Success('Die Daten wurden gespeichert'));
        } catch (e) {
            reject(
                Response.Error('Die Daten konnten nicht gespeichert werden', e)
            );
        }
    });
}
