import React from 'react';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router';
import { Button, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const useStyles = makeStyles({
    root: {
        display: 'flex',
        alignItems: 'center',
        color: 'white',
        padding: '0.5rem 1rem',
        marginRight: '1rem',
        '&:last-child': {
            marginRight: '0',
        },
        '&:hover': {
            backgroundColor: '#FFFFFF',
            color: '#004F80',
        },
        '&:active': {
            backgroundColor: '#FFFFFF',
            color: '#004F80',
        },
    },
    active: {
        backgroundColor: '#FFFFFF',
        color: '#004F80',
    },
});

const NavbarButton = ({ href, title, enabled, type }) => {
    const location = useLocation();
    const history = useHistory();
    const classes = useStyles();

    const onClickHandler = () => {
        if (type === 'external') {
            window.location.href = href;
        } else {
            history.push(href);
        }
    };

    return (
        <Button
            onClick={onClickHandler}
            disabled={!enabled}
            className={classNames(classes.root, {
                [classes.active]: location.pathname === href,
            })}
        >
            <span style={{ minHeight: '24px' }}>{title}</span>
        </Button>
    );
};

NavbarButton.propTypes = {
    href: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    enabled: PropTypes.bool.isRequired,
    type: PropTypes.string,
};

NavbarButton.defaultProps = {
    type: 'internal',
};

export default NavbarButton;
