import makeStyles from '@material-ui/core/styles/makeStyles';

export const useWizardControlStyles = makeStyles((theme) => ({
    flex: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    bar: {
        '& div': {
            marginLeft: theme.spacing(1),
        },
    },
    button: {
        marginLeft: theme.spacing(1),
    },
    progress: {
        position: 'absolute',
    },
}));
