import axios from 'axios';
import { refreshToken } from '../Auth/AuthActions';
import { useAuth } from '../../../stores/Auth.store';

export default (api) =>
    api.interceptors.response.use(
        (response) => response,
        (error) => {
            if (!error.response || error.response.status !== 401) {
                return Promise.reject(error);
            }
            if (
                error.config.url ===
                    `${process.env.REACT_APP_HOST}/oauth/token` ||
                error.response.message === 'Account is disabled'
            ) {
                return Promise.reject(error);
            }
            return refreshToken()
                .then(() => {
                    const config = error.config;

                    return new Promise((resolve, reject) => {
                        axios
                            .request(config)
                            .then((response) => resolve(response))
                            .catch((error) => reject(error));
                    });
                })
                .catch(async (error) => {
                    if (error.request?.status === 400) {
                        const { setState } = useAuth;
                        await setState({
                            user: undefined,
                            error:
                                'Sie wurden wegen zu langer Inaktivität abgemeldet',
                        });
                    }
                    return Promise.reject(error);
                });
        }
    );
