import moment from 'moment';

export const fetchMutator = (applicationRounds) => {
    return applicationRounds.map((round) => ({
        ...round,
        value: round['@id'],
        label: `AR${round.number}/${moment(round.begin).year()} (${moment(
            round.implementationBegin
        ).format('DD.MM.yyyy')} - ${moment(round.implementationEnd).format(
            'DD.MM.yyyy'
        )})`,
    }));
};
