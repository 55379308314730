import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import DirectiveResult from './DirectiveResult';
import DirectiveSummary from './DirectiveSummary';
import { registerFormTexts as rft } from '../../../../texts/Forms/RegisterFormTexts';

const Directive = (props) => {
    const {
        hasLength,
        hasUppercase,
        hasLowercase,
        hasSpecial,
        hasNumber,
        hasThree,
    } = props;

    return (
        <div>
            <Typography>{rft.passwordRules}</Typography>
            <DirectiveResult
                fulfilled={hasLength}
                text={rft.directive.length}
            />
            <Typography>{rft.additional}</Typography>
            <DirectiveResult
                fulfilled={hasUppercase}
                text={rft.directive.upperCase}
            />
            <DirectiveResult
                fulfilled={hasLowercase}
                text={rft.directive.lowerCase}
            />
            <DirectiveResult
                fulfilled={hasSpecial}
                text={rft.directive.specialCharacter}
            />
            <DirectiveResult
                fulfilled={hasNumber}
                text={rft.directive.number}
            />
            <DirectiveSummary hasLength={hasLength} hasThree={hasThree} />
        </div>
    );
};

Directive.propTypes = {
    hasLength: PropTypes.bool,
    hasUppercase: PropTypes.bool,
    hasLowercase: PropTypes.bool,
    hasSpecial: PropTypes.bool,
    hasNumber: PropTypes.bool,
    hasThree: PropTypes.bool,
};

export default Directive;
