import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { coreDataOverviewTexts as cdot } from '../../../texts/components/CoreData/CoreDataOverviewTexts';
import CoreDataDisplay from './Display/CoreDataDisplay';
import CoreDataFormDialog from '../../Forms/CoreDataFormNew/CoreDataFormDialog';
import { useSnackbar } from 'notistack';
import scrollTop from '../../../util/Snippets/ScrollTop';
import { Alert } from '@material-ui/lab';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../../stores/Auth.store';

const CoreDataOverview = (props) => {
    const {
        data,
        isFetching,
        refetch,
        mutateCoreData,
        isLoading,
        error,
        isSaving,
        initialEditMode,
    } = props;

    const user = useAuth((state) => state.user);
    const userCanEdit = user ? user.roles.indexOf('ROLE_MANAGER') > -1 : false;

    const history = useHistory();

    React.useEffect(() => {
        setTimeout(() => {
            scrollTop();
        });
    }, []);

    React.useEffect(() => {
        if (initialEditMode && userCanEdit) {
            setEditMode(true);
        }
    }, [initialEditMode, userCanEdit]);

    const [responseError, setResponseError] = React.useState(undefined);

    const [editMode, setEditMode] = React.useState(false);

    const { enqueueSnackbar } = useSnackbar();

    const openEditDialog = () => {
        setEditMode(true);
    };

    const closeEditDialog = () => {
        setEditMode(false);
        history.push('/stammdaten');
    };

    const submitEditDialog = (data) => {
        mutateCoreData(data)
            .then((e) => {
                enqueueSnackbar(e.message(), { variant: 'success' });
                setEditMode(false);
                history.push('/stammdaten');
            })
            .catch((err) => {
                setResponseError(err);
            });
    };

    if (isLoading) return <p>{cdot.coreDataBeingLoaded}</p>;

    if (error) return <p>{cdot.coreDataError}</p>;

    return (
        <div>
            <div style={{ display: 'inline-block' }}>
                <Typography variant="h4" component="h1" gutterBottom>
                    {cdot.title}
                </Typography>
            </div>
            <div style={{ marginBottom: '1em' }}>
                <Alert severity={'info'}>
                    {cdot.coreDataTextInfo.map((element, index) => {
                        return (
                            <Typography key={index} variant="body2">
                                {element}
                            </Typography>
                        );
                    })}
                </Alert>
                {(data.hasPendingChangeRequest || data.dataUpdateRequired) && (
                    <Alert style={{ marginTop: '.6em' }} severity={'warning'}>
                        <div>
                            {data.hasPendingChangeRequest
                                ? cdot.coreDataChangeRequest
                                : cdot.coreDataUpdateRequired}
                        </div>
                        <div>{cdot.coreDataSupport}</div>
                    </Alert>
                )}
            </div>

            <CoreDataDisplay
                coreData={data}
                handleReload={refetch}
                handleEdit={openEditDialog}
                isFetching={isFetching}
                userCanEdit={userCanEdit}
                refetch={refetch}
            />

            <CoreDataFormDialog
                data={data}
                submitFunc={submitEditDialog}
                handleClose={closeEditDialog}
                isOpen={editMode}
                isSaving={isSaving}
                responseError={responseError}
            />
        </div>
    );
};

CoreDataOverview.propTypes = {
    data: PropTypes.object,
    isFetching: PropTypes.bool,
    refetch: PropTypes.func,
    mutateCoreData: PropTypes.func,
    isLoading: PropTypes.bool,
    error: PropTypes.object,
    isSaving: PropTypes.bool,
    initialEditMode: PropTypes.bool,
};

export default CoreDataOverview;
