import React from 'react';
import PropTypes from 'prop-types';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { Typography } from '@material-ui/core';
import { mobilityFormTexts as mft } from '../../../../texts/Forms/MobilityFormTexts';
import CircularProgress from '@material-ui/core/CircularProgress';

const MobilityListEmpty = (props) => {
    const { isFetching } = props;
    return (
        <TableRow role="row">
            <TableCell role="cell" colSpan={7} aria-colspan={7}>
                <Typography component="span" gutterBottom>
                    {mft.list.empty.text}
                    {isFetching ? (
                        <CircularProgress
                            style={{ marginLeft: 8 }}
                            size="1rem"
                        />
                    ) : null}
                </Typography>
            </TableCell>
        </TableRow>
    );
};

MobilityListEmpty.propTypes = {
    isFetching: PropTypes.bool,
};

export default MobilityListEmpty;
