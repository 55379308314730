import green from '@material-ui/core/colors/green';
import makeStyles from '@material-ui/core/styles/makeStyles';

export const useRegisterSuccessStyles = makeStyles((theme) => ({
    box: {
        textAlign: 'center',
        border: `${theme.spacing(1)}px solid ${green[500]}`,
        padding: theme.spacing(4),
        borderRadius: theme.spacing(2),
    },
    text: {
        marginTop: theme.spacing(2),
    },
    icon: {
        color: green[600],
        fontSize: '3.5rem',
    },
    success: {
        color: green[600],
        fontWeight: 'bold',
    },
}));
