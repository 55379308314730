import _ from 'lodash';

export function verifyRoles(givenRoles, neededRoles) {
    const intersection = _.intersection(givenRoles, neededRoles);
    return intersection.length > 0;
}

export function hasRole(givenRoles, role) {
    return givenRoles.indexOf((given) => given === role) >= 0;
}
