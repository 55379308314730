import _ from 'lodash';

export const teamFetchMutator = (user) =>
    _.omit(
        {
            ...user,
            firstName: user.firstname,
            lastName: user.lastname,
            email: user.username,
            isManager: user.roles
                ? user.roles.indexOf('ROLE_MANAGER') >= 0
                : false,
            fullName: `${user.title ? user.title + ' ' : ''}${user.firstname} ${
                user.lastname
            }`,
            street: user.address.street,
            postcode: user.address.postcode,
            city: user.address.city,
            state: user.address.state,
        },
        ['firstname', 'lastname', 'username', 'address']
    );

export const userCreateMutator = (user) =>
    _.omit(
        {
            ...user,
            firstname: user.firstName,
            lastname: user.lastName,
            username: user.email,
            address: {
                street: user.street,
                postcode: user.postcode,
                city: user.city,
                state: user.state,
            },
        },
        [
            'firstName',
            'lastName',
            'email',
            'isManager',
            'fullName',
            'id',
            'street',
            'postcode',
            'city',
            'state',
        ]
    );

export const userPutMutator = (user) =>
    _.omit(
        {
            ...user,
            firstname: user.firstName,
            lastname: user.lastName,
            username: user.email,
            address: {
                street: user.street,
                postcode: user.postcode,
                city: user.city,
                state: user.state,
            },
        },
        [
            'firstName',
            'lastName',
            'email',
            'isManager',
            'fullName',
            'street',
            'postcode',
            'city',
            'state',
        ]
    );
