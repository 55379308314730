import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const Imprint = () => {
    return (
        <Grid container spacing={10}>
            <Grid item xs={12}>
                <Typography variant="h5" component="h1" gutterBottom>
                    Impressum
                </Typography>
                <Typography variant="h6" component="h2" gutterBottom>
                    Herausgeber
                </Typography>
                <Typography variant="body1">
                    Bundesministerium für Bildung und Forschung
                </Typography>
                <Typography variant="body1">
                    Referat ERASMUS; Internationale Zusammenarbeit in der
                    Berufsbildung
                </Typography>
                <Typography variant="body1">
                    53170 Bonn / 11055 Berlin
                </Typography>
                <Typography variant="body1">
                    <a
                        href="https://www.bmbf.de"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        www.bmbf.de
                    </a>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Stand: April 2021
                </Typography>
                <Typography variant="body1">
                    Telefon: +49 (0)30 18 57-0
                </Typography>
                <Typography variant="body1">
                    Fax: +49 (0)30 18 57-5270
                </Typography>
                <Typography variant="body1">
                    E-Mail:{' '}
                    <a href="mailto:information@bmbf.bund.de">
                        information@bmbf.bund.de
                    </a>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    USt-IdNr. des BMBF: DE169838195
                </Typography>
                <Typography variant="h6" component="h2" gutterBottom>
                    Verantwortlich
                </Typography>
                <Typography variant="body1" gutterBottom>
                    AusbildungWeltweit ist ein Förderprogramm des
                    Bundesministeriums für Bildung und Forschung.
                </Typography>
                <Typography variant="body1">Es wird umgesetzt von</Typography>
                <Typography variant="body1">
                    Nationale Agentur Bildung für Europa beim Bundesinstitut für
                    Berufsbildung (NA beim BIBB)
                </Typography>
                <Typography variant="body1">
                    Friedrich-Ebert-Allee 114 – 116
                </Typography>
                <Typography variant="body1" gutterBottom>
                    53113 Bonn
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Verantwortlich gemäß § 5 TMG (Telemediengesetzes) sowie
                    gemäß § 55 RStV (Rundfunkstaatsvertrags) ist Klaus Fahle,
                    Leiter der Nationalen Agentur beim BIBB.
                </Typography>
                <Typography variant="body1">AusbildungWeltweit</Typography>
                <Typography variant="body1">
                    Telefon: +49 (0)228 107 1611
                </Typography>
                <Typography variant="body1">
                    E-Mail: ausbildung-weltweit(at)bibb(dot)de
                </Typography>
                <Typography variant="body1">
                    <a
                        href="https://www.ausbildung-weltweit.de"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        www.ausbildung-weltweit.de
                    </a>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <a
                        href="https://www.na-bibb.de"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        www.na-bibb.de
                    </a>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <strong>
                        Redaktion des AusbildungWeltweit Projektportals
                    </strong>
                </Typography>
                <Typography variant="body1">
                    B. Kazemkhani, B. Platzmann (NA beim BIBB, Team Finanzen und
                    IT)
                </Typography>
                <Typography variant="body1" gutterBottom>
                    S. Metzdorf, B. Platzmann, H. Saalfeld-Nitz (NA beim BIBB,
                    Team AusbildungWeltweit)
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <strong>
                        Technische Umsetzung des AusbildungWeltweit
                        Projektportals
                    </strong>
                </Typography>
                <Typography variant="body1">DLR-Projektträger</Typography>
                <Typography variant="body1" gutterBottom>
                    <a
                        href="https://www.dlr.de/pt"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        www.dlr.de/pt
                    </a>
                </Typography>
                <Typography variant="h6" component="h2" gutterBottom>
                    Rechtliche Hinweise
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Die auf diesen Seiten vorhandenen Links zu Inhalten von
                    Internet-Seiten Dritter ("fremden Inhalten") wurden durch
                    das Bundesministerium für Bildung und Forschung (BMBF) nach
                    bestem Wissen und unter Beachtung größtmöglicher Sorgfalt
                    erstellt und vermitteln lediglich den Zugang zu "fremden
                    Inhalten". Dabei wurde auf die Vertrauenswürdigkeit dritter
                    Anbieter sowie Rechtmäßigkeit der "fremden Inhalte"
                    besonders geachtet.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Da jedoch der Inhalt von Internetseiten dynamisch ist und
                    sich jederzeit ändern kann, ist eine stetige
                    Einzelfallprüfung sämtlicher Inhalte, auf die ein Link
                    erstellt wurde, nicht in jedem Fall möglich. Das BMBF hat
                    keinen Einfluss auf "fremde Inhalte" verlinkter Webseiten
                    Dritter und kann dafür keine Gewähr übernehmen. Für die
                    Inhalte der verlinkten Seiten ist ausschließlich der
                    jeweilige Anbieter oder Betreiber der Seiten verantwortlich.
                    Das BMBF macht sich daher "fremde Inhalte" ausdrücklich
                    nicht zu eigen. Für Schäden aus der Nutzung oder
                    Nichtnutzung "fremder Inhalte" haftet ausschließlich der
                    jeweilige Anbieter der Seite, auf die verwiesen wurde. Bei
                    Bekanntwerden von Rechtsverletzungen werden wir derartige
                    Links umgehend entfernen.
                </Typography>
                <Typography variant="h6" component="h3" gutterBottom>
                    Bildernachweis
                </Typography>
                <Typography variant="body1">BMBF</Typography>
            </Grid>
        </Grid>
    );
};

Imprint.propTypes = {};

export default Imprint;
