import React from 'react';
import PropTypes from 'prop-types';
import { setup } from '../../lib/setup/Setup';
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

const AppContainer = (props) => {
    const { children } = props;
    const classes = useStyles();
    const [isSettingUp, setIsSettingUp] = React.useState(true);

    React.useEffect(() => {
        setup()
            .catch(() => {})
            .finally(() => {
                setIsSettingUp(false);
            });
    }, []);

    if (isSettingUp)
        return (
            <Backdrop className={classes.backdrop} open={true}>
                <CircularProgress color="inherit" />
            </Backdrop>
        );

    return <div>{children}</div>;
};

AppContainer.propTypes = {
    children: PropTypes.node,
};

export default AppContainer;
