import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { texts } from '../../../texts/Forms/ContactPersonFormTexts';
import { useForm } from 'react-hook-form';
import RHFSelect from '../../Partials/Form/RHFSelect';
import Typography from '@material-ui/core/Typography';
import { useAuth } from '../../../stores/Auth.store';
import { useWizard } from '../../../stores/Wizard.store';
import { Alert } from '@material-ui/lab';

const ContactPersonForm = (props) => {
    const { defaultValues: team, error, saving, mutate } = props;
    const { control, setError, handleSubmit, register } = useForm({
        defaultValues: team,
    });
    const { applicationRound } = useWizard((state) => ({
        applicationRound: state.proposal.applicationRound,
    }));
    const user = useAuth((state) => state.user);
    const contact =
        team.find(
            (teamMember) =>
                teamMember.applicationRounds.indexOf(applicationRound) !== -1 &&
                teamMember.isActive
        ) ||
        team.find((teamMember) => teamMember.isManager && teamMember.isActive);

    useEffect(() => {
        if (error) {
            const violations = error.response?.data?.violations;
            if (violations) {
                violations.forEach((violation) => {
                    setError(violation.propertyPath, {
                        type: 'manual',
                        message: violation.message,
                    });
                });
            }
        }
    }, [error, setError]);

    const handleSubmitCallback = (values) => {
        if (!user.isManager) {
            return mutate({ skip: true });
        }
        // manager is default, so we just need to delete the current contact person
        if (values.user.id === user.id) {
            return mutate({ user: contact, applicationRound, remove: true });
        }
        return mutate(values);
    };

    const options = team
        .filter((person) => {
            return person.isActive;
        })
        .map((person) => ({
            value: person,
            label: person.fullName,
        }));

    const [dataUpdateRequired, setDataUpdateRequired] = React.useState(
        contact ? contact.dataUpdateRequired : false
    );
    const contactOnChange = (user) => {
        setDataUpdateRequired(user.dataUpdateRequired);
    };

    return (
        <form
            id="mainForm"
            onSubmit={handleSubmit(handleSubmitCallback)}
            noValidate
        >
            {!user.isManager && (
                <>
                    <Typography gutterBottom>
                        {texts.contactPersonInfo}
                    </Typography>

                    <Typography gutterBottom variant={'h6'} align={'center'}>
                        {user.fullName}
                    </Typography>
                    <input
                        type="hidden"
                        name="user"
                        value={user}
                        ref={register()}
                    />
                </>
            )}
            {user.isManager && (
                <>
                    {dataUpdateRequired && (
                        <Alert severity="warning">
                            {texts.dataUpdateRequired}
                        </Alert>
                    )}
                    <Typography gutterBottom>{texts.managerInfo}</Typography>
                    <RHFSelect
                        control={control}
                        label={texts.contactPerson}
                        name="user"
                        options={options}
                        disabled={saving}
                        defaultValue={contact}
                        onChangeCallback={contactOnChange}
                    />
                </>
            )}
            <input
                type="hidden"
                name="applicationRound"
                value={applicationRound}
                ref={register()}
            />
            <input type="hidden" name="type" value="1" ref={register()} />
        </form>
    );
};

ContactPersonForm.propTypes = {
    mutate: PropTypes.func,
    defaultValues: PropTypes.array,
    saving: PropTypes.bool,
    error: PropTypes.object,
};

ContactPersonForm.defaultProps = {
    defaultValues: [],
    error: null,
    saving: false,
};

export default ContactPersonForm;
