import { api } from '../AxiosConfig/Config';
import { handleError } from '../../../util/Error/HandleError';
import moment from 'moment';
import { fetchMutator } from './ApplicationRoundsMutators';

export class ApplicationRoundsError extends Error {
    constructor(message, error) {
        super(message);
        this.name = 'ApplicationRoundsError';
        this.error = error;
    }
}

export class ApplicationRoundsAddError extends Error {
    constructor(message, error) {
        super(message);
        this.name = 'ApplicationRoundsAddError';
        this.error = error;
    }
}

export function getApplicationRounds(shouldThrow = false) {
    return new Promise(async (resolve, reject) => {
        try {
            const { data } = await api().get('/application_rounds');
            resolve(fetchMutator(data['hydra:member']));
        } catch (e) {
            //TODO: Error handling
            if (shouldThrow) {
                reject(
                    new ApplicationRoundsError(
                        'Error while fetching application rounds',
                        e
                    )
                );
            }
            reject(
                handleError(e, {
                    generic: 'Some Generic Error',
                })
            );
        }
    });
}

export function getCurrentApplicationRound(applicationRounds) {
    return applicationRounds.find((round) => {
        return moment().isBetween(
            round.begin,
            moment(round.end).add(13, 'hours')
        );
    });
}

export function editApplicationRoundContactPerson(
    { user, applicationRound, remove, skip },
    shouldThrow = false
) {
    return new Promise(async (resolve, reject) => {
        try {
            if (Boolean(skip)) {
                resolve();
            }

            let applicationRoundPayload = user.applicationRounds;
            if (remove) {
                if (user.applicationRounds.indexOf(applicationRound) >= 0) {
                    applicationRoundPayload = user.applicationRounds.filter(
                        (round) => round !== applicationRound
                    );
                } /*else {
                    return resolve(user);
                }*/
            }
            if (!remove) {
                if (user.applicationRounds.indexOf(applicationRound) < 0) {
                    applicationRoundPayload = user.applicationRounds.concat([
                        applicationRound,
                    ]);
                } /*else {
                    return resolve(user);
                }*/
            }

            const { data } = await api().put(`/users/${user.id}`, {
                ...user,
                applicationRounds: applicationRoundPayload,
            });
            resolve(data);
        } catch (e) {
            //TODO: Error handling
            if (shouldThrow) {
                reject(
                    new ApplicationRoundsAddError(
                        `Error while adding application round ${applicationRound} to user ${user.id}`,
                        e
                    )
                );
            }
            reject(
                handleError(e, {
                    generic: 'Some Generic Error',
                })
            );
        }
    });
}
