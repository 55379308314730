import { validations } from '../../util/Validation/validations';
import { states } from '../../util/constants/States';
import { teamTexts } from '../../texts/components/Team/TeamTexts';

export const teamAddFormConfig = {
    salutation: {
        label: 'Anrede',
        name: 'salutation',
        validation: validations.salutation,
        options: [
            { label: 'Herr', value: 'm' },
            { label: 'Frau', value: 'f' },
            { label: 'ohne Anrede', value: 'd' },
        ],
        type: 'select',
    },
    title: {
        label: 'Titel (optional)',
        name: 'title',
    },
    firstName: {
        label: 'Vorname',
        name: 'firstName',
        validation: validations.firstName,
    },
    lastName: {
        label: 'Nachname',
        name: 'lastName',
        validation: validations.lastName,
    },
    func: {
        name: 'func',
        label: 'Funktion',
        validation: validations.func,
    },
    department: {
        name: 'department',
        label: 'Abteilung',
        validation: validations.department,
    },
    phone: {
        label: 'Telefon',
        name: 'phone',
        validation: validations.phone,
        helperText: teamTexts.phoneFormat,
    },
    email: {
        label: 'E-Mail',
        name: 'email',
        validation: validations.email,
    },
    street: {
        label: 'Straße und Hausnummer',
        name: 'street',
        validation: validations.street,
    },
    postcode: {
        label: 'PLZ',
        name: 'postcode',
        validation: validations.postCode,
    },
    city: {
        label: 'Ort',
        name: 'city',
        validation: validations.city,
    },
    state: {
        type: 'select',
        options: states,
        label: 'Bundesland',
        name: 'state',
        validation: validations.state,
    },
};
