import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import { useMobilityEditStyles } from '../../../styles/components/forms/MobilityForm/MobilityEditStyles';

const MobilityTextField = (props) => {
    const { error, readOnly, register, rules, helperText, ...rest } = props;
    const classes = useMobilityEditStyles();
    return (
        <TextField
            inputProps={{
                'aria-label': rest.label,
            }}
            className={classes.input}
            fullWidth
            error={Boolean(error)}
            InputProps={{
                readOnly,
            }}
            helperText={Boolean(error) ? error.message : helperText}
            inputRef={register(rules)}
            required={!!rules.required}
            {...rest}
        />
    );
};

MobilityTextField.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string,
    rules: PropTypes.object,
    error: PropTypes.object,
    register: PropTypes.func,
    readOnly: PropTypes.bool,
    disabled: PropTypes.bool,
    defaultValue: PropTypes.string,
    helperText: PropTypes.string,
};

MobilityTextField.defaultProps = {
    error: undefined,
    disabled: false,
    readOnly: false,
    rules: {},
    defaultValue: '',
    helperText: undefined,
};

export default MobilityTextField;
