import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { useLoginButtonStyles } from '../../../../styles/components/Layout/LoginButtonStyles';
import { texts } from '../../../../texts/GeneralTexts';

const LoginButton = (props) => {
    const classes = useLoginButtonStyles();
    const { isLoading, onClick } = props;
    return (
        <>
            <Button
                className={classes.root}
                onClick={onClick}
                disabled={isLoading}
            >
                {texts.login}
            </Button>
        </>
    );
};

LoginButton.propTypes = {
    onClick: PropTypes.func,
    isLoading: PropTypes.bool,
};

export default LoginButton;
