import { dineroFormat } from '../../util/Helper/Dinero';

export const fiscalYearFormTexts = {
    subHead: 'Übersicht beantragte Fördersumme (gesamt)',
    subHead2: 'Geplante Verwendung der Fördersumme nach Haushaltsjahren',
    costs: 'Zuschusskategorie',
    year1: 'Haushaltsjahr 1',
    year2: 'Haushaltsjahr 2',
    remainingCosts: 'aufzuteilender Betrag',
    travelAllowance: 'Fahrt',
    residenceAllowance: 'Aufenthalt',
    orgaAllowance: 'Organisationsmittel',
    prePostProcessing: 'Vor- und Nachbereitung',
    disabledRequirements:
        'Zusätzlicher Bedarf für Menschen mit Behinderungen (in EUR)',
    total: 'SUMME',
    totalDifferenceWarning: (diff) =>
        `Der Betrag ist ${dineroFormat(Math.abs(diff))} ${
            diff < 0 ? 'über' : 'unter'
        } der beantragten Fördersumme.`,
    type: 'Zuschusskategorie',
    requestedAllowance: 'beantragter Zuschuss',
    subTotal1:
        'Zwischensumme für Fahrt, Aufenthalt und ggf. zusätzlicher Bedarf',
    subTotal2: 'Zwischensumme für Organisation und Vor- und Nachbereitung',
    requestedTotal: 'Beantragte Fördersumme (gesamt)',
    explanation: [
        {
            type: 'p',
            text:
                'Geben Sie den Bedarf nach Haushaltsjahren erst an, wenn Sie alle Mobilitäten angelegt haben, ' +
                'für die Sie Förderung beantragen möchten. Schlüsseln Sie dann bitte auf, welchen Anteil der ' +
                'Fördersumme Sie im ersten Haushaltsjahr anfordern und ausgeben werden und welcher Anteil auf ' +
                'das darauffolgende Haushaltsjahr entfällt.',
        },
        {
            type: 'list',
            text:
                '[li]Die Zuschüsse für Fahrt, Aufenthalt und zusätzlichen Bedarf für Menschen mit Behinderung ' +
                'können nach einer Bewilligung zur alsbaldigen Verwendung im ihnen zugewiesenen Haushaltsjahr ' +
                'angefordert werden.[li]' +
                '[li]Die Zuschüsse für die Vor-/Nachbereitung und Organisationsmittel werden automatisch dem ' +
                '2. Haushaltsjahr zugeordnet und können mit dem Verwendungsnachweis zum Projektabschluss ' +
                'abgerechnet werden.[li]',
        },
    ],
};
