import makeStyles from '@material-ui/core/styles/makeStyles';

export const useUserBarStyles = makeStyles((theme) => ({
    wrapper: {
        padding: `${theme.spacing(2)}px 0`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    container: {
        backgroundColor: '#fff',
    },
    icon: {
        verticalAlign: 'text-top',
        marginRight: theme.spacing(1),
    },
}));
