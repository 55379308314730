export const registerFormTexts = {
    title: 'Registrieren',
    info:
        'Mit der Registrierung für das AusbildungWeltweit Projektportal legen Sie ein Profil für die Einrichtung an ' +
        'und übernehmen die Rolle eines „Zugangsmanagers“ mit umfassenden Bearbeitungsrechten. Für eine Einrichtung ' +
        'darf es nur ein Profil geben, daher wird der Registrierungsprozess in mehreren Schritten durchgeführt. Zuerst ' +
        'legen Sie Ihre Zugangsdaten fest, erhalten anschließend eine E-Mail mit einem Bestätigungslink und senden ' +
        'damit Ihre Registrierungsanfrage ab. Die NA beim BIBB schaltet Ihren Zugang frei, wenn für Ihre Einrichtung ' +
        'noch kein Profil existiert.',
    personalData: 'Daten zur Person',
    companyData: 'Daten zur Einrichtung',
    register: 'Registrieren',
    phoneFormat:
        'Format: Vorwahl und Rufnummer ohne Leerstellen und Sonderzeichen (z.B. 04567067345)',
    passwordRules: 'Passwortrichtlinie',
    additional: 'Zusätzlich:',
    success: {
        title: 'Registrierung erfolgreich!',
        body:
            'Der erste Schritt zur Registrierung war erfolgreich. Sie erhalten in Kürze eine Nachricht an Ihre E-Mail-Adresse mit einem Bestätigungslink. Mit einem Klick auf diesen Link schließen Sie Ihre Registrierungsanfrage ab.',
    },
    directive: {
        0: 'Das Passwort entspricht nicht der Richtlinie. Es muss mindestens 10 Zeichen lang sein und 3 der 4 zusätzlichen Vorgaben erfüllen.',
        1: 'Das Passwort entspricht nicht der Richtlinie. Es muss mindestens 10 Zeichen lang sein.',
        10: 'Das Passwort entspricht nicht der Richtlinie. Es müssen 3 der 4 zusätzlichen Vorgaben erfüllt werden.',
        11: 'Das Passwort entspricht der Richtlinie.',
        length: 'Passwortlänge 10 Zeichen',
        upperCase: 'Passwort enthält Großbuchstaben',
        lowerCase: 'Passwort enthält Kleinbuchstaben',
        specialCharacter: 'Passwort enthält Sonderzeichen',
        number: 'Passwort enthält Nummer',
    },
    validateDone: {
        confirmEmailEclipsed: 'E-Mail bestätigen...',
        confirmEmail: 'E-Mail bestätigen',
        confirmEmailInvalid: 'E-Mail nicht bestätigt',
        emailIsBeingConfirmed: 'Ihre E-Mail Adresse wird bestätigt...',
        emailHasBeenConfirmed: 'E-Mail bestätigt',
        emailHasBeenConfirmedText:
            'Mit der Bestätigung Ihrer E-Mail-Adresse wurde Ihre Registrierungsanfrage gesendet. Es wird geprüft, ob für Ihre Einrichtung bereits ein Profil besteht. Sobald Ihr Zugangskonto freigeschaltet ist, erhalten Sie eine E-Mail-Benachrichtigung. Die Freischaltung erfolgt manuell bei der NA beim BIBB und kann ein bis zwei Werktage dauern. Wir bitten um Verständnis.',
        tokenInvalid:
            'Das von Ihnen angegeben Token ist ungültig. Versuchen Sie es erneut oder kontaktieren Sie unseren Support.',
    },
    consent: {
        title: 'Einwilligungserklärung',
        text: [
            {
                type: 'p',
                text:
                    'Ich bin mit der Verarbeitung meiner personenbezogenen Daten einverstanden und nehme die Datenschutzerklärung [a|https://www.ausbildung-weltweit.de/de/datenschutzerklaerung.html]https://www.ausbildung-weltweit.de/de/datenschutzerklaerung.html[a] zur Kenntnis. Ich habe insbesondere von meinem Widerrufsrecht Kenntnis genommen. Mir ist bewusst, dass meine Registrierungsdaten nur dann vorzeitig gelöscht werden können, wenn ich noch keine vollständige Einreichung getätigt habe.',
            },
        ],
    },
};
