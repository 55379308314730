import React from 'react';
import WizardButtons from '../../components/WizardNew/WizardButtons';
import WizardForms from '../../components/WizardNew/WizardForms';
import WizardControlContainer from '../../container/WizardNew/WizardControlContainer';
import WizardContainer from '../../container/WizardNew/WizardContainer';

const WizardView = (props) => {
    //TODO: WizardContainer wrapping the components to get WizardState from Proposal
    return (
        <div>
            <WizardContainer>
                <WizardControlContainer>
                    <WizardForms />
                    <WizardButtons />
                </WizardControlContainer>
            </WizardContainer>
        </div>
    );
};

export default WizardView;
