export const UserState = {
    ACTIVE: 0,
    NOT_CONFIRMED: 1,
    MUST_CHANGE_PASSWORD: 2,
    NOT_APPROVED: 3,
    NOT_ACTIVE: 4,
};

export function getUserState(user) {
    if (!user.confirmed) return UserState.NOT_CONFIRMED;
    if (!user.approved) return UserState.NOT_APPROVED;
    if (user.passwordChange || user.passwordMustBeChanged)
        return UserState.MUST_CHANGE_PASSWORD;
    if (!user.isActive) return UserState.NOT_ACTIVE;

    return UserState.ACTIVE;
}
