import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import MobilityEditContainer from '../../../container/Forms/Mobility/MobilityEditContainer';
import MobilityListContainer from '../../../container/Forms/Mobility/MobilityListContainer';
import MobilityFormError from './MobilityFormError';
import { validateStringGreaterThan } from '../../../util/Validation/ValidateStringGreater';
import Alert from '@material-ui/lab/Alert';
import { mobilityFormTexts as mft } from '../../../texts/Forms/MobilityFormTexts';

const MobilityForm = (props) => {
    const { defaultValues, submitFunc, fetchFunc, proposalId } = props;
    const { handleSubmit, register, errors, clearErrors } = useForm({});

    const [open, setOpen] = React.useState(false);
    const [editState, setEditState] = React.useState({
        index: -1,
        variant: 'create',
        data: undefined,
    });

    const [length, setLength] = React.useState(0);

    const handleEditClick = (index, data) => {
        setEditState({ index, variant: 'edit', data });
        setOpen(true);
    };

    const handleCreateNew = () => {
        setEditState({
            index: -1,
            variant: 'create',
            data: {
                [mft.fields.reqPrePostProcessing.name]: '0',
                [mft.fields.reqOrgaAllowance.name]: '0',
                [mft.fields.reqTravelAllowance.name]: '0',
                [mft.fields.reqResidenceAllowance.name]: '0',
            },
        });
        setOpen(true);
    };

    const handleClose = () => {
        setEditState({ ...editState, index: -1, variant: 'create' });
        setOpen(false);
    };

    const handleChange = (length) => {
        clearErrors('mobilities');
        setLength(length);
    };

    if (!defaultValues.list) return <MobilityFormError fetchFunc={fetchFunc} />;

    return (
        <>
            <form id="mainForm" onSubmit={handleSubmit(submitFunc)} noValidate>
                {errors.mobilities && (
                    <Alert severity="error" style={{ marginBottom: 8 }}>
                        {errors.mobilities.message}
                    </Alert>
                )}
                <input
                    type="hidden"
                    value={length}
                    name="mobilities"
                    ref={register({
                        validate: (value) =>
                            validateStringGreaterThan(
                                value,
                                0,
                                'Bitte mindestens eine Mobilität eintragen'
                            ),
                    })}
                />
            </form>

            <MobilityListContainer
                proposalId={proposalId}
                onEdit={handleEditClick}
                onCreate={handleCreateNew}
                onChange={handleChange}
            />

            {open && (
                <MobilityEditContainer
                    open={open}
                    onClose={handleClose}
                    countryList={defaultValues.list}
                    allowance={defaultValues.allowance}
                    {...editState}
                />
            )}
        </>
    );
};

MobilityForm.propTypes = {
    defaultValues: PropTypes.object,
    submitFunc: PropTypes.func.isRequired,
    saving: PropTypes.bool,
    error: PropTypes.object,
    proposalId: PropTypes.string.isRequired,
};

export default MobilityForm;
