import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import MobilityDialogTitle from '../../../components/Forms/Mobility/MobilityDialog/MobilityDialogTitle';
import MobilityDialogActions from '../../../components/Forms/Mobility/MobilityDialog/MobilityDialogActions';
import { useForm } from 'react-hook-form';
import MobilityDialogContent from '../../../components/Forms/Mobility/MobilityDialog/MobilityDialogContent';
import { useMutation } from 'react-query';
import {
    createMobility,
    putMobility,
} from '../../../lib/api/Mobility/MobilityActions';
import {
    changeMobility,
    generateMobilitySaveText,
} from '../../../util/Forms/MobilityFunctions';
import { useSnackbar } from 'notistack';
import { queryCache } from '../../../QueryCache';
import { useParams } from 'react-router-dom';

const MobilityEditContainer = (props) => {
    const {
        variant,
        data,
        onClose,
        open,
        index,
        countryList,
        allowance,
    } = props;
    const { proposalId } = useParams();
    const { handleSubmit, ...form } = useForm({
        defaultValues: data,
        mode: 'onChange',
    });

    const [responseError, setResponseError] = React.useState(undefined);

    const { enqueueSnackbar } = useSnackbar();

    const handleClose = () => {
        onClose(false);
    };

    //Scroll to top of content when mounting
    const [contentRef, setContentRef] = React.useState(undefined);

    const scrollTop = () => {
        contentRef.scrollIntoView();
    };

    React.useEffect(() => {
        if (!contentRef) return;
        scrollTop();
    }, [contentRef]); //eslint-disable-line

    const setRef = (node) => {
        setContentRef(node);
    };

    const getMutateFn = () => {
        if (variant === 'create') return createMobility;
        return putMobility;
    };

    React.useEffect(() => {
        if (!responseError) return;
        scrollTop();
    }, [responseError]); //eslint-disable-line

    const [mutate, { isLoading }] = useMutation(getMutateFn(), {
        onMutate: () => {
            setResponseError(undefined);
        },
        onSuccess: (data) => {
            const message = generateMobilitySaveText(
                data.type,
                data.destination
            );
            enqueueSnackbar(message, { variant: 'success' });
            const oldData = queryCache.getQueryData(['mobilities', proposalId]);

            data.type === 'post'
                ? queryCache.setQueryData(
                      ['mobilities', proposalId],
                      [...oldData, data.newData]
                  )
                : queryCache.setQueryData(
                      ['mobilities', proposalId],
                      [...changeMobility(oldData, index, data.newData)]
                  );

            handleClose();
        },
        onError: (err) => {
            setResponseError(err);
        },
    });

    const submitCallback = (values) => {
        mutate({ values, proposalId });
    };

    return (
        <Dialog
            role="main"
            PaperProps={{
                'aria-label': 'Mobilitäten Dialog',
            }}
            fullWidth
            maxWidth="md"
            open={open}
        >
            <form
                onSubmit={(e) => {
                    form.clearErrors();
                    handleSubmit(submitCallback)(e);
                }}
                ref={setRef}
                noValidate
            >
                <MobilityDialogTitle
                    variant={variant}
                    onClose={handleClose}
                    isSaving={isLoading}
                />
                <MobilityDialogContent
                    form={form}
                    scrollTop={scrollTop}
                    data={data}
                    countryList={countryList}
                    allowance={allowance}
                    responseError={responseError}
                />
                <MobilityDialogActions
                    dismiss={handleClose}
                    isSaving={isLoading}
                />
            </form>
        </Dialog>
    );
};

MobilityEditContainer.propTypes = {
    variant: PropTypes.oneOf(['edit', 'create']),
    data: PropTypes.object,
    open: PropTypes.bool,
    onClose: PropTypes.func,
    index: PropTypes.number,
    countryList: PropTypes.array,
    allowance: PropTypes.object,
};

MobilityEditContainer.defaultProps = {
    data: {},
    open: false,
};

export default MobilityEditContainer;
