import axios from 'axios';
import { buildUrlClientAuth, generateCodes } from '../Auth/AuthorizeActions';
import crypto from 'crypto';

export function authenticateClient() {
    return new Promise((resolve, reject) => {
        const codes = generateCodes();
        const state = crypto
            .createHmac('sha256', codes.codeVerify)
            .update(codes.codeChallenge)
            .digest('hex');
        const url = buildUrlClientAuth(codes.codeChallenge, state);

        axios
            .get(url, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            })
            .then((response) => {
                const responseUrl = response.request.responseURL || '';

                if (
                    responseUrl === '' ||
                    responseUrl === null ||
                    responseUrl === undefined
                ) {
                    reject({});
                }

                //Call Token with Code
                const search = responseUrl.split('?');
                const searchParam = new URLSearchParams(search[1]);
                const code = searchParam.get('code');
                const newState = searchParam.get('state');

                if (state !== newState) {
                    reject({});
                }

                const formData = new FormData();
                formData.set('grant_type', 'authorization_code');
                formData.set('client_id', process.env.REACT_APP_CLIENT_ID);
                formData.set('code', code);
                formData.set('code_verifier', codes.codeVerify);
                formData.set('redirect_uri', `${process.env.REACT_APP_HOST}`);

                axios
                    .post(
                        `${process.env.REACT_APP_HOST}/oauth/token`,
                        formData,
                        {
                            headers: {
                                'Conten-Type': 'multipart/form-data',
                            },
                        }
                    )
                    .then((response) => {
                        resolve(response.data.access_token);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            })
            .catch((error) => {
                reject(error);
            });
    });
}
