import { persistMobilityData } from '../lib/api/Mobility/MobilityActions';
import { saveProjectData } from '../lib/api/Project/ProjectActions';
import { persistFiscalYearData } from '../lib/api/FiscalYears/FiscalYearActions';
import { persistBudgetPlanData } from '../lib/api/BudgetPlan/BudgetPlanActions';
import { editApplicationRoundContactPerson } from '../lib/api/ApplicationRounds/ApplicationsRoundsActions';
import { persistExplanationData } from '../lib/api/Explanations/ExplanationsActions';
import { persistFinalizationData } from '../lib/api/Finalization/FinalizationActions';
import ExplanationsFormContainer from '../container/Forms/Explanations/ExplanationsFormContainer';
import ProjectFormContainer from '../container/Forms/Project/ProjectFormContainer';
import MobilityFormContainer from '../container/Forms/Mobility/MobilityFormContainer';
import ContactPersonFormContainer from '../container/Forms/ContactPerson/ContactPersonFormContainer';
import BudgetPlanFormContainer from '../container/Forms/BudgetPlan/BudgetPlanFormContainer';
import CoreDataFormContainer from '../container/Forms/CoreData/CoreDataFormContainer';
import FiscalYearFormContainer from '../container/Forms/FiscalYear/FiscalYearFormContainer';
import FinalizationFormContainer from '../container/Forms/Finalization/FinalizationFormContainer';
import { finalizationFormTexts } from '../texts/Forms/FinalizationFormTexts';

export const steps = [
    {
        name: 'Stammdaten',
        component: CoreDataFormContainer,
        saveFunc: () => {},
        buttons: {
            save: {
                text: 'Speichern',
                disabled: true,
                display: false,
            },
            link: {
                text: 'Stammdaten Bearbeiten',
                href: '/stammdaten/edit',
            },
            continue: {
                text: 'Weiter',
                disabled: false,
                display: true,
            },
        },
    },
    {
        name: 'Kontaktperson',
        component: ContactPersonFormContainer,
        saveFunc: editApplicationRoundContactPerson,
        buttons: {
            save: {
                text: 'Speichern',
                disabled: false,
                display: true,
            },
            continue: {
                text: 'Speichern & Weiter',
                disabled: false,
                display: true,
            },
        },
    },
    {
        name: 'Projekt',
        component: ProjectFormContainer,
        saveFunc: saveProjectData,
        buttons: {
            save: {
                text: 'Speichern',
                disabled: false,
                display: true,
            },
            continue: {
                text: 'Speichern & Weiter',
                disabled: false,
                display: true,
            },
        },
    },
    {
        name: 'Mobilität hinzufügen',
        component: MobilityFormContainer,
        saveFunc: persistMobilityData,
        buttons: {
            save: {
                text: 'Speichern',
                disabled: false,
                display: true,
            },
            continue: {
                text: 'Speichern & Weiter',
                disabled: false,
                display: true,
            },
        },
    },
    {
        name: ' Beantragte Förderung und Haushaltsjahre',
        component: FiscalYearFormContainer,
        saveFunc: persistFiscalYearData,
        buttons: {
            save: {
                text: 'Speichern',
                disabled: false,
                display: true,
            },
            continue: {
                text: 'Speichern & Weiter',
                disabled: false,
                display: true,
            },
        },
    },
    {
        name: 'Geplante Gesamtausgaben',
        component: BudgetPlanFormContainer,
        saveFunc: persistBudgetPlanData,
        buttons: {
            save: {
                text: 'Speichern',
                disabled: false,
                display: true,
            },
            continue: {
                text: 'Speichern & Weiter',
                disabled: false,
                display: true,
            },
        },
    },
    {
        name: 'Erklärungen',
        component: ExplanationsFormContainer,
        saveFunc: persistExplanationData,
        buttons: {
            save: {
                text: 'Speichern',
                disabled: false,
                display: true,
            },
            continue: {
                text: 'Speichern & Weiter',
                disabled: false,
                display: true,
            },
        },
    },
    {
        name: 'Abschluss',
        component: FinalizationFormContainer,
        saveFunc: persistFinalizationData,
        buttons: {
            save: {
                text: 'Speichern',
                disabled: true,
                display: false,
            },
            continue: {
                text: 'Antrag elektronisch übermitteln',
                disabled: false,
                display: true,
                confirm: {
                    title: finalizationFormTexts.confirm.title,
                    body: finalizationFormTexts.confirm.body,
                    info: finalizationFormTexts.confirm.info,
                    cancel: finalizationFormTexts.confirm.cancel,
                    accept: finalizationFormTexts.confirm.accept,
                },
            },
        },
    },
];
