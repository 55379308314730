import React from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import BusinessIcon from '@material-ui/icons/Business';
import { coreDataOverviewTexts as cdot } from '../../../../texts/components/CoreData/CoreDataOverviewTexts';

const DisplayCompany = (props) => {
    const { company } = props;
    const address = company.address;

    return (
        <Card style={{ height: '100%' }}>
            <CardContent>
                <Typography variant="h6" component="h2">
                    <BusinessIcon
                        style={{ verticalAlign: 'sub', marginRight: 4 }}
                    />
                    {cdot.company}
                </Typography>
                <Typography>
                    <b>Name der Organisation: </b>
                    {company.name ?? 'Nicht angegeben'}
                </Typography>
                <Typography>
                    <b>Rechtsform: </b>
                    {company.legalName ?? 'Nicht angegeben'}
                </Typography>
                <Typography>
                    <b>Berechtigt zum Vorsteuerabzug: </b>
                    {company.ustAbzug
                        ? cdot.ustAbzugText[company.ustAbzug]
                        : 'Nicht angegeben'}
                </Typography>
                <Typography>
                    <b>Adresse:</b>
                </Typography>
                <Typography style={{ textIndent: '1em' }}>
                    {address.street}
                </Typography>
                <Typography style={{ textIndent: '1em' }}>
                    {address.postcode} {address.city}
                </Typography>
                <Typography style={{ textIndent: '1em' }}>
                    {address.state}
                </Typography>
                <Typography>
                    <b>Anzahl Beschäftigte: </b>
                    {company.employees ?? 'Nicht angegeben'}
                </Typography>
                <Typography>
                    <b>Anzahl der Auszubildenden: </b>
                    {company.apprentices ?? 'Nicht angegeben'}
                </Typography>
            </CardContent>
        </Card>
    );
};

DisplayCompany.propTypes = {
    company: PropTypes.object,
};

export default DisplayCompany;
