import React from 'react';
import PropTypes from 'prop-types';
import { mobilityFormTexts as mft } from '../../../../texts/Forms/MobilityFormTexts';
import MobilityDropdown from '../MobilityDropdown';
import MobilityTextField from '../MobilityTextField';
import MobilityRadioButtons from '../MobilityRadioButtons';
import Collapse from '@material-ui/core/Collapse';
import MobilityDurationPicker from '../MobilityDurationPicker';
import { useWatch } from 'react-hook-form';
import { validateDuration } from '../../../../util/Validation/ValidateMobilityDuration';
import _ from 'lodash';
import AutocompleteSync from '../../../Partials/Form/AutocompleteSync';
import MobilityFields from './MobilityFields';
import { STAY_TYPES } from '../../../../util/constants/States';

const manualScroll = [
    mft.fields.type.name,
    mft.fields.destination.name,
    mft.fields.area.name,
    mft.fields.duration.name,
];

const MobilityEdit = (props) => {
    const {
        data,
        form,
        scrollTop,
        countryList,
        allowance,
        responseErrors,
    } = props;
    const {
        register,
        control,
        errors,
        setError,
        setValue,
        trigger,
        getValues,
    } = form;

    const type = useWatch({
        control,
        name: mft.fields.type.name,
        defaultValue: data[mft.fields.type.name],
    });

    React.useEffect(() => {
        const res = _.intersection(Object.keys(errors), manualScroll);
        if (res.length > 0) {
            document.getElementById(res[0]).focus();
            scrollTop();
        }
    }, [errors]); //eslint-disable-line

    React.useEffect(() => {
        if (type) {
            trigger(['start', 'end']);
        }
    }, [type, trigger]);

    React.useEffect(() => {
        if (!responseErrors) return;
        const { violations } = responseErrors;

        violations.forEach((violation) => {
            setError(violation.id, {
                type: 'manual',
                message: violation.message,
            });
        });
    }, [responseErrors, setError]);

    return (
        <div>
            <input
                type="hidden"
                name="@id"
                ref={register()}
                defaultValue={data['@id']}
            />

            <MobilityDropdown
                control={control}
                {...mft.fields.type}
                defaultValue={data[mft.fields.type.name]}
                error={errors[mft.fields.type.name]}
            />

            <AutocompleteSync
                control={control}
                data={countryList}
                {...mft.fields.destination}
                defaultValue={data[mft.fields.destination.name]}
                error={errors[mft.fields.destination.name]}
            />

            <MobilityTextField
                register={register}
                defaultValue={data[mft.fields.attendees.name]}
                error={errors[mft.fields.attendees.name]}
                {...mft.fields.attendees}
            />
            <MobilityDropdown
                control={control}
                defaultValue={data[mft.fields.area.name]}
                error={errors[mft.fields.area.name]}
                {...mft.fields.area}
            />

            <Collapse in={type === STAY_TYPES.apprentice} unmountOnExit>
                <MobilityRadioButtons
                    defaultValue={data[mft.fields.trainingType.name]}
                    error={errors[mft.fields.trainingType.name]}
                    register={register}
                    {...mft.fields.trainingType}
                />
            </Collapse>

            <MobilityDurationPicker
                errors={[
                    errors[mft.fields.start.name],
                    errors[mft.fields.end.name],
                ]}
                name={[mft.fields.start.name, mft.fields.end.name]}
                control={control}
                label={[mft.fields.start.label, mft.fields.end.label]}
                setValue={setValue}
                triggerValidation={trigger}
                rules={{
                    validate: (newValue, fieldName) => {
                        const values = {
                            start: getValues('start'),
                            end: getValues('end'),
                        };
                        values[fieldName] = newValue;
                        return validateDuration(values, type);
                    },
                }}
                required={true}
            />

            <MobilityTextField
                register={register}
                defaultValue={data[mft.fields.partner.name]}
                error={errors[mft.fields.partner.name]}
                {...mft.fields.partner}
            />

            <MobilityTextField
                register={register}
                defaultValue={data[mft.fields.region.name]}
                error={errors[mft.fields.region.name]}
                {...mft.fields.region}
            />

            <MobilityTextField
                register={register}
                defaultValue={data[mft.fields.city.name]}
                error={errors[mft.fields.city.name]}
                {...mft.fields.city}
            />
            <MobilityFields
                control={control}
                data={data}
                orgaAmount={allowance.orga}
                processingAmount={allowance.processing}
                errors={errors}
            />
        </div>
    );
};

MobilityEdit.propTypes = {
    data: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    scrollTop: PropTypes.func,
    countryList: PropTypes.array,
    allowance: PropTypes.object,
    responseErrors: PropTypes.object,
};

MobilityEdit.defaultProps = {
    data: {},
    countryList: [],
    allowance: {},
    responseErrors: undefined,
};

export default MobilityEdit;
