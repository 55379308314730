import { Response } from '../../../util/DataTypes/Response';
import { fetchMutator, persistMutator } from './CoreDataMutators';
import { useAuth } from '../../../stores/Auth.store';
import { api, baseJson } from '../AxiosConfig/Config';
import { queryCache } from '../../../QueryCache';
import {
    buildResponseError,
    handleError,
} from '../../../util/Error/HandleError';

export function getCoreData() {
    return new Promise(async (resolve, reject) => {
        try {
            const { getState } = useAuth;
            const companyId = getState().user?.company?.id;

            if (!companyId) {
                reject(
                    Response.Error(
                        'Die Einrichtungs-Id konnte nicht gefunden werden.'
                    )
                );
                return;
            }
            const { data } = await api().get(`/companies/${companyId}`);
            let legal = undefined;
            if (data.legal) {
                legal = await baseJson().get(`${data.legal}`);
            }
            resolve(fetchMutator(data, legal));
        } catch (e) {
            reject(
                Response.Error(
                    'Die Einrichtungsdaten konnten nicht geladen werden'
                )
            );
        }
    });
}

export function saveCoreData(values) {
    return new Promise(async (resolve, reject) => {
        try {
            if (values.skip === 'true') {
                resolve(Response.Success('Stammdaten wurden übernommen.'));
                return;
            }
            const { getState, setState } = useAuth;
            const companyId = getState().user?.company?.id;

            if (!companyId)
                reject(
                    Response.Error(
                        'Die Einrichtungs-Id konnte nicht gefunden werden.'
                    )
                );

            const mutated = persistMutator(values);

            const result = await api().patch(
                `/companies/${companyId}`,
                mutated,
                { headers: { 'content-type': 'application/merge-patch+json' } }
            );

            const { data } = result;

            //Fix useAuth Company Name
            //Fix react-query caching of company
            setState({
                user: {
                    ...getState().user,
                    company: { ...data },
                },
            });

            const { data: legal } = await baseJson().get(`${data.legal}`);
            queryCache.setQueryData(['coreData'], {
                ...fetchMutator(data, legal),
            });
            resolve(Response.Success('Speichern der Stammdaten erfolgreich.'));
        } catch (e) {
            reject(
                buildResponseError(e, {
                    generic: 'Stammdaten konnten nicht gespeichert werden',
                })
            );
        }
    });
}

export function deleteViceSignaturePerson(data) {
    return new Promise(async (resolve, reject) => {
        try {
            const viceSignaturePersonId = data.id;

            if (!viceSignaturePersonId)
                reject(
                    Response.Error('Die Person konnte nicht gefunden werden.')
                );

            await api().delete(
                `/vice_signature_people/${viceSignaturePersonId}`
            );

            resolve(
                Response.Success(
                    'Löschen der Vertretung der zeichnungsberechtigten Person erfolgreich.'
                )
            );
        } catch (e) {
            reject(
                handleError(e, {
                    generic: 'Es ist ein Fehler beim Löschen aufgerteten.',
                })
            );
        }
    });
}
