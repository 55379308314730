export const texts = {
    contactPerson: 'Kontaktperson',
    dataUpdateRequired:
        'Die persönlichen Daten der ausgewählten Kontaktperson sind unvollständig. Bitte vervollständigen Sie diese, indem Sie im Menü auf "Team" klicken und dann die entsprechende Person bearbeiten.',
    contactPersonInfo:
        'Sie wurden als Kontaktperson für diesen Antrag festgelegt. Bitte setzen Sie sich mit ihrem Zugangsmanager in Verbindung, falls dies nicht korrekt ist. Bitte klicken Sie auf "Speichern und weiter", um den Antrag weiter auszufüllen.',
    managerInfo:
        'Bitte ordnen Sie diesem Antrag eine Kontaktperson zu. Die Kontaktperson wird im Antrag aufgeführt und ist ' +
        'für die NA beim BIBB die Ansprechperson zu allen Belangen der Umsetzung. Eine neue Kontaktperson kann im ' +
        'Bereich "Team" angelegt werden. Sie kann diesen Antrag bearbeiten und übermitteln. Bitte stellen Sie sicher, ' +
        'dass die Angaben für die zugeordnete Person unter Team vollständig und aktuell sind.',
};
