import { Response } from '../../../util/DataTypes/Response';
import { api } from '../AxiosConfig/Config';

export function fetchBudgetPlanData(proposalId) {
    return function () {
        return new Promise(async (resolve, reject) => {
            try {
                const { data } = await api().get(
                    `/projects/${proposalId}/budget_plan`
                );
                resolve(data);
            } catch (e) {
                reject(
                    Response.Error('Budgetplan konnte nicht geladen werden', e)
                );
            }
        });
    };
}

export function persistBudgetPlanData({ proposalId, data }) {
    return new Promise(async (resolve, reject) => {
        try {
            await api().patch(`/projects/${proposalId}/budget_plan`, data, {
                headers: { 'content-type': 'application/merge-patch+json' },
            });
            resolve(Response.Success('Budgetplan erfolgreich gespeichert'));
        } catch (e) {
            reject(
                Response.Error('Budgetplan konnte nicht gespeichert werden', e)
            );
        }
    });
}
