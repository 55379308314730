/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import { useMutation } from 'react-query';
import { createNewProposal } from '../../lib/api/Project/ProjectActions';
import { CREATE_STATE } from '../../util/Overview/OverviewFunctions';
import { useApplicationRounds } from '../../stores/ApplicationRounds.store';
import { useAuth } from '../../stores/Auth.store';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';

const ProposalOverviewCreateContainer = (props) => {
    const { render, state } = props;
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();

    const [create, { isLoading, error }] = useMutation(
        (companyId) => createNewProposal(companyId),
        {
            onSuccess: (proposal) => {
                history.push(`/antrag/bearbeiten/${proposal.id}`);
            },
            onError: (e) => {
                enqueueSnackbar(e.message(), { variant: 'error' });
            },
        }
    );

    const user = useAuth((state) => state.user);

    const { currentApplicationRound } = useApplicationRounds((state) => ({
        currentApplicationRound: state.currentApplicationRound,
    }));
    const userCanCreateProposal =
        !!user &&
        (user.isManager ||
            user.applicationRounds.find((round) =>
                round === currentApplicationRound
                    ? currentApplicationRound['@id']
                    : null
            ));
    const isCurrentApplicationRound = !!currentApplicationRound;

    return render({
        create,
        isLoading,
        error,
        state,
        userCanCreateProposal,
        isCurrentApplicationRound,
    });
};

ProposalOverviewCreateContainer.propTypes = {
    render: PropTypes.func.isRequired,
    state: PropTypes.number,
};

ProposalOverviewCreateContainer.defaultProps = {
    state: CREATE_STATE.OPEN,
};

export default ProposalOverviewCreateContainer;
/* eslint-enable no-unused-vars */
