import React from 'react';
import PropTypes from 'prop-types';
import { useMutation } from 'react-query';
import { useWizard } from '../../stores/Wizard.store';
import { useSnackbar } from 'notistack';
import { wizardContainerTexts } from '../../texts/container/WizardContainerTexts';
import scrollTop from '../../util/Snippets/ScrollTop';

const FormContainer = (props) => {
    const { component, saveFunc, proposalId } = props;

    React.useEffect(() => {
        scrollTop();
    }, []);

    const { enqueueSnackbar } = useSnackbar();

    const {
        shouldContinue,
        setShouldContinue,
        forward,
        setFinished,
        setSaving,
    } = useWizard((state) => ({
        shouldContinue: state.shouldContinue,
        setShouldContinue: state.setShouldContinue,
        forward: state.forward,
        setFinished: state.setFinished,
        setSaving: state.setSaving,
    }));

    const [save, { error: saveError, isLoading: isSaving }] = useMutation(
        saveFunc,
        {
            onMutate: () => {
                setSaving(true);
            },
            onSuccess: () => {
                if (shouldContinue) {
                    forward()
                        .then(() => {})
                        .catch((err) => {
                            enqueueSnackbar(err.message(), {
                                variant: 'error',
                            });
                        });
                    setShouldContinue(false);
                    return;
                }
                setFinished(true);
            },
            onSettled: (res, err) => {
                setSaving(false);
                if (res) {
                    enqueueSnackbar(wizardContainerTexts.saveSuccess, {
                        variant: 'success',
                    });
                }
                if (err) {
                    enqueueSnackbar(wizardContainerTexts.saveError, {
                        variant: 'error',
                    });
                }
            },
        }
    );

    return <div>{component({ save, saveError, isSaving, proposalId })}</div>;
};

FormContainer.propTypes = {
    component: PropTypes.func.isRequired,
    saveFunc: PropTypes.func.isRequired,
    unmount: PropTypes.bool,
};

export default FormContainer;
