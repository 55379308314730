import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AnonNavlist from './AnonNavlist';
import AuthNavList from './AuthNavList';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.primary.main,
        padding: '1rem 0',
    },
}));

const Navbar = ({ isAuthenticated }) => {
    const classes = useStyles();

    return (
        <nav className={classes.root}>
            {isAuthenticated ? <AuthNavList /> : <AnonNavlist />}
        </nav>
    );
};

export default Navbar;
