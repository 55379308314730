import axios from 'axios';
import RefreshInterceptor from './RefreshInterceptor';

const authApi = axios.create({
    baseURL: `${process.env.REACT_APP_HOST}/oauth`,
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json',
    },
});

const registerApi = axios.create({
    baseURL: `${process.env.REACT_APP_HOST}/api`,
    headers: {
        'Content-Type': 'application/json',
    },
});

const api = () => {
    const ac = axios.create({
        baseURL: `${process.env.REACT_APP_HOST}/api`,
        headers: {
            'Content-Type': 'application/json',
        },
        withCredentials: true,
    });

    RefreshInterceptor(ac);

    return ac;
};

const baseJson = () => {
    const ac = axios.create({
        baseURL: `${process.env.REACT_APP_HOST}`,
        headers: {
            'Content-Type': 'application/json',
        },
        withCredentials: true,
    });

    RefreshInterceptor(ac);

    return ac;
};
export { authApi, registerApi, api, baseJson };
