import React from 'react';
import PropTypes from 'prop-types';
import { useMutation } from 'react-query';
import { registerNewUser } from '../../lib/api/Register/RegisterActions';

const RegisterFormContainer = (props) => {
    const { render } = props;

    const [register, { isLoading, error }] = useMutation(registerNewUser, {
        throwOnError: true,
    });

    return <div>{render({ register, isLoading, error })}</div>;
};

RegisterFormContainer.propTypes = {
    render: PropTypes.func.isRequired,
};
export default RegisterFormContainer;
