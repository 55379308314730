import makeStyles from '@material-ui/core/styles/makeStyles';

export const useMobilityEditStyles = makeStyles((theme) => ({
    input: {
        marginTop: theme.spacing(1),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    sumTextfield: {
        marginTop: theme.spacing(1),
    },
}));
