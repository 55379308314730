import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { useWizard } from '../../stores/Wizard.store';
import { isStepComplete } from '../../lib/api/Wizard/WizardActions';
import { useSnackbar } from 'notistack';
import { wizardContainerTexts } from '../../texts/container/WizardContainerTexts';
import { steps } from '../../config/WizardSteps';
import _ from 'lodash';
import { fetchProjectData } from '../../lib/api/Project/ProjectActions';

const WizardContainer = (props) => {
    const { children } = props;

    const { proposalId } = useParams();

    const { enqueueSnackbar } = useSnackbar();

    const {
        init,
        currentStep,
        setFinished,
        setButtons,
        setMaxSteps,
    } = useWizard((state) => ({
        init: state.init,
        setFinished: state.setFinished,
        currentStep: state.currentStep,
        setMaxSteps: state.setMaxSteps,
        setButtons: state.setButtons,
    }));

    const { isLoading, isFetching, error } = useQuery(
        ['proposal', proposalId],
        () => fetchProjectData(proposalId),
        {
            refetchOnMount: true,
            refetchOnWindowFocus: false,
            onSuccess: (data) => {
                init(data);
            },
        }
    );

    React.useEffect(() => {
        setMaxSteps(steps);
        setButtons(_.map(steps, 'buttons'));
    }, [setButtons, setMaxSteps]);

    React.useEffect(() => {
        setFinished(false);
        if (isLoading) return;
        isStepComplete(currentStep, proposalId)
            .then((result) => {
                if (result) {
                    setFinished(result);
                }
            })
            .catch((err) => {
                enqueueSnackbar(err.message(), { variant: 'warning' });
            });
    }, [currentStep, proposalId, isLoading]); //eslint-disable-line

    //TODO: Loading Page
    if (isLoading || isFetching) return <p>{wizardContainerTexts.loading}</p>;

    //TODO: Error Page
    if (error) return <p>{wizardContainerTexts.error}</p>;

    //TODO: Refetch and isFetching
    return (
        <>
            {React.Children.map(children, (child) => {
                return React.createElement(child.type, {
                    ...child.props,
                    proposalId: proposalId,
                });
            })}
        </>
    );
};

WizardContainer.propTypes = {
    children: PropTypes.node,
};

export default WizardContainer;
