import React from 'react';
import { Link } from 'react-router-dom';
const NotFound = () => {
    return (
        <React.Fragment>
            <div>404</div>
            <div>nochmals404</div>
            <Link to={'/anmelden'}>Anmelden</Link>
        </React.Fragment>
    );
};

export default NotFound;
