/**
 * Generate array from String with given {%...%} elements
 *  Ex: Klicken Sie {%hier%} um weiter zu machen => ['Klicken Sie ', '{%hier%}', ' um weiter zu machen']
 */
export function stringToUri(string) {
    const regex = /{%.*?%}/g;
    const matchedString = string.match(regex);

    let parts = [];
    let stringWIP = string;

    if (matchedString) {
        matchedString.forEach((el) => {
            const firstPart = stringWIP.slice(0, stringWIP.indexOf(el));
            parts.push(firstPart);
            parts.push(el);
            stringWIP = stringWIP.slice(firstPart.length + el.length);
        });
    }
    parts.push(stringWIP);

    return parts;
}
