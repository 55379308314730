import React from 'react';
import Paragraph from './Paragraph';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { parseToJsx } from './parseToJsx';
import uniqid from 'uniqid';

const TextBlock = (props) => {
    const {
        textBlock,
        paragraphProps,
        paragraphRootComponent,
        listProps,
        listRootComponent,
        parserOptions,
    } = props;

    const paragraphs = [];

    textBlock.forEach((paragraph) => {
        const fragments = parseToJsx(paragraph, parserOptions);
        paragraphs.push(fragments);
    });

    return (
        <>
            {paragraphs.map((fragment, idx) => {
                switch (textBlock[idx].type) {
                    case 'list':
                        return (
                            <Paragraph
                                as={listRootComponent}
                                {...listProps}
                                key={uniqid()}
                                paragraph={fragment}
                            />
                        );
                    case 'block':
                        return (
                            <Typography
                                key={uniqid()}
                                variant={textBlock[idx].variant}
                                component="p"
                            >
                                {fragment}
                            </Typography>
                        );
                    case 'p':
                        return (
                            <Typography
                                key={uniqid()}
                                variant={textBlock[idx].variant}
                                component="p"
                                style={{ marginBottom: '1.4rem' }}
                            >
                                {fragment}
                            </Typography>
                        );
                    case 'checkBoxLabel':
                        return (
                            <Typography
                                key={uniqid()}
                                variant={textBlock[idx].variant}
                                component="p"
                            >
                                {fragment}
                            </Typography>
                        );
                    default:
                        return (
                            <Paragraph
                                as={paragraphRootComponent}
                                key={uniqid()}
                                {...paragraphProps}
                                paragraph={fragment}
                            />
                        );
                }
            })}
        </>
    );
};

TextBlock.propTypes = {
    textBlock: PropTypes.array.isRequired,
    paragraphRootComponent: PropTypes.elementType,
    paragraphProps: PropTypes.object,
    listRootComponent: PropTypes.elementType,
    listProps: PropTypes.object,
    parserOptions: PropTypes.object,
};

TextBlock.defaultProps = {
    paragraphRootComponent: Typography,
    listRootComponent: Typography,
    paragraphProps: { variant: 'body1', component: 'p' },
    listProps: { component: 'ul' },
};

export default React.memo(TextBlock);
