import React from 'react';
import RHFTextField from './RHFTextField';
import _ from 'lodash';
import PropTypes from 'prop-types';

const GenericTextField = (props) => {
    const { config, control, errors, ...rest } = props;

    return (
        <RHFTextField
            label={config.label}
            control={control}
            name={config.name}
            error={_.get(errors, config.name)}
            validation={config.validation}
            {...rest}
        />
    );
};

GenericTextField.propTypes = {
    control: PropTypes.object.isRequired,
    config: PropTypes.object.isRequired,
    errors: PropTypes.object,
};

export default GenericTextField;
