import { registerMutator } from './RegisterMutator';
import { authenticateClient } from '../Client/ClientAuth';
import { registerApi } from '../AxiosConfig/Config';
import { parseError } from './RegisterErrorParse';

//TODO: Error messages
export function registerNewUser(values) {
    return new Promise(async (resolve, reject) => {
        try {
            const token = await authenticateClient();
            await registerApi.post(
                '/action/account-register',
                registerMutator(values),
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
            resolve(values);
        } catch (e) {
            reject(parseError(e));
        }
    });
}

export function validateAccount(confirmToken) {
    return new Promise((resolve, reject) => {
        authenticateClient()
            .then((token) => {
                registerApi
                    .post(
                        '/action/account-confirm',
                        { token: confirmToken },
                        {
                            headers: {
                                Authorization: `Bearer ${token}`,
                                'Content-Type': 'application/json',
                            },
                        }
                    )
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            })
            .catch((error) => {
                reject(error);
            });
    });
}
