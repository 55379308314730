import { validations } from '../../util/Validation/validations';
import { states } from '../../util/constants/States';
import { getLegalList } from '../../lib/api/Legal/LegalActions';

export const coreDataFormConfig = {
    companyId: {
        name: 'id',
    },
    name: {
        name: 'name',
        label: 'Rechtsverbindlicher Name der Organisation',
        validation: validations.companyName,
    },
    street: {
        name: 'address.street',
        label: 'Straße und Hausnummer',
        validation: validations.street,
    },
    postcode: {
        name: 'address.postcode',
        label: 'Postleitzahl',
        validation: validations.postCode,
    },
    city: {
        name: 'address.city',
        label: 'Ort',
        validation: validations.city,
    },
    state: {
        name: 'address.state',
        label: 'Bundesland',
        validation: validations.state,
        options: states,
        type: 'select',
    },
    homepage: {
        name: 'url',
        label: 'Homepage (optional)',
        validation: validations.homepage,
    },
    employees: {
        name: 'employees',
        label: 'Anzahl Beschäftigte',
        validation: validations.numberOfEmployees,
    },
    apprentices: {
        name: 'apprentices',
        label: 'Anzahl der Auszubildenden',
        validation: validations.numberOfApprentices,
    },
    legalForm: {
        name: 'legal',
        label: 'Rechtsform',
        type: 'select',
        options: getLegalList,
        validation: validations.legalForm,
    },
    ustAbzug: {
        name: 'ustAbzug',
        groupLabel:
            'Hinsichtlich Lieferungen und Leistungen Dritter ist der Antragsteller / Zuwendungsempfänger',
        options: [
            {
                label: 'nicht zum Vorsteuerabzug berechtigt.',
                value: '0',
            },
            {
                label:
                    'teilweise zum Vorsteuerabzug berechtigt. Umsatzsteuer ist in den Einzelpositionen des Gesamtfinanzierungsplans nur anteilig veranschlagt.',
                value: '1',
            },
            {
                label:
                    'zum Vorsteuerabzug berechtigt. Umsatzsteuer ist in den Einzelpositionen des Gesamtfinanzierungsplans nicht veranschlagt.',
                value: '2',
            },
        ],
        validation: validations.ustAbzug,
    },
    //bankAccount.name
    bankAccountName: {
        name: 'bankAccount.name',
        label: 'Kontoinhaber',
        validation: validations.bankAccount.name,
    },
    //bankAccount.institute
    institute: {
        name: 'bankAccount.institute',
        label: 'Kreditinstitut',
        validation: validations.bankAccount.institute,
    },
    //bankAccount
    iban: {
        name: 'bankAccount.iban',
        label: 'IBAN',
        validation: validations.bankAccount.iban,
    },
    //bankAccount
    bic: {
        name: 'bankAccount.bic',
        label: 'BIC',
        validation: validations.bankAccount.bic,
    },
    signaturePerson: {
        salutation: {
            name: 'signaturePerson.salutation',
            label: 'Anrede',
            validation: validations.salutation,
            options: [
                { label: 'Herr', value: 'm' },
                { label: 'Frau', value: 'f' },
                { label: 'ohne Anrede', value: 'd' },
            ],
            type: 'select',
        },
        title: {
            name: 'signaturePerson.title',
            label: 'Titel (optional)',
        },
        firstName: {
            name: 'signaturePerson.firstname',
            label: 'Vorname',
            validation: validations.firstName,
        },
        lastName: {
            name: 'signaturePerson.lastname',
            label: 'Nachname',
            validation: validations.lastName,
        },
        email: {
            name: 'signaturePerson.email',
            label: 'E-Mail',
            validation: validations.email,
        },
        phone: {
            name: 'signaturePerson.phone',
            label: 'Telefon',
            validation: validations.phone,
        },
        func: {
            name: 'signaturePerson.func',
            label: 'Funktion',
            validation: validations.func,
        },
        department: {
            name: 'signaturePerson.department',
            label: 'Abteilung',
            validation: validations.department,
        },
        street: {
            name: 'signaturePerson.address.street',
            label: 'Straße und Hausnummer',
            validation: validations.street,
        },
        postcode: {
            name: 'signaturePerson.address.postcode',
            label: 'PLZ',
            validation: validations.postCode,
        },
        city: {
            name: 'signaturePerson.address.city',
            label: 'Ort',
            validation: validations.city,
        },
        state: {
            name: 'signaturePerson.address.state',
            label: 'Bundesland',
            validation: validations.state,
            options: states,
            type: 'select',
        },
        type: {
            name: 'signaturePerson.type',
            label: 'Typ',
            validation: validations.type,
        },
        id: {
            name: 'signaturePerson.@id',
            label: 'ID',
        },
    },
    viceSignaturePerson: {
        salutation: {
            name: 'viceSignaturePerson.salutation',
            label: 'Anrede',
            options: [
                { label: 'Herr', value: 'm' },
                { label: 'Frau', value: 'f' },
                { label: 'ohne Anrede', value: 'd' },
            ],
            type: 'select',
        },
        title: {
            name: 'viceSignaturePerson.title',
            label: 'Titel (optional)',
        },
        firstName: {
            name: 'viceSignaturePerson.firstname',
            label: 'Vorname',
        },
        lastName: {
            name: 'viceSignaturePerson.lastname',
            label: 'Nachname',
        },
        email: {
            name: 'viceSignaturePerson.email',
            label: 'E-Mail',
            validation: validations.emailOptional,
        },
        phone: {
            name: 'viceSignaturePerson.phone',
            label: 'Telefon',
            validation: validations.phoneOptional,
        },
        func: {
            name: 'viceSignaturePerson.func',
            label: 'Funktion',
        },
        department: {
            name: 'viceSignaturePerson.department',
            label: 'Abteilung',
        },
        street: {
            name: 'viceSignaturePerson.address.street',
            label: 'Straße und Hausnummer',
        },
        postcode: {
            name: 'viceSignaturePerson.address.postcode',
            label: 'PLZ',
            validation: validations.postCodeOptional,
        },
        city: {
            name: 'viceSignaturePerson.address.city',
            label: 'Ort',
        },
        state: {
            name: 'viceSignaturePerson.address.state',
            label: 'Bundesland',
            options: states,
            type: 'select',
        },
        type: {
            name: 'viceSignaturePerson.type',
            label: 'Typ',
        },
        id: {
            name: 'viceSignaturePerson.@id',
            label: 'ID',
        },
    },
};
