import crypto from 'crypto';
import uniqid from 'uniqid';

function base64URLEncode(str) {
    return str
        .toString('base64')
        .replace(/\+/g, '-')
        .replace(/\//g, '_')
        .replace(/=/g, '');
}

function sha256(buffer) {
    return crypto.createHash('sha256').update(buffer).digest();
}

export function generateCodeVerifier() {
    return base64URLEncode(crypto.randomBytes(43));
}

export function generateCodeChallenge(verifyCode) {
    return base64URLEncode(sha256(verifyCode));
}

export function generateState() {
    return uniqid();
}

export function buildUrl(challenge, state) {
    let query = `${process.env.REACT_APP_HOST}/oauth/authorize?`;
    query += `redirect_uri=${process.env.REACT_APP_HOST}${process.env.REACT_APP_REDIRECT}&`;
    query += 'response_type=code&';
    query += `client_id=${process.env.REACT_APP_CLIENT_ID}&`;
    query += `code_challenge=${challenge}&`;
    query += 'code_challenge_method=S256&';
    query += `state=${state}&`;
    query += `scope=user`;
    return query;
}

export function generateParams() {
    const verify = generateCodeVerifier();
    const challenge = generateCodeChallenge(verify);
    const state = generateState();
    return {
        verify,
        challenge,
        state,
    };
}
