import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from 'react-query';
import { fetchMobilityData } from '../../../lib/api/Mobility/MobilityActions';
import WizardFormError from '../../../components/Partials/Error/WizardFormError';
import MobilityForm from '../../../components/Forms/Mobility/MobilityForm';
import FullContentOverlayLoader from '../../../components/Partials/Loader/FullContentOverlayLoader';

const MobilityFormContainer = (props) => {
    const { proposalId } = props;
    const { isLoading, refetch, error, data, isFetching } = useQuery(
        [proposalId, 'mobilityForm'],
        fetchMobilityData(proposalId),
        {
            initialStale: true,
            refetchOnWindowFocus: false,
        }
    );

    if (isLoading || isFetching)
        return <FullContentOverlayLoader loading={true} />;

    if (error) return <WizardFormError error={error} refetch={refetch} />;

    return (
        <MobilityForm
            submitFunc={props.save}
            error={props.saveError}
            defaultValues={data}
            saving={props.isSaving}
            proposalId={proposalId}
        />
    );
};

MobilityFormContainer.propTypes = {
    proposalId: PropTypes.string,
};

export default MobilityFormContainer;
