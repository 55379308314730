import React from 'react';
import PropTypes from 'prop-types';
import Alert from '@material-ui/lab/Alert';
import { Response } from '../../../util/DataTypes/Response';

const TeamMemberError = (props) => {
    const { error } = props;

    if (!error) return null;

    //TODO: Change error from message() to data() to get specific error info
    return <Alert severity="error">{error.message()}</Alert>;
};

TeamMemberError.propTypes = {
    error: PropTypes.instanceOf(Response),
};

export default TeamMemberError;
