import React from 'react';
import PropTypes from 'prop-types';
import ProjectForm from '../../../components/Forms/Project/ProjectForm';
import { useQuery } from 'react-query';
import { fetchProjectFields } from '../../../lib/api/Project/ProjectActions';
import WizardFormError from '../../../components/Partials/Error/WizardFormError';
import FullContentOverlayLoader from '../../../components/Partials/Loader/FullContentOverlayLoader';

const ProjectFormContainer = (props) => {
    const { proposalId } = props;
    const { isLoading, refetch, error, data, isFetching } = useQuery(
        [proposalId, 'projectForm'],
        () => fetchProjectFields(proposalId),
        {
            initialStale: true,
            refetchOnWindowFocus: false,
        }
    );

    if (isLoading || isFetching)
        return <FullContentOverlayLoader loading={true} />;

    if (error) return <WizardFormError error={error} refetch={refetch} />;

    return (
        <ProjectForm
            submitFunc={props.save}
            error={props.saveError}
            defaultValues={data}
            saving={props.isSaving}
            proposalId={proposalId}
        />
    );
};

ProjectFormContainer.propTypes = {
    proposalId: PropTypes.string.isRequired,
};

export default ProjectFormContainer;
