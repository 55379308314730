import React from 'react';
import PropTypes from 'prop-types';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { Typography } from '@material-ui/core';

const MobilityListRowError = (props) => {
    const { message } = props;

    return (
        <TableRow role="row">
            <TableCell role="cell" colSpan={6} aria-colspan={6}>
                <Typography component="span" gutterBottom>
                    {message}
                </Typography>
            </TableCell>
        </TableRow>
    );
};

MobilityListRowError.propTypes = {
    message: PropTypes.string.isRequired,
};

export default MobilityListRowError;
