import {
    fetchApplicationRoundData,
    fetchUserData,
} from '../api/AuthNew/AuthActions';
import { useAuth } from '../../stores/Auth.store';

export function setup() {
    return new Promise(async (resolve, reject) => {
        try {
            const user = await fetchUserData();
            const { setState: setAuthState } = useAuth;
            setAuthState({ user: { ...user, companyRole: 'admin' } });
            await fetchApplicationRoundData();
            resolve();
        } catch (e) {
            //TODO: Error?
            reject();
        }
    });
}
