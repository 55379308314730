import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from 'react-query';
import { proposalListContainerTexts } from '../../texts/container/ProposalListContainerTexts';
import { fetchProjects } from '../../lib/api/Project/ProjectActions';
import { useAuth } from '../../stores/Auth.store';
import { useApplicationRounds } from '../../stores/ApplicationRounds.store';

const ProposalListContainer = (props) => {
    const { render } = props;

    const user = useAuth((state) => state.user);
    const {
        currentApplicationRound,
        applicationRoundsById,
    } = useApplicationRounds((state) => {
        return {
            currentApplicationRound: state.currentApplicationRound,
            applicationRoundsById: state.applicationRoundsById,
        };
    });

    const { data, error, isLoading, isFetching, refetch } = useQuery(
        'proposals',
        () =>
            fetchProjects(
                user.company.id,
                currentApplicationRound,
                applicationRoundsById
            ),
        {
            initialStale: true,
            refetchOnWindowFocus: false,
            refetchOnMount: true,
        }
    );

    //TODO: Loading page
    if (isLoading) return <p>{proposalListContainerTexts.loading}</p>;

    //TODO: Error Page
    if (error) return <p>{proposalListContainerTexts.error}</p>;

    return <div>{render({ data, error, isFetching, refetch })}</div>;
};

ProposalListContainer.propTypes = {
    render: PropTypes.func.isRequired,
};

export default ProposalListContainer;
