import React from 'react';
import TextDecorator from './components/TextDecorator';
import CustomTooltip from './components/CustomTooltip';
import uniqid from 'uniqid';
import TextBlock from './TextBlock';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import _ from 'lodash';
import TextItalic from './components/TextItalic';
import TextBold from './components/TextBold';

const defaultOptions = {
    components: {
        u: {
            component: TextDecorator,
            props: {
                decoration: 'underline',
            },
        },
        i: {
            component: TextItalic,
            props: {},
        },
        b: {
            component: TextBold,
            props: {},
        },
        tooltip: {
            component: CustomTooltip,
            props: {},
        },
        li: {
            component: Typography,
            props: {
                component: 'li',
            },
        },
        a: {
            component: Link,
            props: {
                target: '_blank',
                rel: 'noreferrer',
                underline: 'always',
            },
        },
        ul: {
            component: Typography,
            props: {
                component: 'ul',
            },
        },
    },
};

export function parseToJsx(paragraph, options = {}) {
    const o = _.merge(defaultOptions, options);

    const split = paragraph.text.split(
        /(\[br]|\[u].*\[u]|\[i].*\[i]|\[b].*\[b]|\[tooltip\|\d+].*\[tooltip]|\[li].+?\[li]|\[a\|.+?].+?\[a]|\[ul].+\[ul])/g
    );

    const arr = [];

    split.forEach((element) => {
        if (element === '') return;

        const sp = element.match(/\[(.*?)]/g);
        const [component, param] = !sp
            ? [undefined]
            : sp.map((el) => el.slice(1, -1))[0].split('|');
        switch (component) {
            case 'br':
                arr.push(<br key={uniqid()} />);
                break;
            case 'u':
                arr.push(
                    <o.components.u.component
                        key={uniqid()}
                        text={
                            element
                                .match(/](.*)\[/g)
                                .map((el) => el.slice(1, -1))[0]
                        }
                        decoration="underline"
                    />
                );
                break;
            case 'i':
                arr.push(
                    <o.components.i.component
                        key={uniqid()}
                        text={
                            element
                                .match(/](.*)\[/g)
                                .map((el) => el.slice(1, -1))[0]
                        }
                        decoration="underline"
                    />
                );
                break;
            case 'b':
                arr.push(
                    <o.components.b.component
                        key={uniqid()}
                        text={
                            element
                                .match(/](.*)\[/g)
                                .map((el) => el.slice(1, -1))[0]
                        }
                    />
                );
                break;
            case 'tooltip':
                arr.push(
                    <o.components.tooltip.component
                        {...o.components.tooltip.props}
                        key={uniqid()}
                        title={
                            <TextBlock
                                textBlock={
                                    paragraph.tooltips[parseInt(param, 0)]
                                }
                            />
                        }
                        text={
                            element
                                .match(/](.*)\[/g)
                                .map((el) => el.slice(1, -1))[0]
                        }
                        interactive={
                            paragraph.tooltips[parseInt(param, 0)].interactive
                        }
                    />
                );
                break;
            case 'li':
                arr.push(
                    <o.components.li.component
                        key={uniqid()}
                        {...o.components.li.props}
                    >
                        {parseToJsx(
                            {
                                text: element
                                    .match(/](.*)\[/g)
                                    .map((el) => el.slice(1, -1))[0],
                                tooltips: paragraph.tooltips,
                            },
                            options
                        )}
                    </o.components.li.component>
                );
                break;
            case 'ul':
                arr.push(
                    <o.components.ul.component
                        key={uniqid()}
                        {...o.components.ul.props}
                    >
                        {parseToJsx(
                            {
                                text: element
                                    .match(/](.*)\[/g)
                                    .map((el) => el.slice(1, -1))[0],
                                tooltips: paragraph.tooltips,
                            },
                            options
                        )}
                    </o.components.ul.component>
                );
                break;
            case 'a':
                arr.push(
                    <o.components.a.component
                        key={uniqid()}
                        {...o.components.a.props}
                        href={param}
                    >
                        {
                            element
                                .match(/](.*)\[/g)
                                .map((el) => el.slice(1, -1))[0]
                        }
                    </o.components.a.component>
                );
                break;
            default:
                arr.push(element);
                break;
        }
    });
    return arr;
}
