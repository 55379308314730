import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import { useAuth } from '../../stores/Auth.store';
import NotAllowed from '../../pages/ErrorPages/NotAllowed';
import { verifyRoles } from '../../util/Auth/VerifyRoles';

const RoleRoute = (props) => {
    const { roles, path, exact, children } = props;

    const user = useAuth((state) => state.user);

    if (!user) return null;

    const hasRole = verifyRoles(user.roles, roles);

    if (roles.length === 0 || hasRole)
        return (
            <Route path={path} exact={exact}>
                {children}
            </Route>
        );

    return <NotAllowed />;
};

RoleRoute.propTypes = {
    roles: PropTypes.array,
    path: PropTypes.string,
    children: PropTypes.node,
    exact: PropTypes.bool,
};

RoleRoute.defaultProps = {
    roles: [],
    exact: false,
};

export default RoleRoute;
