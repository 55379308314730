import React from 'react';
import Button from '@material-ui/core/Button';
import { useAuth } from '../../stores/Auth.store';
import { CircularProgress, Tooltip } from '@material-ui/core';
import { CREATE_STATE } from '../../util/Overview/OverviewFunctions';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { proposalOverviewTexts as pot } from '../../texts/components/ProposalOverview/ProposalOverviewTexts';

const ProposalOverviewCreate = (props) => {
    const {
        create,
        isLoading,
        state,
        userCanCreateProposal,
        isCurrentApplicationRound,
    } = props;

    const clickedRef = React.useRef(false);
    const user = useAuth((state) => state.user);
    const companyId = user?.company['@id'];

    const disableButton =
        isLoading || state !== CREATE_STATE.OPEN || !userCanCreateProposal;

    let tooltip = '';
    if (!userCanCreateProposal) {
        tooltip = pot.state.notAllowed;
    } else if (state === CREATE_STATE.ALREADY_CREATED) {
        tooltip = pot.state.alreadyCreated;
    } else if (
        state === CREATE_STATE.NOT_ACTIVE ||
        !isCurrentApplicationRound
    ) {
        tooltip = pot.state.notActive;
    }

    return (
        <>
            <Tooltip
                title={<Typography>{tooltip}</Typography>}
                disableHoverListener={tooltip === ''}
                disableFocusListener={tooltip === ''}
                disableTouchListener={tooltip === ''}
            >
                <span>
                    <Button
                        variant="contained"
                        color={'primary'}
                        onClick={async () => {
                            if (clickedRef.current === false) {
                                clickedRef.current = true;
                                await create({
                                    companyId,
                                }).finally(() => {
                                    clickedRef.current = false;
                                });
                            }
                        }}
                        disabled={disableButton}
                    >
                        Neuen Antrag erstellen
                    </Button>
                </span>
            </Tooltip>
            {isLoading && (
                <CircularProgress
                    size={20}
                    color="primary"
                    style={{ marginLeft: 8, verticalAlign: 'middle' }}
                />
            )}
        </>
    );
};

ProposalOverviewCreate.propTypes = {
    create: PropTypes.func,
    isLoading: PropTypes.bool,
    error: PropTypes.object,
    state: PropTypes.number,
    userCanCreateProposal: PropTypes.bool,
    isCurrentApplicationRound: PropTypes.bool,
};

export default ProposalOverviewCreate;
