import React from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

import bmbf from '../../../static/images/bmbf.svg';
import aww from '../../../static/images/aww.svg';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@material-ui/core/Box';
import { headerTexts } from '../../../texts/HeaderTexts';

const useStyles = makeStyles((theme) => ({
    bmbf: {
        maxHeight: '140px',
        marginLeft: '-30px',
        '& img': {
            width: '100%',
            height: '100%',
        },
    },
    aww: {
        maxWidth: '117px',
        maxHeight: '53px',
        width: '100%',
        height: '100%',
        '& img': {
            width: '100%',
            height: '100%',
        },
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        minHeight: '140px',
    },
    border: {
        borderTop: '10px solid ' + theme.palette.primary.main,
    },
}));

const Header = () => {
    const classes = useStyles();
    return (
        <Box className={classes.border}>
            <Container className={classes.header}>
                <div className={classes.bmbf}>
                    <Typography variant="srOnly" component="span">
                        {headerTexts.ministry}
                    </Typography>
                    <img src={bmbf} alt={headerTexts.ministryLogoAlt} />
                </div>

                <div className={classes.aww}>
                    <Typography variant="srOnly" component="span">
                        {headerTexts.aww}
                    </Typography>
                    <img src={aww} alt={headerTexts.awwLogoAlt} />
                </div>
            </Container>
        </Box>
    );
};

export default Header;
