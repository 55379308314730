import React from 'react';
import PropTypes from 'prop-types';
import DisplayUser from './DisplayUser';
import DisplayCompany from './DisplayCompany';
import Grid from '@material-ui/core/Grid';
import DisplayBankAccount from './DisplayBankAccount';
import { coreDataOverviewTexts as cdot } from '../../../../texts/components/CoreData/CoreDataOverviewTexts';
import Tooltip from '@material-ui/core/Tooltip';
import { Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import RefreshButton from '../../Loader/RefreshButton';

const CoreDataDisplay = (props) => {
    const {
        coreData,
        handleReload,
        handleEdit,
        isFetching,
        displayButtons,
        userCanEdit,
        refetch,
    } = props;

    return (
        <Grid container spacing={2} justify="center">
            {displayButtons && userCanEdit && (
                <Grid
                    item
                    xs={12}
                    style={{
                        display: 'flex',
                        flexDirection: 'row-reverse',
                        justifyContent: 'space-between',
                        marginTop: '.6em',
                    }}
                >
                    <Tooltip title={<Typography>{cdot.reload}</Typography>}>
                        <span>
                            <RefreshButton
                                isFetching={isFetching}
                                handleReload={handleReload}
                            />
                        </span>
                    </Tooltip>
                    <Tooltip title={<Typography>{cdot.edit}</Typography>}>
                        <span>
                            <IconButton
                                aria-label={'Bearbeiten'}
                                disabled={isFetching}
                                onClick={handleEdit}
                                style={{ verticalAlign: 'sub' }}
                            >
                                <EditIcon />
                            </IconButton>
                        </span>
                    </Tooltip>
                </Grid>
            )}
            <Grid item xs={12} sm={6}>
                <DisplayCompany company={coreData} />
            </Grid>
            <Grid item xs={12} sm={6}>
                <DisplayBankAccount bankAccount={coreData.bankAccount} />
            </Grid>
            <Grid item xs={12} sm={6}>
                <DisplayUser
                    user={coreData.signaturePerson}
                    title={cdot.signatory}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <DisplayUser
                    user={coreData.viceSignaturePerson}
                    title={cdot.representative}
                    showDeleteButton={
                        coreData.viceSignaturePerson !== null &&
                        coreData.viceSignaturePerson !== undefined
                    }
                    refetch={refetch}
                />
            </Grid>
        </Grid>
    );
};

CoreDataDisplay.propTypes = {
    coreData: PropTypes.object,
    isFetching: PropTypes.bool,
    handleReload: PropTypes.func,
    handleEdit: PropTypes.func,
    displayButtons: PropTypes.bool,
    userCanEdit: PropTypes.bool,
    refetch: PropTypes.func,
};

CoreDataDisplay.defaultProps = {
    displayButtons: true,
    userCanEdit: false,
    refetch: null,
};

export default CoreDataDisplay;
