export const proposalListContainerTexts = {
    title: 'Anträge',
    tabs: {
        tab1: 'Laufende Anträge',
        tab2: 'Eingereichte Anträge',
        tab3: 'Abgeschlossene Anträge',
    },
    loading: 'Ihre Anträge werden geladen...',
    error: 'Fehler',
};
