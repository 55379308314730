export const proposalOverviewTexts = {
    proposalState: {
        EDITABLE: 'In Bearbeitung',
        SUBMITTED: 'Elektronisch übermittelt',
        RECEIVED_BY_MAIL: 'Postalisch eingegangen',
        CHECK_STARTED: 'In Prüfung',
        APPROVABLE: 'In Prüfung',
        NOT_APPROVABLE: 'In Prüfung',
        APPROVED: 'In Prüfung',
        DENIED: 'In Prüfung',
        APPROVAL_SENT: 'Bewilligt',
        DENIAL_SENT: 'Abgelehnt',
        FUNDING_STARTED: 'In Förderung',
        CANCELLED: 'Zurückgezogen',
        NOT_SUBMITTED: 'Nicht übermittelt',
        DEFAULT: 'In Bearbeitung',
    },
    state: {
        submitted: 'eingereicht',
        created: 'angelegt',
        inProgress: 'in Bearbeitung',
        alreadyCreated:
            'Sie haben in dieser Antragsrunde bereits einen Antrag erstellt.',
        notActive: 'Aktuell findet keine Antragsrunde statt.',
        notAllowed:
            'Sie haben keine Berechtigung, einen neuen Antrag zu erstellen.',
    },
    tooltip: {
        venture: 'Zur Übersichtsseite der Vorhaben wechseln',
        show: 'Antrag ansehen',
        edit: 'Antrag bearbeiten',
        download: 'Antrag herunterladen',
    },
    overviewTable: {
        fkz: 'Förderkennzeichen',
        date: 'Bewilligungszeitraum',
        state: 'Status',
        action: 'Aktion',
    },
    pagination: {
        displayRows: ({ from, to, count }) => `${from}-${to} von ${count}`,
        rows: 'Zeilen pro Seite:',
    },
    headers: {
        archive: 'Archiv',
        project: 'Ihre Vorhaben',
        proposal: 'Ihr Antrag',
    },
};
