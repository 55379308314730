import React from 'react';
import { Switch } from 'react-router-dom';
import RoleRoute from '../../components/PrivateRoute/RoleRoute';
import TeamOverview from '../../components/Team/TeamOverview';
import TeamOverviewContainer from '../../container/Team/TeamOverviewContainer';

const TeamRoutes = () => {
    return (
        <Switch>
            <RoleRoute path="/team" roles={['ROLE_MANAGER']} exact>
                <TeamOverviewContainer render={TeamOverview} />
            </RoleRoute>
        </Switch>
    );
};

export default TeamRoutes;
