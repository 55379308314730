import React from 'react';
import PropTypes from 'prop-types';
import Fade from '@material-ui/core/Fade';
import Typography from '@material-ui/core/Typography';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import withStyles from '@material-ui/core/styles/withStyles';
import Tooltip from '@material-ui/core/Tooltip';

const CustomTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: 'white',
        color: 'black',
        border: '1px solid black',
        boxShadow: theme.shadows[5],
    },
}))(Tooltip);

const CustomHelperIcon = withStyles((theme) => ({
    root: {
        cursor: 'help',
        verticalAlign: 'text-bottom',
        marginLeft: theme.spacing(0.5),
    },
}))(InfoOutlinedIcon);

const InfoBalloon = (props) => {
    const { info, placement } = props;

    return (
        <CustomTooltip
            TransitionComponent={Fade}
            TransitionProps={{ timeout: 600 }}
            title={<Typography>{info}</Typography>}
            placement={placement}
        >
            <CustomHelperIcon fontSize="small" />
        </CustomTooltip>
    );
};

InfoBalloon.propTypes = {
    info: PropTypes.string.isRequired,
    placement: PropTypes.string,
};

InfoBalloon.defaultProps = {
    info: '',
};

export default InfoBalloon;
