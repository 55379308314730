import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import RadioButtons from '../../Partials/Form/RadioButtons';
import CheckboxWithInfoText from '../../Partials/Form/CheckboxWithInfoText';
import RHFTextField from '../../Partials/Form/RHFTextField';
import { explanationsFormTexts } from '../../../texts/Forms/ExplanationsFormTexts';
import Typography from '@material-ui/core/Typography';
import TextBlock from '../../TextBlock/TextBlock';
import { Card, FormGroup } from '@material-ui/core';
import { explanationsFormConfig } from '../../../config/Forms/ExplanationsFormConfig';
import makeStyles from '@material-ui/core/styles/makeStyles';
import FormControl from '@material-ui/core/FormControl';
import { useParams } from 'react-router-dom';
import RHFMoneyInput from '../../Partials/Form/RHFMoneyInput';
import CardContent from '@material-ui/core/CardContent';

const useStyles = makeStyles({
    blockMargins: {
        marginTop: '1rem',
        marginBottom: '2rem',
    },
    checkboxMargins: {
        marginTop: '1rem',
        marginBottom: '1rem',
    },
});

const ExplanationsForm = (props) => {
    const { save, defaultValues, error } = props;

    const classes = useStyles();

    const { proposalId } = useParams();

    const {
        handleSubmit,
        setError,
        errors,
        register,
        control,
        watch,
    } = useForm({
        defaultValues,
    });

    useEffect(() => {
        if (error) {
            const violations = error.d.response?.data?.violations;
            if (violations) {
                violations.forEach((violation) => {
                    setError(violation.propertyPath, {
                        type: 'manual',
                        message: violation.message,
                    });
                });
            }
        }
    }, [error, setError]);

    const watchAllFields = watch();

    return (
        <form id="mainForm" onSubmit={handleSubmit(save)} noValidate>
            <div className={classes.blockMargins}>
                <RadioButtons
                    groupLabel={
                        explanationsFormConfig.otherwiseFinanced.groupLabel
                    }
                    name={'otherwiseFinanced'}
                    control={control}
                    errors={errors.otherwiseFinanced}
                    rules={explanationsFormConfig.otherwiseFinanced.validation}
                    buttons={explanationsFormConfig.otherwiseFinanced.options}
                />
                {watchAllFields.otherwiseFinanced === '1' && (
                    <>
                        <RHFMoneyInput
                            control={control}
                            name={'financedAmount'}
                            error={errors.financedAmount}
                        />
                        {'durch'}
                        <RHFTextField
                            control={control}
                            name={'financedBy'}
                            label={'Träger'}
                            error={errors.financedBy}
                        />
                    </>
                )}
            </div>

            <RadioButtons
                groupLabel={explanationsFormConfig.furtherSpending.groupLabel}
                headlineGroupLabel={true}
                groupSubLabel={
                    explanationsFormConfig.furtherSpending.groupSubLabel
                }
                name={'furtherSpending'}
                control={control}
                errors={errors.furtherSpending}
                rules={explanationsFormConfig.furtherSpending.validation}
                buttons={explanationsFormConfig.furtherSpending.options}
            />
            <FormControl component="fieldset" className={classes.blockMargins}>
                <FormGroup>
                    <CheckboxWithInfoText
                        register={register}
                        errors={errors.notStarted}
                        label={explanationsFormTexts.notStarted}
                        defaultChecked={defaultValues.notStarted}
                        name="notStarted"
                    />
                    <CheckboxWithInfoText
                        register={register}
                        errors={errors.noticed}
                        label={explanationsFormTexts.noticed}
                        defaultChecked={defaultValues.noticed}
                        name="noticed"
                    />
                    <CheckboxWithInfoText
                        register={register}
                        errors={errors.resourcesPresent}
                        label={explanationsFormTexts.resourcesPresent}
                        defaultChecked={defaultValues.resourcesPresent}
                        name="resourcesPresent"
                    />
                </FormGroup>
            </FormControl>

            <Typography variant="h5" component="h2" gutterBottom>
                {explanationsFormTexts.academics}
            </Typography>
            <Typography
                variant="subtitle1"
                component={'p'}
                gutterBottom
                style={{ fontSize: '1.1rem' }}
            >
                {explanationsFormTexts.academicsFootnote}
            </Typography>

            <RadioButtons
                groupLabel={explanationsFormConfig.isForAcademics.groupLabel}
                name={'isForAcademics'}
                control={control}
                buttons={explanationsFormConfig.isForAcademics.options}
                defaultValue={
                    explanationsFormConfig.isForAcademics.defaultValue
                }
            />

            <div className={classes.blockMargins}>
                <Typography gutterBottom style={{ fontSize: '1.1rem' }}>
                    {explanationsFormTexts.academicsPublic}
                </Typography>

                <Typography gutterBottom>
                    {explanationsFormTexts.basicFinance}
                </Typography>
                <CheckboxWithInfoText
                    register={register}
                    label={explanationsFormTexts.basicFinanceCheckbox}
                    defaultChecked={defaultValues.basicFinance}
                    name="basicFinance"
                />

                <Typography gutterBottom>
                    {explanationsFormTexts.ressort}
                </Typography>
                <CheckboxWithInfoText
                    register={register}
                    label={explanationsFormTexts.ressortCheckbox}
                    defaultChecked={defaultValues.ressort}
                    name="ressort"
                />
            </div>

            <Card>
                <CardContent>
                    <Typography variant="h5" component="h2" gutterBottom>
                        {explanationsFormTexts.dataPrivacy}
                    </Typography>
                    <TextBlock
                        textBlock={explanationsFormTexts.dataPrivacyText}
                    />

                    <div className={classes.checkboxMargins}>
                        <CheckboxWithInfoText
                            register={register}
                            label={explanationsFormTexts.informed}
                            errors={errors.informed}
                            defaultChecked={defaultValues.informed}
                            name="informed"
                        />
                    </div>

                    <div className={classes.checkboxMargins}>
                        <CheckboxWithInfoText
                            register={register}
                            label={
                                <TextBlock
                                    textBlock={explanationsFormTexts.obligation}
                                />
                            }
                            errors={errors.obligation}
                            defaultChecked={defaultValues.obligation}
                            name="obligation"
                        />
                    </div>

                    <div className={classes.checkboxMargins}>
                        <CheckboxWithInfoText
                            register={register}
                            label={explanationsFormTexts.dataPolicy}
                            defaultChecked={defaultValues.dataPolicy}
                            errors={errors.dataPolicy}
                            name="dataPolicy"
                        />
                    </div>

                    <div className={classes.checkboxMargins}>
                        <CheckboxWithInfoText
                            register={register}
                            label={explanationsFormTexts.correctness}
                            defaultChecked={defaultValues.correctness}
                            errors={errors.correctness}
                            name="correctness"
                        />
                    </div>
                </CardContent>
            </Card>

            <input
                type="hidden"
                name="proposalId"
                value={proposalId}
                ref={register()}
            />
        </form>
    );
};

ExplanationsForm.propTypes = {
    save: PropTypes.func.isRequired,
    defaultValues: PropTypes.object.isRequired,
};

ExplanationsForm.defaultProps = {
    defaultValues: {},
};

export default ExplanationsForm;
