import React from 'react';
import { Switch } from 'react-router-dom';
import RoleRoute from '../../components/PrivateRoute/RoleRoute';
import CoreDataContainer from '../../container/CoreData/CoreDataContainer';
import CoreDataOverview from '../../components/Partials/CoreData/CoreDataOverview';

const CoreDataRoutes = () => {
    return (
        <Switch>
            <RoleRoute
                roles={['ROLE_MANAGER', 'ROLE_CONTACT']}
                path="/stammdaten"
                exact
            >
                <CoreDataContainer render={CoreDataOverview} />
            </RoleRoute>
            <RoleRoute
                roles={['ROLE_MANAGER', 'ROLE_CONTACT']}
                path="/stammdaten/edit"
                exact
            >
                <CoreDataContainer
                    render={CoreDataOverview}
                    initialEditMode={true}
                />
            </RoleRoute>
        </Switch>
    );
};

export default CoreDataRoutes;
