import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from 'react-query';
import FullContentOverlayLoader from '../../../components/Partials/Loader/FullContentOverlayLoader';
import WizardFormError from '../../../components/Partials/Error/WizardFormError';
import FiscalYearForm from '../../../components/Forms/FiscalYear/FiscalYearForm';
import FiscalYearTable from '../../../components/FiscalYearTable/FiscalYearTable';
import { fetchFiscalYearData } from '../../../lib/api/FiscalYears/FiscalYearActions';
import Typography from '@material-ui/core/Typography';
import { fiscalYearFormTexts } from '../../../texts/Forms/FiscalYearFormTexts';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TextBlock from '../../../components/TextBlock/TextBlock';

const useStyles = makeStyles(() => ({
    explanation: {
        '& ul': {
            listStyleType: '"— "',
        },
    },
}));

const FiscalYearFormContainer = (props) => {
    const { proposalId } = props;
    const classes = useStyles();
    const { isLoading, refetch, isFetching, error, data } = useQuery(
        [proposalId, 'fiscalYearForm'],
        fetchFiscalYearData(proposalId),
        {
            initialStale: true,
            refetchOnWindowFocus: false,
        }
    );
    if (isLoading || isFetching)
        return <FullContentOverlayLoader loading={true} />;

    if (error) return <WizardFormError error={error} refetch={refetch} />;

    return (
        <>
            <Typography variant="h5" component="h2" gutterBottom>
                {fiscalYearFormTexts.subHead}
            </Typography>
            <div style={{ marginBottom: '2rem' }}>
                <FiscalYearTable data={data} />
            </div>
            <Typography variant="h5" component="h2" gutterBottom>
                {fiscalYearFormTexts.subHead2}
            </Typography>
            <div className={classes.explanation}>
                <TextBlock textBlock={fiscalYearFormTexts.explanation} />
            </div>
            <FiscalYearForm
                submitFunc={props.save}
                error={props.saveError}
                defaultValues={data}
                saving={props.isSaving}
                proposalId={proposalId}
            />
        </>
    );
};

FiscalYearFormContainer.propTypes = {
    proposalId: PropTypes.string,
    save: PropTypes.func.isRequired,
};

export default FiscalYearFormContainer;
