import PropTypes from 'prop-types';
import React from 'react';
import GenericSelect from './GenericSelect';
import GenericTextField from './GenericTextField';
import RHFMoneyInput from './RHFMoneyInput';
import _ from 'lodash';

const GenericFieldList = (props) => {
    const { control, config, errors, ...rest } = props;

    return (
        <>
            {Object.keys(config).map((field) => {
                if (config[field].type === 'select') {
                    return (
                        <GenericSelect
                            key={config[field].name}
                            config={config[field]}
                            control={control}
                            errors={errors}
                            {...rest}
                        />
                    );
                }
                if (config[field].type === 'money') {
                    return (
                        <RHFMoneyInput
                            key={config[field].name}
                            name={config[field].name}
                            label={config[field].label}
                            validation={config[field].validation}
                            control={control}
                            error={_.get(errors, config[field].name)}
                            info={config[field].info}
                            {...rest}
                        />
                    );
                }
                return (
                    <GenericTextField
                        key={config[field].name}
                        config={config[field]}
                        control={control}
                        errors={errors}
                        helperText={config[field].helperText}
                        {...rest}
                    />
                );
            })}
        </>
    );
};

GenericFieldList.propTypes = {
    config: PropTypes.object.isRequired,
    control: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
};

export default GenericFieldList;
