import React from 'react';
import PropTypes from 'prop-types';
import { steps } from '../../config/WizardSteps';
import { IconButton } from '@material-ui/core';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { useWizardControlStyles } from '../../styles/components/Wizard/WizardControlStyles';
import WizardControlChip from './WizardControlChip';
import CircularProgress from '@material-ui/core/CircularProgress';

const WizardControlBar = (props) => {
    const {
        currentStep,
        isFinished,
        isTransitioning,
        back,
        forward,
        toStep,
        verifying,
    } = props;

    const classes = useWizardControlStyles();

    const disableBack = () => {
        return currentStep === 0 || isTransitioning;
    };

    const disableNext = () => {
        return !isFinished || isTransitioning;
    };

    return (
        <div className={classes.flex}>
            {currentStep > 0 && (
                <IconButton
                    aria-label={'Zurück'}
                    size="small"
                    edge="end"
                    disabled={disableBack()}
                    color="primary"
                    onClick={back}
                >
                    <NavigateBeforeIcon />
                </IconButton>
            )}
            <div className={classes.bar}>
                {steps.map((step, id) => (
                    <WizardControlChip
                        toStep={toStep}
                        currentStep={currentStep}
                        id={id}
                        step={step}
                        isFinished={isFinished}
                        isTransitioning={isTransitioning}
                        key={`control_${step.name}_${id}`}
                    />
                ))}
            </div>
            {currentStep < steps.length - 1 && (
                <IconButton
                    aria-label={'Weiter'}
                    size="small"
                    className={classes.button}
                    disabled={disableNext()}
                    color="primary"
                    onClick={forward}
                >
                    {verifying && (
                        <CircularProgress
                            className={classes.progress}
                            size={25}
                        />
                    )}
                    <NavigateNextIcon />
                </IconButton>
            )}
        </div>
    );
};

WizardControlBar.propTypes = {
    currentStep: PropTypes.number,
    isFinished: PropTypes.bool,
    isTransitioning: PropTypes.bool,
    toStep: PropTypes.func,
    back: PropTypes.func,
    forward: PropTypes.func,
    verifying: PropTypes.bool,
};

export default WizardControlBar;
