import React from 'react';
import { Tooltip } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
        maxHeight: '300px',
        overflow: 'auto',
    },
}))(Tooltip);

export default function ({ text, title, interactive }) {
    return (
        <span>
            {text}{' '}
            <LightTooltip title={title} interactive={interactive}>
                <InfoOutlinedIcon
                    fontSize="small"
                    style={{ verticalAlign: 'bottom' }}
                />
            </LightTooltip>
        </span>
    );
}
