import { CREATE_STATE } from '../../../util/Overview/OverviewFunctions';

export function fetchProjectsMutator(
    data,
    currentApplicationRound,
    applicationRoundsById
) {
    const mutated = {
        projects: [],
        proposals: [],
        archive: [],
        createState: CREATE_STATE.OPEN,
    };
    if (!currentApplicationRound) {
        mutated.createState = CREATE_STATE.NOT_ACTIVE;
    }

    data.forEach((el) => {
        const applicationRound = applicationRoundsById[el.applicationRound];

        if (
            !!currentApplicationRound &&
            applicationRound &&
            applicationRound['@id'] === currentApplicationRound['@id']
        ) {
            mutated.createState = CREATE_STATE.ALREADY_CREATED;
        }

        if (el.archived) {
            mutated.archive.push({ ...el, applicationRound });
            return;
        }
        mutated.proposals.push({ ...el, applicationRound });
    });

    return mutated;
}
