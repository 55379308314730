import PropTypes from 'prop-types';
import { useMutation, useQuery } from 'react-query';
import {
    getCoreData,
    saveCoreData,
} from '../../lib/api/CoreData/CoreDataActions.new';

const CoreDataContainer = (props) => {
    const { render, initialEditMode } = props;

    //TODO: Need id? Or is it BE action to get Data for given user?
    const { data, isLoading, isFetching, error, refetch } = useQuery(
        ['coreData'],
        getCoreData,
        {
            refetchOnWindowFocus: false,
            refetchOnMount: true,
            initialStale: true,
        }
    );

    const [mutateCoreData, { isLoading: isSaving }] = useMutation(
        saveCoreData,
        { throwOnError: true }
    );

    return render({
        data,
        isFetching,
        refetch,
        mutateCoreData,
        isLoading,
        error,
        isSaving,
        initialEditMode,
    });
};

CoreDataContainer.propTypes = {
    render: PropTypes.func.isRequired,
    initialEditMode: PropTypes.bool,
};

CoreDataContainer.defaultValues = {
    initialEditMode: false,
};

export default CoreDataContainer;
