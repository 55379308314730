import { Response } from '../../../util/DataTypes/Response';
import { api } from '../AxiosConfig/Config';
import { handleError } from '../../../util/Error/HandleError';
import { fetchProjectsMutator } from './ProjectMutators';

export function fetchProjects(
    companyId,
    currentApplicationRound,
    applicationRoundsById
) {
    return new Promise(async (resolve, reject) => {
        try {
            const { data } = await api().get(
                `/companies/${companyId}/projects`
            );

            const mutated = fetchProjectsMutator(
                data['hydra:member'],
                currentApplicationRound,
                applicationRoundsById
            );
            resolve(mutated);
        } catch (e) {
            reject(
                handleError(e, {
                    generic: 'SomeError',
                })
            );
        }
    });
}

export function createNewProposal(props) {
    return new Promise(async (resolve, reject) => {
        try {
            const { companyId } = props;
            const { data } = await api().post('/projects', {
                company: companyId,
            });

            resolve(data);
        } catch (e) {
            reject(Response.Error('Projekt konnte nicht erstellt werden', e));
        }
    });
}

export function fetchProjectData(proposalId) {
    return new Promise(async (resolve, reject) => {
        try {
            const { data } = await api().get(`/projects/${proposalId}`);
            resolve(data);
        } catch (e) {
            reject(
                handleError(e, {
                    generic:
                        'Beim Laden des Projekts ist ein Fehler aufgetreten',
                    404: 'Für den angegebenen Antrag konnten kein Projekt gefunden werden',
                    500: 'Beim Laden des Projekts kam es zu einem Fehler',
                })
            );
        }
    });
}

export function fetchProjectFields(proposalId) {
    return new Promise(async (resolve, reject) => {
        try {
            const { data } = await api().get(`/projects/${proposalId}/fields`);
            resolve(data);
        } catch (e) {
            reject(
                handleError(e, {
                    generic:
                        'Beim Laden der Projektdaten ist ein Fehler aufgetreten',
                    404: 'Für den angegebenen Antrag konnten keine Projektdaten gefunden werden',
                    500: 'Beim Laden der Projektdaten kam es zu einem Fehler',
                })
            );
        }
    });
}

export function saveProjectData({ proposalId, data }) {
    return new Promise(async (resolve, reject) => {
        try {
            await api().put(`/projects/${proposalId}/fields`, data);
            resolve(Response.Success('Projektdaten erfolgreich gespeichert'));
        } catch (e) {
            reject(
                Response.Error(
                    'Projektdaten konnten nicht gespeichert werden',
                    e
                )
            );
        }
    });
}
