import React, { useEffect, useState } from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import PropTypes from 'prop-types';
import RHFMoneyInput from '../../Partials/Form/RHFMoneyInput';
import Grid from '@material-ui/core/Grid';
import { useColorStyles } from '../../../styles/BasicStyles';
import { dineroFormat } from '../../../util/Helper/Dinero';
import { Controller } from 'react-hook-form';
import TextField from '@material-ui/core/TextField';
import MoneyInput from '../../Partials/Form/MoneyInput';
import FormControl from '@material-ui/core/FormControl';
import { removeEurSymbol } from '../../../util/Mutators/StringConversion';

const calculateTotal = (years, total) =>
    parseInt(total) - parseInt(years.year1 || 0) - parseInt(years.year2 || 0);

const FiscalYearTableRow = (props) => {
    const { rowConfig, watch, control, disabled, errors, clearErrors } = props;
    const classes = useColorStyles();
    const [total, setTotal] = useState('0');

    useEffect(() => {
        if (!watch || disabled) {
            setTotal(dineroFormat(calculateTotal(rowConfig, rowConfig.total)));
            return;
        }
        const totalTmp = calculateTotal(watch, rowConfig.total);

        setTotal(dineroFormat(totalTmp));
    }, [
        watch,
        classes.colorGreen,
        classes.colorRed,
        classes.colorSecondaryText,
        rowConfig,
        disabled,
    ]);

    return (
        <Grid container component={TableRow}>
            <Grid item component={TableCell} xs={4}>
                {rowConfig.label}
            </Grid>
            <Grid item component={TableCell} xs={2}>
                <RHFMoneyInput
                    control={control}
                    name={`${rowConfig.name}.year1`}
                    error={errors?.year1}
                    clearErrors={clearErrors}
                    disabled={disabled}
                />
            </Grid>
            <Grid item component={TableCell} xs={2}>
                <RHFMoneyInput
                    control={control}
                    name={`${rowConfig.name}.year2`}
                    error={errors?.year2}
                    clearErrors={clearErrors}
                    disabled={disabled}
                />
            </Grid>
            <Grid item component={TableCell} xs={4}>
                <FormControl component="fieldset" fullWidth={true}>
                    <Controller
                        render={() => (
                            <TextField
                                value={removeEurSymbol(total)}
                                InputProps={{
                                    inputComponent: MoneyInput,
                                    name: `${rowConfig.name}.total`,
                                    disableUnderline: true,
                                    className:
                                        removeEurSymbol(total) !== '0.00'
                                            ? classes.colorRed
                                            : undefined,
                                    inputProps: {
                                        'aria-label': rowConfig.name,
                                    },
                                }}
                                onKeyDown={(event) => {
                                    event.preventDefault();
                                }}
                                error={Boolean(errors?.total)}
                                helperText={
                                    errors?.total
                                        ? errors?.total.message
                                        : undefined
                                }
                                fullWidth
                            />
                        )}
                        name={`${rowConfig.name}.total`}
                        control={control}
                        defaultValue={total}
                    />
                </FormControl>
            </Grid>
        </Grid>
    );
};

FiscalYearTableRow.propTypes = {
    rowConfig: PropTypes.object.isRequired,
    watch: PropTypes.object.isRequired,
    control: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
    errors: PropTypes.object,
    clearErrors: PropTypes.func,
};

FiscalYearTableRow.defaultProps = {
    watch: {},
    disabled: false,
};

export default FiscalYearTableRow;
