import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

const Paragraph = (props) => {
    const { paragraph, variant, component, as, ...rest } = props;

    const Component = as;

    return (
        <>
            <Component
                variant={variant}
                component={component}
                gutterBottom
                {...rest}
            >
                {paragraph}
            </Component>
        </>
    );
};

Paragraph.propTypes = {
    paragraph: PropTypes.array.isRequired,
    options: PropTypes.object,
    as: PropTypes.elementType,
    variant: PropTypes.string,
};

Paragraph.defaultProps = {
    options: {},
    variant: 'body1',
    as: Typography,
    component: 'p',
};

export default React.memo(Paragraph);
