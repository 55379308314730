import React from 'react';
import PropTypes from 'prop-types';
import Chip from '@material-ui/core/Chip';
import Tooltip from '@material-ui/core/Tooltip';
import { Typography } from '@material-ui/core';

const WizardControlChip = (props) => {
    const {
        step,
        id,
        toStep,
        currentStep,
        isFinished,
        isTransitioning,
    } = props;

    const getFillMode = (current) => {
        if (current < currentStep) return 'default';
        if (current === currentStep && isFinished) return 'default';
        return 'outlined';
    };

    const getColor = (current) => {
        if (current === currentStep) return 'primary';
        return undefined;
    };

    const getLabel = (current, name) => {
        if (current === currentStep) return name;
        return `${current + 1}`;
    };

    const isClickable = (current) => {
        return current < currentStep && !isTransitioning;
    };

    const mutate = (current) => () => {
        if (current < currentStep && !isTransitioning) {
            toStep(current);
        }
    };

    const render = () => (
        <Chip
            size="small"
            label={getLabel(id, step.name)}
            variant={getFillMode(id)}
            color={getColor(id)}
            clickable={isClickable(id)}
            onClick={mutate(id)}
        />
    );

    const withTooltip = () => (
        <Tooltip title={<Typography>{step.name}</Typography>} arrow>
            {render()}
        </Tooltip>
    );

    if (id === currentStep) return render();
    return withTooltip();
};

WizardControlChip.propTypes = {
    step: PropTypes.object,
    toStep: PropTypes.func,
    id: PropTypes.number,
    currentStep: PropTypes.number,
    isFinished: PropTypes.bool,
    isTransitioning: PropTypes.bool,
};

export default React.memo(WizardControlChip);
