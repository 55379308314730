export const isObjectEmpty = (obj) => {
    let isEmpty = true;
    Object.getOwnPropertyNames(obj).forEach(function (val) {
        if (
            obj[val] !== undefined &&
            obj[val] !== '' &&
            typeof obj[val] !== 'object'
        ) {
            isEmpty = false;
        } else if (isEmpty && typeof obj[val] === 'object') {
            isEmpty = isObjectEmpty(obj[val]);
        }
    });

    return isEmpty;
};
