export const footerTexts = {
    agency:
        'Nationale Agentur Bildung für Europa beim Bundesinstitut für Berufsbildung (NA beim BIBB)',
    programm: 'Programmdurchführung:',
    logoAlt:
        'Logo Nationale Agentur Bildung für Europa beim Bundesinstitut für Berufsbildung (NA beim BIBB)',
    support: 'Support',
    imprint: 'Impressum',
    dataPrivacy: 'Datenschutz',
};
