import { explanationsFormTexts } from '../../texts/Forms/ExplanationsFormTexts';

export const explanationsFormConfig = {
    otherwiseFinanced: {
        groupLabel: explanationsFormTexts.otherwiseFinanced.label,
        options: [
            {
                value: '0',
                label: explanationsFormTexts.otherwiseFinanced.values.no,
            },
            {
                value: '1',
                label: explanationsFormTexts.otherwiseFinanced.values.yes,
            },
        ],
        validation: {
            required: 'Pflichtfeld',
        },
    },
    financedAmount: {
        validation: {
            required: 'Pflichtfeld',
        },
    },
    financedBy: {
        validation: {
            required: 'Pflichtfeld',
        },
    },
    furtherSpending: {
        groupLabel: explanationsFormTexts.furtherSpending.title,
        groupSubLabel: explanationsFormTexts.furtherSpending.subTitle,
        options: [
            { label: explanationsFormTexts.furtherSpending.no, value: '0' },
            { label: explanationsFormTexts.furtherSpending.yes, value: '1' },
        ],
        validation: {
            required: 'Pflichtfeld',
        },
    },
    isForAcademics: {
        groupLabel: explanationsFormTexts.isForAcademics.label,
        options: [
            {
                label: explanationsFormTexts.isForAcademics.nonEconomic,
                value: '0',
            },
            {
                label: explanationsFormTexts.isForAcademics.economic,
                value: '1',
            },
            {
                label: explanationsFormTexts.isForAcademics.notApplicable,
                value: '2',
            },
        ],
    },
};
