export const texts = {
    readFurther: 'Weiterlesen',
    here: 'Hier',
    clickToGoOn: 'klicken um weiter zu gelangen',
    deactivated: {
        title: 'Konto deaktiviert',
        body:
            'Ihr Konto wurde deaktviert und kann nicht weiter genutzt werden. Sollte es sich hierbei um einen Fehler handeln oder Sie weitere Fragen haben, kontaktieren Sie unseren Support.',
    },
    notConfirmed: {
        title: 'E-Mail Adresse nicht bestätigt',
        body:
            'Sie haben Ihre angegebene E-Mail Adresse nocht nicht bestätigt. Sollten Sie keine E-Mail mit einem Aktivierungslinkt erhalten haben, wenden Sie sich an den Support.',
    },
    notApproved: {
        title: 'Konto nicht freigeschaltet',
        body:
            'Ihr Konto wurde noch nicht von uns freigeschaltet. Es befindet sich aktuell in der Prüfung und wir melden uns, sobald wir mit der Prüung bzw. Freischaltung fertig sind. Für weitere Fragen, wenden Sie sich an den Support.',
    },
    passwordChange: {
        title: 'Passwort ändern',
        body:
            'Ihr Passwort stimmt nicht mehr mit unseren Passwortrichtlinieren überein. Bitte ändern Sie Ihr Passwort, indem Sie im Login-Dialog auf "Passwort vergessen" klicken.',
    },
    anonLines: [
        {
            text: 'AusbildungWeltweit',
            variant: 'h4',
            component: 'h1',
        },
        {
            text:
                'Das AusbildungWeltweit Projektportal bietet die Möglichkeit Förderanträge zu stellen und Aktionen für die Umsetzung bewilligter Vorhaben vorzunehmen. Eine Einrichtung kann im Projektportal ein Stammdatenprofil haben, dem alle Anträge und Projekte zugeordnet werden.',
            variant: 'h6',
        },
        {
            text:
                '<strong>Stellt Ihre Einrichtung zum ersten Mal einen Antrag, dann klicken Sie im Menü auf REGISTRIEREN.</strong>',
            variant: 'h6',
        },
        {
            text:
                'Im Prozess der Registrierung erzeugen Sie ein Stammdatenprofil Ihrer Einrichtung und legen im gleichen Zug fest, welche Person zugangsberechtigt ist. Bevor die Registrierung wirksam ist, wird überprüft, dass tatsächlich noch kein Profil der Einrichtung angelegt wurde. Dieses „Freischalten“ erfolgt manuell und kann Rückfragen beinhalten. <strong><em>Registrierungen sollten daher spätestens eine Woche vor Antragsfrist erfolgen.</em></strong>',
            variant: 'h6',
        },
        {
            text:
                '<strong>Hat Ihre Einrichtung in der Vergangenheit bereits einen Antrag gestellt, dann klicken Sie im Menü auf ANMELDEN.</strong>',
            variant: 'h6',
        },
        {
            text:
                'Dann existiert bereits ein Stammdatenprofil. Die zugangsberechtigte Person kann sich direkt anmelden. Das Login basiert auf der E-Mail-Adresse, die bei Registrierung eingetragen wurde und dem dabei festgelegten Passwort. Bei Bedarf steht die Funktion „Passwort vergessen“ zur Verfügung.',
            variant: 'h6',
        },
        {
            text:
                'Soll die zugangsberechtigte Person oder ihre E-Mail-Adresse geändert werden, geschieht dies zentral über die NA beim BIBB. Setzen Sie sich dazu mit dem Team AusbildungWeltweit in Verbindung.',
            variant: 'h6',
        },

        {
            text:
                'Ein Leitfaden zur Nutzung des Portals und Informationen zum Förderprogramm stehen für Sie auf der Programmwebseite www.ausbildung-weltweit.de zur Verfügung. Gern sind wir auch telefonisch oder per E-Mail für Sie da.',
            variant: 'h6',
        },
        {
            text: 'Ihr AusbildungWeltweit-Team',
            variant: 'h6',
        },
    ],
    dashboard: {
        title: 'AusbildungWeltweit',
        articles: [
            {
                title:
                    'Herzlich Willkommen im AusbildungWeltweit Projektportal',
                rows: [
                    {
                        type: 'p',
                        variant: 'h6',
                        text:
                            'Im AusbildungWeltweit Projektportal können Anträge für das Förderprogramm gestellt sowie Zuschüsse für bewilligte Vorhaben bewirtschaftet werden.',
                    },
                    {
                        type: 'p',
                        variant: 'h6',
                        text:
                            'Die Funktion der Antragstellung wird etwa vier Wochen vor der jeweiligen Antragsfrist freigeschaltet.',
                    },
                    {
                        type: 'p',
                        variant: 'h6',
                        text:
                            'Bei AusbildungWeltweit handelt es sich um ein Förderprogramm des Bundes. Daher bestehen spezielle Anforderungen, wenn es um Finanzen und Zugangsberechtigung zum Portal geht.',
                    },
                    {
                        type: 'p',
                        variant: 'h6',
                        text:
                            'Die Stammdaten der Einrichtung inklusive der Angaben zur Bankverbindung und der zeichnungsberechtigten Person werden Bestandteil von Anträgen und Bescheiden. Eine Änderung muss daher stets erst von der NA beim BIBB freigegeben werden, bevor sie wirksam wird und eine Antragstellung oder andere Aktion abgeschlossen werden kann.',
                    },
                    {
                        type: 'p',
                        variant: 'h6',
                        text:
                            'Für bewilligte Vorhaben stehen die Funktionen Mittel anfordern, Änderungen beantragen, Teilnehmendenverwaltung für Angaben zu umgesetzten Aufenthalten sowie  Verwendungsnachweis erstellen zur Verfügung.',
                    },
                    {
                        type: 'p',
                        variant: 'h6',
                        text:
                            'Bitte nutzen Sie die Kurzanleitung Projektportal für weitere Informationen.',
                    },
                    {
                        type: 'p',
                        variant: 'h6',
                        text:
                            'Alle Informationen und Dokumente zur Antragstellung und Durchführung finden Sie auf der Webseite [a|https://www.ausbildung-weltweit.de]www.ausbildung-weltweit.de[a] im Menüpunkt Förderung.',
                    },
                    {
                        type: 'p',
                        variant: 'h6',
                        text:
                            'Kommen Sie gern auf uns zu, sobald Sie Fragen haben. Wir unterstützen Sie gern!',
                    },
                ],
                signature: [
                    {
                        type: 'block',
                        variant: 'h6',
                        text: 'Team AusbildungWeltweit',
                    },
                    {
                        type: 'block',
                        variant: 'h6',
                        text: 'bei der Nationalen Agentur beim BIBB',
                    },
                ],
            },
        ],
        items: [
            {
                title: 'Anträge und Vorhaben',
                link: '/antrag',
                linkText: 'Anträge und Vorhaben',
            },
            {
                title: 'Mittelanforderung',
                body: 'Diese Funktion steht momentan noch nicht zur Verfügung.',
                link: '/',
                linkText: 'Mittelanforderung',
            },
            {
                title: 'Verwendungsnachweis',
                body: 'Diese Funktion steht momentan noch nicht zur Verfügung.',
                link: '/',
                linkText: 'Verwendungsnachweis',
            },
            {
                title: 'Support',
                body: '',
                link: '#',
                linkText: 'Support',
            },
        ],
    },
};
