import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import Link from '@material-ui/core/Link';
import { Link as RouterLink } from 'react-router-dom';
import { getProposalLink } from '../../util/Overview/OverviewFunctions';
import { Tooltip } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { proposalOverviewTexts as pot } from '../../texts/components/ProposalOverview/ProposalOverviewTexts';
import ProposalPdfDownloader from '../../container/Proposal/ProposalPdfDownloader';

const OverviewTableActions = (props) => {
    const { rowData } = props;

    const isSubmitted = rowData.proposalState !== 'EDITABLE';
    const iStep = parseInt(rowData.currentStep, 10);

    return (
        <>
            {rowData.proposalState !== 'NOT_SUBMITTED' && (
                <Tooltip
                    title={
                        <Typography>
                            {isSubmitted
                                ? pot.tooltip.download
                                : pot.tooltip.edit}
                        </Typography>
                    }
                >
                    {isSubmitted ? (
                        <IconButton
                            aria-label={'PDF herunterladen'}
                            size="small"
                        >
                            <ProposalPdfDownloader
                                pdfMediaIri={rowData.projectPdf}
                                projectId={rowData.projectId}
                            />
                        </IconButton>
                    ) : (
                        <Link
                            aria-label={'Bearbeiten'}
                            component={RouterLink}
                            to={getProposalLink(iStep, isSubmitted, rowData.id)}
                        >
                            <IconButton aria-label={'Bearbeiten'} size="small">
                                <EditIcon fontSize="inherit" />
                            </IconButton>
                        </Link>
                    )}
                </Tooltip>
            )}
        </>
    );
};

OverviewTableActions.propTypes = {
    rowData: PropTypes.object,
};

export default OverviewTableActions;
