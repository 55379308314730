import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from 'react-query';
import FullContentOverlayLoader from '../../../components/Partials/Loader/FullContentOverlayLoader';
import WizardFormError from '../../../components/Partials/Error/WizardFormError';
import FinalizationForm from '../../../components/Forms/Finalization/FinalizationForm';
import { fetchFinalizationData } from '../../../lib/api/Finalization/FinalizationActions';

const FinalizationFormContainer = (props) => {
    const { proposalId } = props;

    const { isLoading, isFetching, data, error, refetch } = useQuery(
        [proposalId, 'finalizationForm'],
        fetchFinalizationData(proposalId),
        { initialStale: true, refetchOnWindowFocus: false }
    );

    if (isLoading || isFetching)
        return <FullContentOverlayLoader loading={true} />;

    if (error) return <WizardFormError error={error} refetch={refetch} />;

    return (
        <FinalizationForm
            save={props.save}
            error={props.saveError}
            defaultValues={data}
            saving={props.isSaving}
        />
    );
};

FinalizationFormContainer.propTypes = {
    proposalId: PropTypes.string,
    save: PropTypes.func.isRequired,
};

export default FinalizationFormContainer;
