import React from 'react';
import './App.css';
import { HelmetProvider } from 'react-helmet-async';
import PageRouter from './pages';
import { SnackbarProvider } from 'notistack';
import { theme } from './Theme';
import { ThemeProvider } from '@material-ui/core/styles';
import { ReactQueryCacheProvider } from 'react-query';
import { queryCache } from './QueryCache';
import AppContainer from './container/App/AppContainer';
import localforage from 'localforage';

localforage.config({
    name: 'AusbildungWeltweit',
    description: 'Temp data used in AWW',
    version: 1.0,
    driver: localforage.LOCALSTORAGE,
    storeName: 'aww',
});

function App() {
    return (
        <React.Fragment>
            <ThemeProvider theme={theme}>
                <ReactQueryCacheProvider queryCache={queryCache}>
                    <HelmetProvider>
                        <SnackbarProvider
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                        >
                            <AppContainer>
                                <PageRouter />
                            </AppContainer>
                        </SnackbarProvider>
                    </HelmetProvider>
                </ReactQueryCacheProvider>
            </ThemeProvider>
        </React.Fragment>
    );
}

export default App;
