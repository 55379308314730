import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { budgetPlanFormConfig } from '../../../config/Forms/BudgetPlanFormConfig';
import GenericFieldList from '../../Partials/Form/GenericFieldList';
import { dineroFormat } from '../../../util/Helper/Dinero';
import Typography from '@material-ui/core/Typography';
import { budgetPlanFormTexts } from '../../../texts/Forms/BudgetPlanFormTexts';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Alert } from '@material-ui/lab';
import { euroToCentInt } from '../../../util/Mutators/StringConversion';

const useStyles = makeStyles((theme) => ({
    sumElement: {
        backgroundColor: theme.palette.grey[100],
        color: theme.palette.text.primary,
        padding: theme.spacing(1),
        marginTop: theme.spacing(2),
    },
}));

const sumUp = (form) =>
    Object.values(form).reduce((acc, val) => acc + parseInt(val || 0), 0);

const BudgetPlanForm = (props) => {
    const {
        submitFunc,
        defaultValues,
        proposalId,
        error,
        fiscalYearTotalSum,
    } = props;
    const classes = useStyles();
    const { handleSubmit, errors, control, watch, setError } = useForm({
        defaultValues,
    });
    const handleSubmitCallback = (values) => {
        submitFunc({ proposalId, data: values });
    };
    const watchAllFields = watch(undefined, { field: '0' });
    const [sumTotal, setSumTotal] = useState('0');
    const [sumLowerThanFiscal, setSumLowerThanFiscal] = useState(true);

    useEffect(() => {
        if (!error) return;
        const violations = error.d.response?.data?.violations;
        if (violations) {
            violations.forEach((violation) => {
                setError(violation.propertyPath, {
                    type: 'manual',
                    message: violation.message,
                });
            });
        }
    }, [error, setError]);

    useEffect(() => {
        const sum = sumUp({
            ...watchAllFields,
        });
        setSumTotal(dineroFormat(sum));
        setSumLowerThanFiscal(
            euroToCentInt(sumTotal) < euroToCentInt(fiscalYearTotalSum)
        );
    }, [watchAllFields, fiscalYearTotalSum, sumTotal]);

    return (
        <form
            id="mainForm"
            onSubmit={handleSubmit(handleSubmitCallback)}
            noValidate
        >
            <GenericFieldList
                config={budgetPlanFormConfig}
                control={control}
                errors={errors}
            />
            <Typography
                variant="h6"
                component="h2"
                className={classes.sumElement}
                gutterBottom
            >
                {budgetPlanFormTexts.sumTotal} {sumTotal}
            </Typography>
            {sumLowerThanFiscal && (
                <Alert severity={'warning'}>
                    {budgetPlanFormTexts.warningIfLessThenFiscalYearSum}
                </Alert>
            )}
        </form>
    );
};

BudgetPlanForm.propTypes = {
    defaultValues: PropTypes.object,
    submitFunc: PropTypes.func.isRequired,
    proposalId: PropTypes.string.isRequired,
    error: PropTypes.object,
    fiscalYearTotalSum: PropTypes.string,
};

export default BudgetPlanForm;
