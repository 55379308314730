import { validations } from '../../util/Validation/validations';
import { budgetPlanFormTexts } from '../../texts/Forms/BudgetPlanFormTexts';

export const budgetPlanFormConfig = {
    travel: {
        label: budgetPlanFormTexts.travel.label,
        name: 'travel',
        type: 'money',
        info: budgetPlanFormTexts.travel.info,
        validation: validations.moneyInputCannotBeNegative,
    },
    residence: {
        label: budgetPlanFormTexts.residence.label,
        name: 'residing',
        type: 'money',
        info: budgetPlanFormTexts.residence.info,
        validation: validations.moneyInputCannotBeNegative,
    },
    organisation: {
        label: budgetPlanFormTexts.organisation.label,
        name: 'organisation',
        type: 'money',
        validation: validations.moneyInputCannotBeNegative,
    },
    processing: {
        label: budgetPlanFormTexts.processing.label,
        name: 'processing',
        type: 'money',
        info: budgetPlanFormTexts.processing.info,
        validation: validations.moneyInputCannotBeNegative,
    },
    disabledReqs: {
        label: budgetPlanFormTexts.disabledReqs.label,
        name: 'disabledReqs',
        type: 'money',
        validation: validations.moneyInputCannotBeNegative,
    },
};
