import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import { CircularProgress } from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';

const RefreshButton = (props) => {
    const { isFetching, handleReload, ...rest } = props;

    return (
        <div style={{ textAlign: 'end' }}>
            <IconButton
                aria-label={'Aktualisieren'}
                disabled={isFetching}
                onClick={handleReload}
                style={{ verticalAlign: 'sub' }}
                {...rest}
            >
                {isFetching ? <CircularProgress size={24} /> : <RefreshIcon />}
            </IconButton>
        </div>
    );
};

RefreshButton.propTypes = {
    isFetching: PropTypes.bool,
    handleReload: PropTypes.func,
};

export default RefreshButton;
