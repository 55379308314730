import React from 'react';
import PropTypes from 'prop-types';
import Toolbar from '@material-ui/core/Toolbar';
import TextField from '@material-ui/core/TextField';
import { useTeamToolbarStyles } from '../../../styles/components/Team/TeamToolbarStyles';
import IconButton from '@material-ui/core/IconButton';
import AddBoxIcon from '@material-ui/icons/AddBox';
import Typography from '@material-ui/core/Typography';
import { teamTexts as tt } from '../../../texts/components/Team/TeamTexts';
import ReplayIcon from '@material-ui/icons/Replay';
import Tooltip from '@material-ui/core/Tooltip';

const TeamToolbar = (props) => {
    const { onChange, handleAdd, refetch, isFetching } = props;
    const classes = useTeamToolbarStyles();

    const handleChange = (evt) => {
        onChange(evt.currentTarget.value);
    };

    return (
        <Toolbar className={classes.toolbar}>
            <Tooltip title={<Typography>{tt.addTeamMember}</Typography>}>
                <span>
                    <IconButton
                        aria-label={'Person hinzufügen'}
                        onClick={handleAdd}
                    >
                        <AddBoxIcon />
                    </IconButton>
                </span>
            </Tooltip>
            <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                <TextField
                    id="search"
                    size="small"
                    variant="outlined"
                    onChange={handleChange}
                    label={'Suchen'}
                />
                <Tooltip title={<Typography>{tt.reloadTooltip}</Typography>}>
                    <span>
                        <IconButton
                            aria-label={'Aktualisieren'}
                            onClick={refetch}
                            disabled={isFetching}
                            size="small"
                            style={{ marginLeft: 8 }}
                        >
                            <ReplayIcon />
                        </IconButton>
                    </span>
                </Tooltip>
            </div>
        </Toolbar>
    );
};

TeamToolbar.propTypes = {
    search: PropTypes.string,
    onChange: PropTypes.func,
    handleAdd: PropTypes.func,
    isFetching: PropTypes.bool,
    refetch: PropTypes.func,
};

export default TeamToolbar;
