import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from 'react-query';
import { Controller } from 'react-hook-form';
import Autocomplete from '@material-ui/lab/Autocomplete';
import _ from 'lodash';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';

const Complete = (props) => {
    const {
        name,
        control,
        error,
        asyncFunc,
        queryId,
        defaultValue,
        label,
        variant,
        rules,
        inputProps,
    } = props;

    const { data, isFetching, isLoading } = useQuery(queryId, asyncFunc, {
        initialData: [],
        initialStale: true,
        refetchOnWindowFocus: false,
        staleTime: 1000 * 60 * 30,
    });

    const getOption = (option) => {
        if (option.label) return option;
        return data.find((op) => option.label === op);
    };

    return (
        <Controller
            render={(props) => (
                <Autocomplete
                    {...props}
                    fullWidth
                    options={data}
                    getOptionLabel={(option) => getOption(option).label}
                    getOptionSelected={(option, value) => {
                        return _.isEqual(option.label, getOption(value).label);
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={label}
                            variant={variant}
                            InputProps={{
                                ...params.InputProps,
                                ...inputProps,
                                endAdornment: (
                                    <>
                                        {isLoading || isFetching ? (
                                            <CircularProgress
                                                color="inherit"
                                                size={20}
                                            />
                                        ) : null}
                                        {params.InputProps.endAdornment}
                                    </>
                                ),
                            }}
                            error={Boolean(error)}
                            helperText={
                                Boolean(error) ? error.message : undefined
                            }
                            required={Boolean(rules.required)}
                        />
                    )}
                    onChange={(_, data) => props.onChange(data)}
                />
            )}
            name={name}
            control={control}
            defaultValue={defaultValue}
            rules={rules}
        />
    );
};

Complete.propTypes = {
    name: PropTypes.string.isRequired,
    control: PropTypes.object.isRequired,
    error: PropTypes.object,
    asyncFunc: PropTypes.func.isRequired,
    queryId: PropTypes.any.isRequired,
    defaultValue: PropTypes.object,
    label: PropTypes.string,
    variant: PropTypes.oneOf(['outlined', 'standard', 'filled']),
    rules: PropTypes.object,
};

Complete.defaultProps = {
    error: undefined,
    defaultValue: null,
    variant: 'standard',
    rules: undefined,
};

export default Complete;
