import { steps } from '../../config/WizardSteps';

export const CREATE_STATE = {
    NOT_ACTIVE: 0,
    ALREADY_CREATED: 1,
    OPEN: 2,
};

export function getProposalLink(currentStep, isSubmitted, proposalId) {
    const lastStep = steps.length;
    const iStep = parseInt(currentStep);

    if (iStep <= lastStep && !isSubmitted)
        return `/antrag/bearbeiten/${proposalId}`;
    if (isSubmitted) return `/antrag/ansehen/${proposalId}`;
    return `/antrag/ansehen/${proposalId}`;
}

/**
 * Berechnung der Höhe für die emptyRows (padding row für die Tabelle) in px
 * 59px entspricht der Höhe einer Tabellen Zeile
 * Während isLoading sind die Zeilen höher, da <Skeleton/> höher als Text ist
 */
export function calculateHeight(emptyRows, isLoading, maxRows) {
    if (isLoading) return 30;
    return maxRows === 0 ? 59 * 4 : 59 * emptyRows;
}
