import React from 'react';
import { Typography } from '@material-ui/core';
import { privateRouteTexts } from '../../texts/components/PrivateRouteTexts';
import LoginContainer from '../../container/Login/LoginContainer';
import PrivateRouteButton from './PrivateRouteButton';
import LoginButtonModal from '../Layout/Navbar/LoginButton/LoginButtonModal';
import { useAuth } from '../../stores/Auth.store';
import { usePrivateRouteLoginStyles } from '../../styles/components/PrivateRoute/PrivateRouteLoginStyles';

const PrivateRouteLogin = (props) => {
    const error = useAuth((state) => state.error);
    const classes = usePrivateRouteLoginStyles();
    return (
        <>
            <Typography variant="h4" component="h2" gutterBottom>
                {privateRouteTexts.title}
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
                {privateRouteTexts.body}
            </Typography>
            {error && (
                <Typography className={classes.error} gutterBottom>
                    {error}
                </Typography>
            )}
            <LoginContainer
                render={PrivateRouteButton}
                renderModal={LoginButtonModal}
            />
        </>
    );
};

export default PrivateRouteLogin;
