import React from 'react';
import PropTypes from 'prop-types';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useMobilityEditStyles } from '../../../../styles/components/forms/MobilityForm/MobilityEditStyles';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';

const MobilityDialogTitle = (props) => {
    const { variant, onClose, isSaving } = props;
    const classes = useMobilityEditStyles();
    const getTitle = () => {
        switch (variant) {
            case 'edit':
                return 'Mobilität bearbeiten';
            default:
                return 'Mobilität erstellen';
        }
    };

    const title = getTitle();

    return (
        <DialogTitle disableTypography>
            <Typography variant="h6">{title}</Typography>

            <IconButton
                aria-label="Schließen"
                className={classes.closeButton}
                onClick={onClose}
                disabled={isSaving}
            >
                <CloseIcon />
            </IconButton>
        </DialogTitle>
    );
};

MobilityDialogTitle.propTypes = {
    variant: PropTypes.oneOf(['edit', 'create']),
    onClose: PropTypes.func,
    isSaving: PropTypes.bool,
};

export default React.memo(MobilityDialogTitle);
