import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Header from './Header';
import Navbar from './Navbar';
import UserBar from './UserBar';
import Content from './Content';
import Footer from './Footer';
import { Helmet } from 'react-helmet-async';
import { texts } from '../../texts/GeneralTexts';

const Layout = ({ isAuthenticated, children }) => {
    return (
        <React.Fragment>
            <CssBaseline />
            <Helmet>
                <title>{texts.awwAcronym}</title>
            </Helmet>
            <Header />
            <Navbar isAuthenticated={isAuthenticated} />
            {isAuthenticated && <UserBar />}
            <Content>{children}</Content>
            <Footer />
        </React.Fragment>
    );
};

export default Layout;
