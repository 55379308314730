import React from 'react';
import PropTypes from 'prop-types';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import CardContent from '@material-ui/core/CardContent';
import { coreDataOverviewTexts as cdot } from '../../../../texts/components/CoreData/CoreDataOverviewTexts';

const DisplayBankAccount = (props) => {
    const { bankAccount } = props;

    return (
        <Card style={{ height: '100%' }}>
            <CardContent>
                <Typography variant="h6" component="h2">
                    <AccountBalanceIcon
                        style={{ verticalAlign: 'sub', marginRight: 4 }}
                    />
                    {cdot.bankAccountData}
                </Typography>
                {!bankAccount ? (
                    <Typography>{cdot.noData}</Typography>
                ) : (
                    <>
                        <Typography>
                            <b>Kontoinhaber: </b>
                            {bankAccount.name ?? 'Nicht angegeben'}
                        </Typography>
                        <Typography>
                            <b>Bankinstitut: </b>
                            {bankAccount.institute ?? 'Nicht angegeben'}
                        </Typography>
                        <Typography>
                            <b>IBAN: </b>
                            {bankAccount.iban ?? 'Nicht angegeben'}
                        </Typography>
                        <Typography>
                            <b>BIC: </b>
                            {bankAccount.bic ?? 'Nicht angegeben'}
                        </Typography>
                    </>
                )}
            </CardContent>
        </Card>
    );
};

DisplayBankAccount.propTypes = {
    bankAccount: PropTypes.object,
};

export default DisplayBankAccount;
