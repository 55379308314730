import React from 'react';
import PropTypes from 'prop-types';
import ProposalOverviewCreateContainer from '../../container/ProposalOverview/ProposalOverviewCreateContainer';
import ProposalOverviewCreate from './ProposalOverviewCreate';
import Divider from '@material-ui/core/Divider';
import RefreshButton from '../Partials/Loader/RefreshButton';
import { proposalOverviewTexts as pot } from '../../texts/components/ProposalOverview/ProposalOverviewTexts';
import ProposalTable from './ProposalTable';

const ProposalOverview = (props) => {
    const { data, isFetching, refetch } = props;

    return (
        <div>
            <RefreshButton handleReload={refetch} isFetching={isFetching} />

            <ProposalTable
                title={pot.headers.proposal}
                data={data.proposals}
                isLoading={isFetching}
                type="full"
            />
            <ProposalOverviewCreateContainer
                render={ProposalOverviewCreate}
                state={data.createState}
            />
            <Divider style={{ marginTop: 24, marginBottom: 24 }} />

            <ProposalTable
                title={pot.headers.archive}
                data={data.archive}
                isLoading={isFetching}
                type="archive"
            />
        </div>
    );
};

ProposalOverview.propTypes = {
    data: PropTypes.object,
    error: PropTypes.object,
    isFetching: PropTypes.bool,
    refetch: PropTypes.func,
};

export default ProposalOverview;
