import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { coreDataFormConfig as config } from '../../../config/Forms/CoreDataFormConfig';
import { coreDataOverviewTexts as cdot } from '../../../texts/components/CoreData/CoreDataOverviewTexts';
import Grid from '@material-ui/core/Grid';
import TwoColumnFormRow from '../../Partials/Form/TwoColumnFormRow';
import { Typography } from '@material-ui/core';
import GenericTextField from '../../Partials/Form/GenericTextField';
import UserSubForm from '../../Partials/Form/UserSubForm';
import GenericSelect from '../../Partials/Form/GenericSelect';
import InfoBalloon from '../../Partials/Form/InfoBalloon';
import AsyncSelect from '../../Partials/Form/AsyncSelect';
import { Alert } from '@material-ui/lab';
import RadioButtons from '../../Partials/Form/RadioButtons';

const CoreDataFormNew = (props) => {
    const { data, mutate, disabled } = props;

    const { handleSubmit, errors, control, reset } = useForm({
        defaultValues: data,
    });

    const handleReset = () => {
        reset({});
    };

    return (
        <>
            <form
                id="mainForm"
                onSubmit={handleSubmit(mutate)}
                onReset={handleReset}
                noValidate
            >
                <Grid container spacing={2}>
                    <Grid item sm={12}>
                        <Typography variant="h5" component="h3">
                            {cdot.companyData}
                        </Typography>
                    </Grid>

                    <input
                        type="hidden"
                        name={config.companyId.name}
                        ref={control.register()}
                    />

                    <Grid item sm={12}>
                        <GenericTextField
                            config={config.name}
                            control={control}
                            errors={errors}
                            disabled={disabled}
                        />
                    </Grid>
                    <Grid item sm={12}>
                        <AsyncSelect
                            config={config.legalForm}
                            control={control}
                            errors={errors}
                            info={cdot.legalFormInfo}
                            disabled={disabled}
                        />
                    </Grid>
                    <Grid item sm={12}>
                        <RadioButtons
                            groupLabel={config.ustAbzug.groupLabel}
                            name={config.ustAbzug.name}
                            control={control}
                            buttons={config.ustAbzug.options}
                            defaultValue={config.ustAbzug.defaultValue}
                            rules={config.ustAbzug.validation}
                            errors={errors.ustAbzug}
                        />
                    </Grid>

                    <TwoColumnFormRow
                        firstColumn={
                            <GenericTextField
                                config={config.employees}
                                control={control}
                                errors={errors}
                                type="number"
                                disabled={disabled}
                                inputProps={{ min: 0 }}
                            />
                        }
                        secondColumn={
                            <GenericTextField
                                config={config.apprentices}
                                control={control}
                                errors={errors}
                                type="number"
                                disabled={disabled}
                                inputProps={{ min: 0 }}
                            />
                        }
                    />

                    <TwoColumnFormRow
                        firstColumn={
                            <GenericTextField
                                config={config.street}
                                control={control}
                                errors={errors}
                                disabled={disabled}
                            />
                        }
                        secondColumn={
                            <GenericTextField
                                config={config.city}
                                control={control}
                                errors={errors}
                                disabled={disabled}
                            />
                        }
                    />
                    <TwoColumnFormRow
                        firstColumn={
                            <GenericTextField
                                config={config.postcode}
                                control={control}
                                errors={errors}
                                disabled={disabled}
                            />
                        }
                        secondColumn={
                            <GenericSelect
                                config={config.state}
                                control={control}
                                errors={errors}
                                disabled={disabled}
                            />
                        }
                    />

                    <Grid item sm={12}>
                        <GenericTextField
                            config={config.homepage}
                            control={control}
                            errors={errors}
                            disabled={disabled}
                        />
                    </Grid>

                    <Grid item sm={12}>
                        <Typography
                            variant="h5"
                            component="h3"
                            style={{ display: 'flex', alignItems: 'center' }}
                        >
                            {cdot.bankAccountData}
                            <InfoBalloon info={cdot.bankAccountDataInfo} />
                        </Typography>
                    </Grid>

                    <TwoColumnFormRow
                        firstColumn={
                            <GenericTextField
                                config={config.bankAccountName}
                                control={control}
                                errors={errors}
                                disabled={disabled}
                            />
                        }
                        secondColumn={
                            <GenericTextField
                                config={config.institute}
                                control={control}
                                errors={errors}
                                disabled={disabled}
                            />
                        }
                    />
                    <TwoColumnFormRow
                        firstColumn={
                            <GenericTextField
                                config={config.iban}
                                control={control}
                                errors={errors}
                                disabled={disabled}
                            />
                        }
                        secondColumn={
                            <GenericTextField
                                config={config.bic}
                                control={control}
                                errors={errors}
                                disabled={disabled}
                            />
                        }
                    />
                    <Grid item sm={12}>
                        <Typography variant="h5" component="h3">
                            {cdot.signatory}
                        </Typography>
                    </Grid>

                    <UserSubForm
                        config={config.signaturePerson}
                        control={control}
                        errors={errors}
                        disabled={disabled}
                    />

                    <Grid item sm={12}>
                        <Typography
                            variant="h5"
                            component="h3"
                            style={{ marginBottom: '.4em' }}
                        >
                            {cdot.representative}
                        </Typography>
                        <Alert severity="info">
                            <Typography variant="body2">
                                {cdot.representativeInfo}
                            </Typography>
                        </Alert>
                    </Grid>

                    <UserSubForm
                        config={config.viceSignaturePerson}
                        control={control}
                        errors={errors}
                        disabled={disabled}
                    />
                </Grid>
            </form>
        </>
    );
};

CoreDataFormNew.propTypes = {
    defaultValues: PropTypes.object,
    mutate: PropTypes.func.isRequired,
    isTransitioning: PropTypes.bool,
    data: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
};

CoreDataFormNew.defaultProps = {
    defaultValues: {},
    disabled: false,
};

export default CoreDataFormNew;
