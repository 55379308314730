import React from 'react';
import nabibb from '../../../static/images/nabibb.svg';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import { footerTexts } from '../../../texts/FooterTexts';

const useStyles = makeStyles((theme) => ({
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'baseline',
        alignItems: 'flex-end',
        '& a': {
            color: '#000',
        },
        '& ul': {
            listStyle: 'none',
            padding: '0',
            margin: '0',
            marginLeft: 'auto',
            marginTop: theme.spacing(1),
            '& li': {
                display: 'inline',
                marginRight: theme.spacing(2),
            },
            '& li:last-child': {
                marginRight: '0',
            },
        },
        '& div': {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            '& p': {
                display: 'inline-block',
                margin: '0',
                marginRight: theme.spacing(1),
            },
            '& img': {
                maxHeight: '70px',
                height: '100%',
            },
        },
        '& div:last-child': {
            flexDirection: 'row',
            width: '100%',
            '& p': {
                marginRight: 'auto',
            },
        },
    },
}));

const FooterCopyright = () => {
    const classes = useStyles();
    return (
        <Box className={classes.wrapper}>
            <div>
                <Typography variant="srOnly" component="span">
                    {footerTexts.agency}
                </Typography>
                <Typography component="p">{footerTexts.programm}</Typography>
                <img src={nabibb} alt={footerTexts.logoAlt} />
            </div>

            <Box>
                <ul>
                    <li>
                        <Link component={RouterLink} to="/impressum">
                            {footerTexts.imprint}
                        </Link>
                    </li>
                    <li>
                        <Link component={RouterLink} to="/datenschutz">
                            {footerTexts.dataPrivacy}
                        </Link>
                    </li>
                </ul>
            </Box>
        </Box>
    );
};

export default FooterCopyright;
