import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import DashboardArticle from './DashboardArticle';
import { texts as dt } from '../../../texts/DashboardTexts';

const Dashboard = () => {
    return (
        <Grid container spacing={10}>
            <Grid item xs={12}>
                <Typography variant="h5" component="h1" gutterBottom>
                    {dt.dashboard.title}
                </Typography>
                <DashboardArticle {...dt.dashboard.articles[0]} />
            </Grid>
        </Grid>
    );
};

Dashboard.propTypes = {};

export default Dashboard;
