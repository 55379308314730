import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import TableContainer from '@material-ui/core/TableContainer';
import { TableHead } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TeamToolbar from './TeamToolbar';
import TeamTableRow from './TeamTableRow';
import TeamTableBody from './TeamTableBody';
import { dataFilter } from '../../../util/Helper/DataFilter';
import { texts } from '../../../texts/GeneralTexts';

const TeamTable = (props) => {
    const {
        team,
        handleAdd,
        error,
        isFetching,
        refetch,
        handleEdit,
        handleLock,
        handleApplicationRoundEdit,
    } = props;

    const [search, setSearch] = React.useState('');

    const display = useMemo(() => {
        return dataFilter(search, team, ['firstName', 'lastName', 'email']);
    }, [search, team]);

    return (
        <TableContainer>
            <TeamToolbar
                search={search}
                onChange={setSearch}
                handleAdd={handleAdd}
                refetch={refetch}
                isFetching={isFetching}
            />
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>{texts.actions}</TableCell>
                        <TableCell>{texts.firstName}</TableCell>
                        <TableCell>{texts.lastName}</TableCell>
                        <TableCell>{texts.email}</TableCell>
                        <TableCell>{texts.role}</TableCell>
                        <TableCell>{texts.phone}</TableCell>
                        <TableCell>{texts.applicationRound}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TeamTableBody loading={isFetching} error={error}>
                        {team === 0 ? (
                            <span>{texts.none.toUpperCase()}</span>
                        ) : (
                            display.map((d) => (
                                <TeamTableRow
                                    teamMember={d}
                                    key={d.id}
                                    handleEdit={handleEdit}
                                    handleLock={handleLock}
                                    handleApplicationRoundEdit={
                                        handleApplicationRoundEdit
                                    }
                                />
                            ))
                        )}
                    </TeamTableBody>
                </TableBody>
            </Table>
        </TableContainer>
    );
};

TeamTable.propTypes = {
    team: PropTypes.array,
    handleAdd: PropTypes.func.isRequired,
    error: PropTypes.object,
    isFetching: PropTypes.bool,
    isLoading: PropTypes.bool,
    refetch: PropTypes.func,
    handleLock: PropTypes.func.isRequired,
    handleEdit: PropTypes.func.isRequired,
    handleApplicationRoundEdit: PropTypes.func.isRequired,
};

export default TeamTable;
