import { makeStyles } from '@material-ui/core/styles';

export const useColorStyles = makeStyles((theme) => ({
    colorRed: {
        border: '1px solid ' + theme.palette.error.main,
        borderRadius: '4px',
        paddingRight: '5px',
    },
    colorGreen: {
        border: '1px solid ' + theme.palette.success.main,
        borderRadius: '4px',
        paddingRight: '5px',
    },
    colorPrimaryText: {
        color: theme.palette.text.primary,
    },
    colorSecondaryText: {
        color: theme.palette.text.secondary,
    },
    backgroundGrey: {
        backgroundColor: theme.palette.grey[100],
    },
}));
