import moment from 'moment';

export function mutateMobility(mobility) {
    return {
        '@id': mobility['@id'],
        total: mobility.requestedBudget.total,
        start: mobility.fields.start,
        end: mobility.fields.end,
        duration: [mobility.fields.start, mobility.fields.end],
        attendees: '' + mobility.fields.attendees,
        area: '' + mobility.fields.area,
        type: '' + mobility.fields.type,
        disabledReqs: mobility.requestedBudget.disabledReqs,
        trainingType:
            mobility.fields.trainingType != null
                ? '' + mobility.fields.trainingType
                : undefined,
        reqTravelAllowance: mobility.fields.reqTravelAllowance ? '1' : '0',
        reqProcessing: mobility.fields.reqProcessing ? '1' : '0',
        reqResAllowance: mobility.fields.reqResAllowance ? '1' : '0',
        reqOrgAllowance: mobility.fields.reqOrgAllowance ? '1' : '0',
        trainingRegulation: mobility.fields.trainingRegulation,
        partner: mobility.fields.partner,
        region: mobility.fields.region,
        city: mobility.fields.city,
    };
}

export function getMutator(data, countries) {
    //PLACEHOLDER ATM
    return data.map((el) => {
        const mutated = mutateMobility(el);
        const financialPlan = countries.find(
            (country) => country['@id'] === el.financialPlan
        );
        return { ...mutated, financialPlan: { ...financialPlan } };
    });
}

/*
"@id": "someId"
area: "4"
attendees: "10"
city: "Das ist ein ort"
destination: Object { country: "Afghanistan", label: "Afghanistan", travelUnit: "92500", … }
disabledRequirements: "1.111.111,00 €"
duration: Array [ "2020-12-13", "2020-12-20" ]
partner: "Das ist ein Test"
region: "Das ist eine Region"
reqOrgaAllowance: "0"
reqPrePostProcessing: "0"
reqResidenceAllowance: "0"
reqTravelAllowance: "1"
trainingRegulation: true
type: "1"
 */
export function saveMutator(data) {
    const copy = { ...data };

    copy.financialPlan = copy.financialPlan['@id'];
    copy.requestedBudget = {
        disabledReqs: copy.disabledReqs ? copy.disabledReqs : '0',
    };

    copy.fields = {
        reqTravelAllowance: copy.reqTravelAllowance === '1',
        reqResAllowance: copy.reqResAllowance === '1',
        reqOrgAllowance: copy.reqOrgAllowance === '1',
        reqProcessing: copy.reqProcessing === '1',
        trainingRegulation: copy.trainingRegulation,
        area: parseInt(copy.area),
        attendees: parseInt(copy.attendees),
        type: parseInt(copy.type),
        start: moment(copy.start).format(),
        end: moment(copy.end).format(),
        trainingType: copy.trainingType ? parseInt(copy.trainingType) : null,
        partner: copy.partner,
        region: copy.region,
        city: copy.city,
    };
    delete copy.partner;
    delete copy.region;
    delete copy.city;
    delete copy.area;
    delete copy.attendees;
    delete copy.type;
    delete copy.start;
    delete copy.end;
    delete copy.trainingType;
    delete copy.disabledReqs;
    delete copy.duration;
    delete copy.destination;
    delete copy.reqOrgAllowance;
    delete copy.reqTravelAllowance;
    delete copy.reqResAllowance;
    delete copy.reqProcessing;
    delete copy.trainingRegulation;

    return copy;
}
