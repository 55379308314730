import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import TeamTable from './TeamTable/TeamTable';
import { teamTexts as tt } from '../../texts/components/Team/TeamTexts';
import TeamMemberDialog from '../Forms/Team/TeamMemberDialog';
import TeamMemberApplicationRoundEditDialog from '../Forms/Team/TeamMemberApplicationRoundEditDialog';
import { Alert } from '@material-ui/lab';

const TeamOverview = (props) => {
    const { data: team, error, isFetching, refetch, lock } = props;

    const [
        showTeamMemberFormDialog,
        setShowTeamMemberFormDialog,
    ] = React.useState(false);
    const [
        showApplicationRoundEditDialog,
        setShowApplicationRoundEditDialog,
    ] = React.useState(false);
    const [teamMember, setTeamMember] = React.useState(undefined);

    const handleAdd = () => {
        setShowTeamMemberFormDialog(true);
    };

    const handleClose = () => {
        setShowTeamMemberFormDialog(false);
        setShowApplicationRoundEditDialog(false);
        setTeamMember(undefined);
    };

    const handleEdit = (user) => {
        setTeamMember(user);
        setShowTeamMemberFormDialog(true);
    };

    const handleApplicationRoundEdit = (user) => {
        setTeamMember(user);
        setShowApplicationRoundEditDialog(true);
    };

    const handleLock = (id, isActive) => {
        lock({ id, isActive });
    };

    return (
        <div>
            <Typography variant="h4" component="h1" gutterBottom>
                {tt.title}
            </Typography>

            <Alert severity="info">
                {tt.infoText.map((text, index) => {
                    return (
                        <Typography variant="body2" key={index}>
                            {text}
                        </Typography>
                    );
                })}
            </Alert>

            <TeamTable
                error={error}
                handleAdd={handleAdd}
                refetch={refetch}
                isFetching={isFetching}
                team={team}
                handleLock={handleLock}
                handleEdit={handleEdit}
                handleApplicationRoundEdit={handleApplicationRoundEdit}
            />

            <TeamMemberDialog
                refetch={refetch}
                open={showTeamMemberFormDialog}
                handleClose={handleClose}
                user={teamMember}
            />

            {teamMember && (
                <TeamMemberApplicationRoundEditDialog
                    open={showApplicationRoundEditDialog}
                    handleClose={handleClose}
                    user={teamMember}
                />
            )}
        </div>
    );
};

TeamOverview.propTypes = {
    data: PropTypes.array,
    handleAdd: PropTypes.func,
    error: PropTypes.object,
    isFetching: PropTypes.bool,
    isLoading: PropTypes.bool,
    refetch: PropTypes.func,
    lock: PropTypes.func,
    deleteTeamMember: PropTypes.func,
    isLocking: PropTypes.bool,
};

export default TeamOverview;
