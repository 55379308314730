import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';

const TwoColumnFormRow = (props) => {
    const { firstColumn, secondColumn } = props;

    return (
        <>
            <Grid item xs={12} sm={6}>
                {firstColumn}
            </Grid>
            <Grid item xs={12} sm={6}>
                {secondColumn}
            </Grid>
        </>
    );
};

TwoColumnFormRow.propTypes = {
    firstColumn: PropTypes.node,
    secondColumn: PropTypes.node,
};

export default TwoColumnFormRow;
