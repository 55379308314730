import FooterGridSupport from '../components/Layout/Footer/FooterGridSupport';
import FooterGridDirective from '../components/Layout/Footer/FooterGridDirective';
import richtlinie from '../static/AWW_Foerderrichtlinie-2023.pdf';

export const footerData = [
    {
        id: 1,
        headline: 'Fachlicher Support',
        body: ['AusbildungWeltweit', 'NA beim BIBB'],
        mail: {
            link:
                'ausbildung-weltweit@bibb.de?subject=AusbildungWeltweit Anfrage',
            text: 'ausbildung-weltweit@bibb.de',
        },
        tel: 'Programmberatung: 0228 / 107 1611',
        render: FooterGridSupport,
    },
    {
        id: 2,
        headline: 'Technischer Support',
        body: [
            'Deutsches Zentrum für Luft- und Raumfahrt e. V. (DLR)',
            'Berlin',
        ],
        mail: {
            link: 'pt-webservice@dlr.de?subject=AusbildungWeltweit Anfrage',
            text: 'pt-webservice@dlr.de',
        },
        tel: 'Hotline: 030 67055-767',
        render: FooterGridSupport,
    },
    {
        id: 3,
        headline: 'Mehr Informationen',
        rows: [
            'Zur {%Förderrichtlinie%} (PDF-Dokument) ',
            'Zur Website {%www.ausbildung-weltweit.de%}',
            'Zur {%Kurzanleitung Projektportal%} (PDF-Dokument)',
        ],
        uris: [
            richtlinie,
            'https://www.ausbildung-weltweit.de/',
            'https://www.ausbildung-weltweit.de/SharedDocs/Downloads/de/dateien/portal_ausbildungweltweit_anleitung.pdf?__blob=publicationFile&v=1',
        ],
        render: FooterGridDirective,
    },
];
