import React from 'react';
import PropTypes from 'prop-types';
import { useWizard } from '../../stores/Wizard.store';
import WizardControls from '../../components/WizardNew/WizardControls';
import { useQuery } from 'react-query';
import WizardTransitioning from '../../components/WizardNew/WizardTransitioning';
import { useSnackbar } from 'notistack';

const dummy = () =>
    new Promise((resolve) =>
        setTimeout(() => resolve({ isFinished: false }), 500)
    );

const WizardControlContainer = (props) => {
    const { children, proposalId } = props;

    const { enqueueSnackbar } = useSnackbar();

    const {
        toStep,
        forward,
        back,
        id,
        setFinished,
        ...wizardComponents
    } = useWizard((state) => ({
        currentStep: state.currentStep,
        isFinished: state.isFinished,
        isTransitioning: state.isTransitioning,
        toStep: state.toStep,
        forward: state.forward,
        back: state.back,
        id: state.id,
        setFinished: state.setFinished,
    }));

    //TODO: ASync function and error handling
    const { isFetching } = useQuery(
        ['wizardStepState', id, wizardComponents.currentStep],
        dummy,
        {
            refetchOnMount: true,
            refetchOnWindowFocus: false,
            onSuccess: (data) => {
                setFinished(data.isFinished);
            },
            onError: () => {},
        }
    );

    const handleForward = React.useCallback(() => {
        forward()
            .then(() => {})
            .catch((err) => {
                enqueueSnackbar(err.message(), { variant: 'error' });
            });
    }, [forward, enqueueSnackbar]);

    const handleBack = React.useCallback(() => {
        back()
            .then(() => {})
            .catch((err) => {
                enqueueSnackbar(err.message(), { variant: 'error' });
            });
    }, [back, enqueueSnackbar]);

    const handleToStep = React.useCallback(
        (to) => {
            toStep(to)
                .then(() => {})
                .catch((err) => {
                    enqueueSnackbar(err.message(), { variant: 'error' });
                });
        },
        [toStep, enqueueSnackbar]
    );

    return (
        <>
            <WizardTransitioning />
            <WizardControls
                {...wizardComponents}
                back={handleBack}
                forward={handleForward}
                toStep={handleToStep}
                verifying={isFetching}
            >
                {React.Children.map(children, (child) => {
                    return React.createElement(child.type, {
                        ...child.props,
                        ...wizardComponents,
                        proposalId,
                    });
                })}
            </WizardControls>
        </>
    );
};

WizardControlContainer.propTypes = {
    children: PropTypes.node,
};

export default WizardControlContainer;
