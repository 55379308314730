import { isObjectEmpty } from '../../../util/Helper/Object';

export function persistMutator(data) {
    let persistData = {
        name: data.name,
        url: data.url,
        legal: data.legal,
        employees: parseInt(data.employees),
        signaturePerson: {
            ...data.signaturePerson,
            phone: data.signaturePerson.phone?.replaceAll(' ', '') || undefined,
            id: data.signaturePerson.id || undefined,
        },
        address: data.address,
        bankAccount: {
            ...data.bankAccount,
            iban: data.bankAccount.iban?.replaceAll(' ', '') || undefined,
            bic: data.bankAccount.bic?.replaceAll(' ', '') || undefined,
        },
        id: data.id || null,
        apprentices: parseInt(data.apprentices),
        ustAbzug: parseInt(data.ustAbzug),
    };
    if (!isObjectEmpty(data.viceSignaturePerson)) {
        persistData.viceSignaturePerson = {
            ...data.viceSignaturePerson,
            phone:
                data.viceSignaturePerson.phone?.replaceAll(' ', '') ||
                undefined,
            id: data.viceSignaturePerson.id || undefined,
        };
    }

    return persistData;
}

export function fetchMutator(data, legal) {
    return {
        id: data.id,
        name: data.name,
        url: data.url,
        legal: data.legal ? data.legal : undefined,
        legalName: legal ? legal.name : undefined,
        employees: '' + data.employees,
        apprentices: '' + data.apprentices,
        bankAccount: { ...data.bankAccount },
        address: data.address,
        hasSubmittedProject: data.hasSubmittedProject,
        signaturePerson: data.signaturePerson,
        viceSignaturePerson: data.viceSignaturePerson,
        hasPendingChangeRequest: data.hasPendingChangeRequest,
        dataUpdateRequired: data.dataUpdateRequired,
        ustAbzug:
            data.ustAbzug !== undefined && data.ustAbzug !== null
                ? '' + data.ustAbzug
                : null,
    };
}
