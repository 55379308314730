export const validatePassword = (
    password,
    message = 'Entspricht nicht der Richtlinie'
) => {
    let check = 0;

    if (password.length < 10) {
        return 'Passwort muss mindestens 10 Zeichen lang sein';
    }

    if (password.match(/[a-z]/g)) {
        check++;
    }

    if (password.match(/[A-Z]/g)) {
        check++;
    }

    if (password.match(/[0-9]/g)) {
        check++;
    }
    if (password.match(/[!@#§$%&()_]/g)) {
        check++;
    }

    return check >= 3 || message;
};

export const verifyPassword = (password) => {
    const hasLength = password.length >= 10;
    const hasUppercase = /[A-Z]/.test(password);
    const hasLowercase = /[a-z]/.test(password);
    const hasSpecial = /[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/.test(password);
    const hasNumber = /[0-9]/.test(password);
    const hasThree =
        [hasUppercase, hasLowercase, hasSpecial, hasNumber].filter(
            (el) => el === true
        ).length >= 3;
    return {
        hasLength,
        hasUppercase,
        hasLowercase,
        hasSpecial,
        hasNumber,
        hasThree,
    };
};
