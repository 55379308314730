import React from 'react';
import PropTypes from 'prop-types';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import { Typography } from '@material-ui/core';

const MobilityDialogError = (props) => {
    const { responseError } = props;

    if (!responseError) return null;

    return (
        <Alert severity="error">
            <AlertTitle>{responseError.title}</AlertTitle>
            {responseError.violations.length > 0 && (
                <Typography component="ul">
                    {responseError.violations.map((violation) => (
                        <Typography component="li" key={violation.key}>
                            {violation.message}
                        </Typography>
                    ))}
                </Typography>
            )}
        </Alert>
    );
};

MobilityDialogError.propTypes = {
    responseError: PropTypes.object,
};

export default MobilityDialogError;
