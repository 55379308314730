import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import CoreDataFormNew from './CoreDataFormNew';
import DialogWithConfirmation from '../../Partials/Dialog/DialogWithConfirmation';
import { coreDataOverviewTexts as cdot } from '../../../texts/components/CoreData/CoreDataOverviewTexts';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { texts } from '../../../texts/GeneralTexts';
import { CircularProgress } from '@material-ui/core';
import { useAuth } from '../../../stores/Auth.store';
import { fetchMutator } from './CoreDataMutators';
import CoreDataFormDialogError from './CoreDataFormDialogError';

const CoreDataFormDialog = (props) => {
    const {
        data,
        handleClose,
        isOpen,
        submitFunc,
        isSaving,
        refetch,
        responseError,
    } = props;

    const [openConfirm, setOpenConfirm] = React.useState(false);
    const [newData, setNewData] = React.useState(data);

    const handleSubmit = () => {
        submitFunc(newData);
    };

    const { coreDataUpdateRequired } = useAuth((state) =>
        fetchMutator(state.user)
    );

    return (
        <>
            <Dialog
                role="main"
                PaperProps={{
                    'aria-label': 'Stammdaten Dialog',
                }}
                onClose={handleClose}
                maxWidth="sm"
                fullWidth
                open={isOpen}
                disableBackdropClick={true}
            >
                <DialogTitle>
                    {coreDataUpdateRequired
                        ? cdot.firstTimeEditCoreData
                        : cdot.editCoreData}
                </DialogTitle>
                <CoreDataFormDialogError responseError={responseError} />
                <DialogContent>
                    <CoreDataFormNew
                        mutate={(editedData) => {
                            setOpenConfirm(!coreDataUpdateRequired);
                            setNewData(editedData);
                            if (coreDataUpdateRequired) {
                                submitFunc(editedData);
                            }
                        }}
                        data={data}
                        refetch={refetch}
                    />
                </DialogContent>
                <DialogActions>
                    {isSaving && (
                        <CircularProgress size="1rem" color="primary" />
                    )}
                    <Button variant="outlined" onClick={handleClose}>
                        {texts.cancel}
                    </Button>
                    <Button
                        type="submit"
                        variant="contained"
                        disableElevation
                        color="primary"
                        form={'mainForm'}
                        disabled={isSaving}
                    >
                        {texts.save}
                    </Button>
                </DialogActions>
            </Dialog>

            <DialogWithConfirmation
                open={openConfirm}
                title={cdot.confirmSave}
                content={cdot.confirmSaveText}
                closeFunc={() => setOpenConfirm(false)}
                confirmFunc={() => {
                    handleSubmit();
                    setOpenConfirm(false);
                }}
                maxWidth={'xs'}
            />
        </>
    );
};

CoreDataFormDialog.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    data: PropTypes.object.isRequired,
    handleClose: PropTypes.func,
    submitFunc: PropTypes.func.isRequired,
    isSaving: PropTypes.bool,
    refetch: PropTypes.func,
    responseError: PropTypes.object,
};

export default CoreDataFormDialog;
