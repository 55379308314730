import React from 'react';
import { Container } from '@material-ui/core';
import NavbarButton from './NavbarButton';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { getUserState, UserState } from '../../../util/Helper/UserState';
import { useAuth } from '../../../stores/Auth.store';
import { pages } from '../../../config/Pages';
import { verifyRoles } from '../../../util/Auth/VerifyRoles';

const useStyles = makeStyles({
    nav: {
        display: 'flex',
        flexDirection: 'row',
        '& button': {
            '&:last-child': {
                marginLeft: 'auto',
            },
        },
    },
});

const AuthNavList = () => {
    const user = useAuth((state) => state.user);
    const classes = useStyles();
    if (!user) return null;
    return (
        <Container className={classes.nav}>
            {pages.map((el, id) => {
                if (el.roles.length > 0 && !verifyRoles(user.roles, el.roles))
                    return null;

                return (
                    <NavbarButton
                        href={el.href}
                        enabled={
                            getUserState(user) === UserState.ACTIVE ||
                            id === 0 ||
                            id === pages.length - 1
                        }
                        title={el.label}
                        key={id}
                        type={el.type}
                    />
                );
            })}
        </Container>
    );
};

export default AuthNavList;
