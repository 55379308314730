import React from 'react';
import PropTypes from 'prop-types';
import TableBody from '@material-ui/core/TableBody';
import MobilityListRowError from './MobilityListRowError';

const MobilityListBody = (props) => {
    const { children, getError } = props;
    const hasError = Boolean(getError);
    return (
        <TableBody role="rowgroup">
            {hasError ? (
                <MobilityListRowError message={getError.message()} />
            ) : (
                children
            )}
        </TableBody>
    );
};

MobilityListBody.propTypes = {
    children: PropTypes.node,
    getError: PropTypes.object,
};

MobilityListBody.defaultProps = {
    getError: undefined,
};

export default MobilityListBody;
