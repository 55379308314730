import React from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { Typography } from '@material-ui/core';
import { mobilityFormTexts as mft } from '../../../../texts/Forms/MobilityFormTexts';
import CircularProgress from '@material-ui/core/CircularProgress';

const MobilityListLoading = (props) => {
    return (
        <TableRow role="row">
            <TableCell role="cell" colSpan={6} aria-colspan={6}>
                <Typography component="span" gutterBottom>
                    {mft.list.empty.loading}
                    <CircularProgress style={{ marginLeft: 8 }} size="1rem" />
                </Typography>
            </TableCell>
        </TableRow>
    );
};

export default MobilityListLoading;
