import React from 'react';
import { Backdrop, CircularProgress, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
    root: {
        backgroundColor: 'rgba(255,255,255,0.5)',
        position: 'absolute',
        top: '0',
        right: '0',
        left: '0',
        bottom: '0',
        color: '#383838',
        zIndex: 999,
    },
});

const FullContentOverlayLoader = ({ loading }) => {
    const classes = useStyles();
    return (
        <Backdrop open={loading} className={classes.root}>
            <CircularProgress color="inherit" />
        </Backdrop>
    );
};

FullContentOverlayLoader.propTypes = {
    loading: PropTypes.bool.isRequired,
};

export default FullContentOverlayLoader;
