import React from 'react';
import PropTypes from 'prop-types';
import { mobilityFormTexts as mft } from '../../../../texts/Forms/MobilityFormTexts';
import TextField from '@material-ui/core/TextField';
import { useMobilityEditStyles } from '../../../../styles/components/forms/MobilityForm/MobilityEditStyles';
import { Typography } from '@material-ui/core';
import Dinero from 'dinero.js';
import Collapse from '@material-ui/core/Collapse';

const MobilityEditSum = (props) => {
    const { disabled, orga, processing, residence, travel } = props;
    const classes = useMobilityEditStyles();

    const sumWithoutOrga = residence
        .add(travel)
        .add(new Dinero({ amount: parseInt(disabled || '0') }));
    const sumOrgaProcessing = orga.add(processing);
    const total = sumWithoutOrga.add(sumOrgaProcessing);

    return (
        <div className={classes.input}>
            <Typography variant="subtitle1" component="h3" gutterBottom>
                {mft.edit.sum}
            </Typography>
            <Collapse in={0 < orga.getAmount()} unmountOnExit>
                <TextField
                    inputProps={{
                        'aria-label': mft.fields.sumWithoutOrga.label,
                    }}
                    value={sumWithoutOrga.toFormat()}
                    fullWidth
                    {...mft.fields.sumWithoutOrga}
                    InputProps={{
                        readOnly: true,
                    }}
                    variant="outlined"
                />
                <TextField
                    inputProps={{
                        'aria-label': mft.fields.sumOrga.label,
                    }}
                    className={classes.sumTextfield}
                    value={sumOrgaProcessing.toFormat()}
                    fullWidth
                    {...mft.fields.sumOrga}
                    InputProps={{ readOnly: true }}
                    variant="outlined"
                />
            </Collapse>
            <TextField
                inputProps={{
                    'aria-label': mft.fields.total.label,
                }}
                className={classes.input}
                value={total.toFormat()}
                fullWidth
                {...mft.fields.total}
                InputProps={{ readOnly: true }}
                variant="outlined"
                color="primary"
            />
        </div>
    );
};

MobilityEditSum.propTypes = {
    travel: PropTypes.object,
    orga: PropTypes.object,
    residence: PropTypes.object,
    processing: PropTypes.object,
    disabled: PropTypes.string,
};

MobilityEditSum.defaultProps = {
    disabled: '',
};

export default React.memo(MobilityEditSum);
