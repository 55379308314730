import TwoColumnFormRow from './TwoColumnFormRow';
import GenericTextField from './GenericTextField';
import React from 'react';
import PropTypes from 'prop-types';
import GenericSelect from './GenericSelect';
import { userSubFormTexts } from '../../../texts/Forms/UserSubFormTexts';

const UserSubForm = (props) => {
    const { config, control, errors, disabled } = props;

    return (
        <>
            <TwoColumnFormRow
                firstColumn={
                    <GenericSelect
                        config={config.salutation}
                        control={control}
                        errors={errors}
                        disabled={disabled}
                    />
                }
                secondColumn={
                    <GenericTextField
                        config={config.title}
                        control={control}
                        errors={errors}
                        disabled={disabled}
                    />
                }
            />
            <TwoColumnFormRow
                firstColumn={
                    <GenericTextField
                        config={config.firstName}
                        control={control}
                        errors={errors}
                        disabled={disabled}
                    />
                }
                secondColumn={
                    <GenericTextField
                        config={config.lastName}
                        control={control}
                        errors={errors}
                        disabled={disabled}
                    />
                }
            />
            <TwoColumnFormRow
                firstColumn={
                    <GenericTextField
                        config={config.email}
                        control={control}
                        errors={errors}
                        disabled={disabled}
                    />
                }
                secondColumn={
                    <GenericTextField
                        config={config.phone}
                        control={control}
                        errors={errors}
                        disabled={disabled}
                        helperText={userSubFormTexts.phoneFormat}
                    />
                }
            />
            <TwoColumnFormRow
                firstColumn={
                    <GenericTextField
                        config={config.func}
                        control={control}
                        errors={errors}
                        disabled={disabled}
                    />
                }
                secondColumn={
                    <GenericTextField
                        config={config.department}
                        control={control}
                        errors={errors}
                        disabled={disabled}
                    />
                }
            />
            <TwoColumnFormRow
                firstColumn={
                    <GenericTextField
                        config={config.street}
                        control={control}
                        errors={errors}
                        disabled={disabled}
                    />
                }
                secondColumn={
                    <GenericTextField
                        config={config.city}
                        control={control}
                        errors={errors}
                        disabled={disabled}
                    />
                }
            />
            <TwoColumnFormRow
                firstColumn={
                    <GenericTextField
                        config={config.postcode}
                        control={control}
                        errors={errors}
                        disabled={disabled}
                    />
                }
                secondColumn={
                    <GenericSelect
                        config={config.state}
                        control={control}
                        errors={errors}
                        disabled={disabled}
                    />
                }
            />
            <input
                type="hidden"
                name={config.id.name}
                ref={control.register()}
            />
        </>
    );
};

UserSubForm.propTypes = {
    config: PropTypes.object.isRequired,
    control: PropTypes.object.isRequired,
    errors: PropTypes.object,
};

UserSubForm.defaultProps = {
    disabled: false,
};

export default UserSubForm;
