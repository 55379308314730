import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import {
    proposalOverviewTexts,
    proposalOverviewTexts as pot,
} from '../../texts/components/ProposalOverview/ProposalOverviewTexts';
import TableBody from '@material-ui/core/TableBody';
import TableRowLoading from '../Partials/Loader/TableRowLoading';
import EmptyRow from './EmptyRow';
import moment from 'moment';
import OverviewTableActions from './OverviewTableActions';
import { Collapse } from '@material-ui/core';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import IconButton from '@material-ui/core/IconButton';
import { useApplicationRounds } from '../../stores/ApplicationRounds.store';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(() => ({
    tableHeader: {
        '& th': {
            fontWeight: 'bold',
        },
    },
}));

const ProposalTable = (props) => {
    const { type, data, isLoading, title } = props;

    const classes = useStyles();
    const [showArchive, setShowArchive] = React.useState(true);
    const [beginDates, setBeginDates] = React.useState([]);
    const [endDates, setEndDates] = React.useState([]);
    const applicationRoundsById = useApplicationRounds(
        (state) => state.applicationRoundsById
    );

    React.useEffect(() => {
        if (data.length > 0) {
            setBeginDates(
                data.map((d) => {
                    let result = '...';
                    if (d.applicationRound) {
                        result = moment(
                            applicationRoundsById[d.applicationRound['@id']]
                                ?.implementationBegin
                        ).format('DD.MM.yyyy');
                    }
                    return result;
                })
            );
            setEndDates(
                data.map((d) => {
                    let result = '...';
                    if (d.applicationRound) {
                        result = moment(
                            applicationRoundsById[d.applicationRound['@id']]
                                ?.implementationEnd
                        ).format('DD.MM.yyyy');
                    }
                    return result;
                })
            );
        }
    }, [applicationRoundsById, setBeginDates, setEndDates, data]);

    const handleShowArchiveClick = () => {
        setShowArchive(!showArchive);
    };

    return (
        <div style={{ marginBottom: 24 }}>
            <Typography variant="h5" component="h1" gutterBottom>
                {title}{' '}
                {type === 'archive' && (
                    <IconButton
                        aria-label={'Bereich auf- oder zuklappen'}
                        onClick={handleShowArchiveClick}
                    >
                        {showArchive ? (
                            <KeyboardArrowUpIcon />
                        ) : (
                            <KeyboardArrowDownIcon />
                        )}
                    </IconButton>
                )}
            </Typography>
            <Collapse in={showArchive}>
                <TableContainer>
                    <Table stickyHeader>
                        <TableHead className={classes.tableHeader}>
                            <TableRow>
                                <TableCell>{pot.overviewTable.fkz}</TableCell>
                                <TableCell>{pot.overviewTable.date}</TableCell>
                                <TableCell>{pot.overviewTable.state}</TableCell>
                                <TableCell>
                                    {pot.overviewTable.action}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {isLoading ? (
                                <TableRowLoading />
                            ) : data.length === 0 ? (
                                <EmptyRow />
                            ) : (
                                data.map((d, id) => (
                                    <TableRow key={`${title}_${d.id}_${id}`}>
                                        <TableCell>{d.projectId}</TableCell>
                                        <TableCell>
                                            {beginDates[id]} - {endDates[id]}
                                        </TableCell>
                                        <TableCell>
                                            {proposalOverviewTexts
                                                .proposalState[d.proposalState]
                                                ? proposalOverviewTexts
                                                      .proposalState[
                                                      d.proposalState
                                                  ]
                                                : proposalOverviewTexts
                                                      .proposalState.DEFAULT}
                                        </TableCell>
                                        <TableCell>
                                            <OverviewTableActions rowData={d} />
                                        </TableCell>
                                    </TableRow>
                                ))
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Collapse>
        </div>
    );
};

ProposalTable.propTypes = {
    type: PropTypes.oneOf(['full', 'archive']),
    title: PropTypes.string,
    data: PropTypes.array,
    isLoading: PropTypes.bool,
};

export default ProposalTable;
