import React from 'react';
import PropTypes from 'prop-types';

const WizardDisplay = (props) => {
    const { unmount, children } = props;

    if (unmount) return null;

    return <div>{children}</div>;
};

WizardDisplay.propTypes = {
    unmount: PropTypes.bool,
    children: PropTypes.node,
};

export default WizardDisplay;
