import React from 'react';
import PropTypes from 'prop-types';
import TableRow from '@material-ui/core/TableRow';
import { TableCell } from '@material-ui/core';

const EmptyRow = (props) => {
    const { colSpan, text } = props;

    return (
        <TableRow>
            <TableCell colSpan={colSpan}>{text}</TableCell>
        </TableRow>
    );
};

EmptyRow.propTypes = {
    colSpan: PropTypes.number,
    text: PropTypes.string,
};

EmptyRow.defaultProps = {
    colSpan: 1000,
    text: 'Kein Eintrag vorhanden',
};

export default EmptyRow;
