import PropTypes from 'prop-types';
import { useMutation, useQuery } from 'react-query';
import { getTeam, lockMember } from '../../lib/api/Team/TeamActions';
import { useSnackbar } from 'notistack';
import { useAuth } from '../../stores/Auth.store';
import { getApplicationRounds } from '../../lib/api/ApplicationRounds/ApplicationsRoundsActions';

const TeamOverviewContainer = (props) => {
    const { render, ...rest } = props;

    const { enqueueSnackbar } = useSnackbar();

    const { company } = useAuth((state) => state.user);

    const { data, error, isFetching, isLoading, refetch } = useQuery(
        'team',
        getTeam(company.id),
        {
            initialData: [],
            refetchOnMount: true,
            refetchOnWindowFocus: false,
            initialStale: true,
        }
    );

    useQuery('applicationRounds', getApplicationRounds, {
        initialData: [],
        initialStale: true,
    });

    const [lock, { isLoading: isLocking }] = useMutation(lockMember, {
        onSuccess: (response) => {
            enqueueSnackbar(response.message(), { variant: 'success' });
        },
        onError: (e) => {
            enqueueSnackbar(e.message(), { variant: 'error' });
        },
    });

    return render({
        data,
        error,
        isFetching,
        isLoading,
        refetch,
        lock,
        isLocking,
        ...rest,
    });
};

TeamOverviewContainer.propTypes = {
    render: PropTypes.func.isRequired,
};

export default TeamOverviewContainer;
