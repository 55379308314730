import { api } from '../AxiosConfig/Config';
import { Response } from '../../../util/DataTypes/Response';
import { rename } from '../../../util/Lodash/Rename';

export function getLegalList() {
    return new Promise((resolve, reject) => {
        api()
            .get('/legals')
            .then((response) => {
                const obj = response.data['hydra:member'];

                obj.map((o) => {
                    rename(o, '@id', 'value');
                    rename(o, 'name', 'label');
                    return o;
                });

                resolve(obj);
            })
            .catch((err) => {
                reject(
                    Response.Error('Fehler beim Laden der Rechtsformen', err)
                );
            });
    });
}
