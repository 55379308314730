export const states = [
    { value: 'Baden-Württemberg', label: 'Baden-Württemberg' },
    { value: 'Bayern', label: 'Bayern' },
    { value: 'Berlin', label: 'Berlin' },
    { value: 'Brandenburg', label: 'Brandenburg' },
    { value: 'Bremen', label: 'Bremen' },
    { value: 'Hamburg', label: 'Hamburg' },
    { value: 'Hessen', label: 'Hessen' },
    { value: 'Mecklenburg-Vorpommern', label: 'Mecklenburg-Vorpommern' },
    { value: 'Niedersachsen', label: 'Niedersachsen' },
    { value: 'Nordrhein-Westfalen', label: 'Nordrhein-Westfalen' },
    { value: 'Rheinland-Pfalz', label: 'Rheinland-Pfalz' },
    { value: 'Saarland', label: 'Saarland' },
    { value: 'Sachsen', label: 'Sachsen' },
    { value: 'Sachsen-Anhalt', label: 'Sachsen-Anhalt' },
    { value: 'Schleswig-Holstein', label: 'Schleswig-Holstein' },
    { value: 'Thüringen', label: 'Thüringen' },
];

export function getStateList() {
    return Promise.resolve(states);
}

export const STAY_TYPES = {
    apprentice: '0',
    instructor: '1',
    preparatoryVisit: '2',
    companion: '3',
};

export const AREA_TYPES = {
    other: '0',
    educationSocial: '1',
    hotelTourism: '2',
    healthcare: '3',
    tech: '4',
    craft: '5',
    media: '6',
    trade: '7',
    laboratory: '8',
    agricultureForestry: '9',
};

export const TRAINING_TYPES = {
    inTraining: '0',
    outTraining: '1',
};

export const STAY_LENGTHS_BY_TYPE = {
    default: {
        min: 2,
        max: 90,
    },
    apprentice: {
        min: 19,
        max: 90,
    },
    instructor: {
        min: 2,
        max: 12,
    },
    preparatoryVisit: {
        min: 2,
        max: 5,
    },
    companion: {
        min: 2,
        max: 90,
    },
};
