import create from 'zustand';
import { steps } from '../config/WizardSteps';
import _ from 'lodash';
import { Response } from '../util/DataTypes/Response';
import { changeWizardStep } from '../lib/api/Wizard/WizardActions';

const initial = {
    id: 'someid',
    currentStep: 0,
    maxSteps: 0,
    buttons: [],
    shouldContinue: false,
    isFinished: false,
    isTransitioning: false,
    isSaving: false,
    proposal: {},
};

const withFunctions = (set, get) => ({
    ...initial,
    setId: (id) => set((state) => (state.id = id)),
    setMaxSteps: (steps) => {
        set((state) => (state.maxSteps = steps.length));
    },
    setButtons: (buttons) => {
        set((state) => (state.buttons = buttons));
    },
    toStep: (step, back = false) =>
        new Promise(async (resolve, reject) => {
            try {
                set((state) => (state.isTransitioning = true));
                const to =
                    get().currentStep > step || back
                        ? `back_to_step_${step}`
                        : `to_step_${step}`;
                await changeWizardStep(get().proposal.id, to);
                set((state) => {
                    state.currentStep = step;
                    state.isTransitioning = false;
                });

                resolve(
                    Response.Success(
                        `Erfolgreich zu "${steps[step].name}" gewechselt`
                    )
                );
            } catch (e) {
                //TODO: Wizard error messages
                set((state) => (state.isTransitioning = false));
                if (get().isFinished) return;
                reject(
                    Response.Error(
                        `Konnte nicht zu "${steps[step].name}" wechseln: <INSERT ERROR HERE>`
                    )
                );
            }
        }),
    back: () => {
        const current = get().currentStep;
        if (current > 0) return get().toStep(current - 1, true);
    },
    forward: () => {
        const current = get().currentStep;
        if (current < steps.length) return get().toStep(current + 1);
    },
    setShouldContinue: (shouldContinue) =>
        set((state) => (state.shouldContinue = shouldContinue)),
    setFinished: (finished) => set((state) => (state.isFinished = finished)),
    setTransitioning: (transitioning) =>
        set((state) => (state.isTransitioning = transitioning)),
    setSaving: (saving) => set((state) => (state.isSaving = saving)),
    init: ({ currentStep, id, ...rest }) =>
        set((state) => {
            state.currentStep = parseInt(currentStep);
            state.id = id;
            state.proposal = { ...rest, id };
        }),
    setStepButton: (buttons, currentStep = null) => {
        let step = currentStep;
        if (step === null) {
            step = get().currentStep;
        }

        set(
            (state) =>
                (state.buttons[step] = _.merge(get().buttons[step], buttons))
        );
    },
    enableContinueButton: () => {
        const step = get().currentStep;
        set(() => {
            get().buttons[step].continue.display = true;
        });
    },
    disableContinueButton: () => {
        const step = get().currentStep;
        set(() => {
            get().buttons[step].continue.display = false;
        });
    },
    setProposal: (proposal) => set((state) => (state.proposal = proposal)),
});

export const useWizard = create(withFunctions);
