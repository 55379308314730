import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import TeamFormContainer from '../../../container/Team/TeamFormContainer';
import TeamMemberDialogRender from './TeamMemberDialogRender';

const TeamMemberDialog = (props) => {
    const { open, handleClose, user, refetch } = props;

    return (
        <Dialog
            role="main"
            PaperProps={{
                'aria-label': 'Team Dialog',
            }}
            open={open}
            onClose={handleClose}
            maxWidth="sm"
            fullWidth
            disableBackdropClick={true}
        >
            <TeamFormContainer
                handleClose={handleClose}
                render={TeamMemberDialogRender}
                user={user}
                refetch={refetch}
            />
        </Dialog>
    );
};

TeamMemberDialog.propTypes = {
    open: PropTypes.bool,
    handleClose: PropTypes.func,
    user: PropTypes.object,
    refetch: PropTypes.func,
};

export default TeamMemberDialog;
