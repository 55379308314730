export const TYPE = {
    SUCCESS: 'success',
    ERROR: 'error',
    WARNING: 'warning',
    INFO: 'info',
};

export class Response {
    constructor(message, data, type) {
        this.m = message;
        this.t = type;
        this.d = data;
    }

    message() {
        return this.m;
    }

    type() {
        return this.t;
    }

    data() {
        return this.d;
    }

    static Success(message, data = null) {
        return new Response(message, data, TYPE.SUCCESS);
    }

    static Error(message, data = null) {
        return new Response(message, data, TYPE.ERROR);
    }

    static Warning(message, data = null) {
        return new Response(message, data, TYPE.WARNING);
    }
}
