import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { texts } from '../../../texts/DashboardTexts';

const DashboardError = (props) => {
    const { title, body, link } = props;

    return (
        <>
            <Typography variant="h3" component="h1" gutterBottom>
                {title}
            </Typography>
            <Typography variant="body1">{body}</Typography>
            {link && (
                <Typography variant="body1">
                    <Link href={link}>{texts.here}</Link> {texts.clickToGoOn}
                </Typography>
            )}
        </>
    );
};

DashboardError.propTypes = {
    title: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
    link: PropTypes.string,
};

export default DashboardError;
