import React from 'react';
import { Route, Switch } from 'react-router-dom';
import NotFound from './ErrorPages/NotFound';
import Layout from '../components/Layout/Layout';
import RegisterValidateContainer from '../container/Register/RegisterValidateContainer';
import DashboardContainer from '../container/Dashboard/DashboardContainer';
import LogoutContainer from '../container/Login/LogoutContainer';
import ProposalRoutes from './Proposal';
import { useAuth } from '../stores/Auth.store';
import PrivateRoute from '../components/PrivateRoute/PrivateRoute';
import TeamRoutes from './Team';
import RegisterFormContainer from '../container/Register/RegisterFormContainer';
import RegisterForm from '../components/Forms/Register/RegisterForm';
import CoreDataRoutes from './CoreData';
import ImprintContainer from '../container/Footer/ImprintContainer';
import DataPrivacyContainer from '../container/Footer/DataPrivacyContainer';

const PageRouter = () => {
    const user = useAuth((state) => state.user);
    return (
        <Layout isAuthenticated={user !== undefined}>
            <Switch>
                <PrivateRoute path="/antrag">
                    <ProposalRoutes />
                </PrivateRoute>
                <PrivateRoute path="/team">
                    <TeamRoutes />
                </PrivateRoute>
                <PrivateRoute path="/stammdaten">
                    <CoreDataRoutes />
                </PrivateRoute>
                <Route path="/abmelden">
                    <LogoutContainer />
                </Route>
                <Route path="/registrieren" exact>
                    <RegisterFormContainer render={RegisterForm} />
                </Route>
                <Route path="/benutzerkonto-bestaetigen/:token">
                    <RegisterValidateContainer />
                </Route>
                <Route path="/impressum" exact>
                    <ImprintContainer />
                </Route>
                <Route path="/datenschutz" exact>
                    <DataPrivacyContainer />
                </Route>
                <Route path="/" exact>
                    <DashboardContainer />
                </Route>
                <Route>
                    <NotFound />
                </Route>
            </Switch>
        </Layout>
    );
};

export default PageRouter;
