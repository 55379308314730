import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import RHFSelect from './RHFSelect';

const GenericSelect = (props) => {
    const { config, control, errors, ...rest } = props;

    return (
        <RHFSelect
            label={config.label}
            control={control}
            name={config.name}
            options={config.options}
            error={_.get(errors, config.name)}
            validation={config.validation}
            {...rest}
        />
    );
};

GenericSelect.propTypes = {
    control: PropTypes.object.isRequired,
    config: PropTypes.object.isRequired,
    errors: PropTypes.object,
};

export default GenericSelect;
