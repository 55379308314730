import React from 'react';
import { Grid } from '@material-ui/core';
import FooterGridElement from './FooterGridElement';

const FooterGrid = ({ footerData }) => {
    return (
        <Grid container spacing={3}>
            {footerData.map((element) => (
                <FooterGridElement
                    key={element.id}
                    element={element}
                    xs={12}
                    sm={4}
                />
            ))}
        </Grid>
    );
};

export default FooterGrid;
