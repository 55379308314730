import React from 'react';
import PropTypes from 'prop-types';
import { teamAddFormConfig } from '../../../config/Forms/TeamAddFormConfig';
import GenericFieldList from '../../Partials/Form/GenericFieldList';

const TeamMemberForm = (props) => {
    const { errors, register, control } = props;

    return (
        <div>
            <input type="hidden" name="id" ref={register()} />
            <GenericFieldList
                control={control}
                config={teamAddFormConfig}
                errors={errors}
            />
        </div>
    );
};

TeamMemberForm.propTypes = {
    register: PropTypes.func,
    errors: PropTypes.object,
    control: PropTypes.object,
};

export default TeamMemberForm;
