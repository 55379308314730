import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { mobilityFormTexts as mft } from '../../../texts/Forms/MobilityFormTexts';
import { Button } from '@material-ui/core';

const MobilityFormError = (props) => {
    const { fetchFunc } = props;

    return (
        <div>
            <Typography variant="h5" component="h2" gutterBottom>
                {mft.error.title}
            </Typography>

            <Typography gutterBottom>{mft.error.text}</Typography>
            <Button onClick={fetchFunc} variant="outlined">
                {mft.error.button}
            </Button>
        </div>
    );
};

MobilityFormError.propTypes = {
    fetchFunc: PropTypes.func.isRequired,
};

export default MobilityFormError;
