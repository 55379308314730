import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    body: {
        marginBottom: '.5rem',
    },
    color: {
        textDecoration: 'underline',
        color: 'white',
    },
});

const FooterGridSupport = (props) => {
    const { headline, body, mail, tel, tel2 } = props;
    const classes = useStyles();

    return (
        <>
            <Typography variant="h5" component="h2" gutterBottom>
                {headline}
            </Typography>
            <Typography variant="body1" className={classes.body}>
                {body.map((line, idx) =>
                    idx !== body.length - 1 ? (
                        <React.Fragment key={`footer_support_line${idx}`}>
                            {line}
                            <br />
                        </React.Fragment>
                    ) : (
                        <React.Fragment key={idx}>{line}</React.Fragment>
                    )
                )}
            </Typography>
            {mail?.link && mail?.text && (
                <Typography variant="body1" className={classes.body}>
                    <Link
                        className={classes.color}
                        variant="body1"
                        href={'mailto:' + mail.link}
                    >
                        {mail.text}
                    </Link>
                </Typography>
            )}
            {tel && (
                <Typography variant="body1" className={classes.body}>
                    {tel}
                </Typography>
            )}
            {tel2 && (
                <Typography variant="body1" className={classes.body}>
                    {tel2}
                </Typography>
            )}
        </>
    );
};

FooterGridSupport.propTypes = {
    headline: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
    mail: PropTypes.string,
    tel: PropTypes.string,
    tel2: PropTypes.string,
};

export default FooterGridSupport;
