import { Response } from '../../../util/DataTypes/Response';
import { api, baseJson } from '../AxiosConfig/Config';

export function downloadFile(link, fromRoot) {
    return new Promise(async (resolve, reject) => {
        try {
            const config = {
                responseType: 'blob',
            };
            let response;
            if (!fromRoot) {
                response = await api().get(link, config);
            } else {
                response = await baseJson().get(link, config);
            }
            resolve(response);
        } catch (e) {
            reject(
                Response.Error(
                    'Beim Download der Datei ist ein Fehler aufgetreten',
                    e
                )
            );
        }
    });
}
