import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { stringToUri } from '../../../util/Helper/StringToUri';
import CreateLink from '../../Partials/CreateLink';

const useStyles = makeStyles({
    body: {
        marginBottom: '.5rem',
    },
});

const FooterGridDirective = (props) => {
    const { headline, rows, uris } = props;
    const classes = useStyles();

    const splitData = rows.map((element) => {
        return stringToUri(element);
    });
    const uri = _.cloneDeep(uris);

    return (
        <>
            <Typography variant="h5" component="h2" gutterBottom>
                {headline}
            </Typography>
            {splitData.map((string, index) => {
                return (
                    <Typography
                        key={index}
                        variant="body1"
                        className={classes.body}
                    >
                        {string.map((element) =>
                            element.startsWith('{%')
                                ? CreateLink({
                                      string: element,
                                      uris: uri,
                                      target: '_blank',
                                  })
                                : element
                        )}
                    </Typography>
                );
            })}
        </>
    );
};

FooterGridDirective.propTypes = {
    headline: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
    uris: PropTypes.array,
};

export default FooterGridDirective;
