import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import TextBlock from '../../TextBlock/TextBlock';

const DashboardArticle = (props) => {
    const { title, rows, signature } = props;

    return (
        <>
            <Typography
                variant="h6"
                component="h2"
                style={{ marginBottom: '1.4rem' }}
            >
                {title}
            </Typography>
            <TextBlock textBlock={rows} />
            <TextBlock textBlock={signature} />
        </>
    );
};

DashboardArticle.propTypes = {
    title: PropTypes.string.isRequired,
};

export default DashboardArticle;
