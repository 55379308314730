export function addCentAfterSplit(arr) {
    let result = `${arr[0]}00`;

    if (arr.length > 1) {
        switch (arr[1].length) {
            case 0:
                result = `${arr[0]}00`;
                break;
            case 1:
                result = `${arr[0]}${arr[1]}0`;
                break;
            case 2:
                result = `${arr[0]}${arr[1]}`;
                break;
            default:
                result = `${arr[0]}${arr[1].slice(0, 2)}`;
                break;
        }
    }
    return '' + parseInt(result);
}

export function euroToCentString(string) {
    if (!string || string === '') {
        return '0';
    }
    const arr = string.replace(/\./g, '').replace(/€/g, '').trim().split(',');
    return addCentAfterSplit(arr);
}

export function euroToCentInt(string) {
    if (!string) {
        return 0;
    }
    return parseInt(euroToCentString(string));
}

export function removeEurSymbol(string) {
    return string.replace('.', '').replace('€', '').trim().replace(',', '.');
}

export function parseToCentString(value = '000') {
    let result = value;
    if (value !== '0.00') {
        result = value.replace(/\./g, '').replace(/^0+/, '');
    }
    return result;
}

export function parseCentToString(value = '000') {
    if (!value) return value;
    return value.slice(0, value.length - 2) + '.' + value.slice(-2);
}
