import { QueryCache } from 'react-query';
import { useAuth } from './stores/Auth.store';

export const queryCache = new QueryCache({
    defaultConfig: {
        queries: {
            retry: (failureCount, error) => {
                const { getState } = useAuth;
                const { user } = getState();
                if (user === undefined) {
                    queryCache.removeQueries();
                    return false;
                }
                return failureCount < 3;
            },
        },
    },
});
