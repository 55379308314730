import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import { teamTexts } from '../../../texts/components/Team/TeamTexts';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { texts } from '../../../texts/GeneralTexts';
import RHFSelect from '../../Partials/Form/RHFSelect';
import { useForm } from 'react-hook-form';
import { queryCache } from '../../../QueryCache';
import { useMutation } from 'react-query';
import { editUserApplicationRounds } from '../../../lib/api/Team/TeamActions';
import { useSnackbar } from 'notistack';
import DialogWithConfirmation from '../../Partials/Dialog/DialogWithConfirmation';
import _ from 'lodash';

const TeamMemberApplicationRoundEditDialog = (props) => {
    const { open, handleClose, user } = props;

    const [openConfirm, setOpenConfirm] = React.useState(false);
    const [newApplicationRounds, setNewApplicationRounds] = React.useState([]);
    const [contactConflicts, setContactConflicts] = React.useState([]);

    const { handleSubmit, errors, control } = useForm({
        defaultValues: user,
    });

    const applicationRounds = queryCache.getQueryData('applicationRounds');
    const applicationRoundsById = _.keyBy(applicationRounds, '@id');
    const team = queryCache.getQueryData('team');

    const { enqueueSnackbar } = useSnackbar();

    const getContactConflicts = (newApplicationRounds) => {
        return team
            .filter(({ id }) => id !== user.id)
            .map((member) => ({
                name: member.fullName,
                rounds: _.intersection(
                    member.applicationRounds,
                    newApplicationRounds
                ).map((round) => applicationRoundsById[round].label),
            }))
            .filter((potentialConflict) => potentialConflict.rounds.length)
            .map(
                (conflict) => `${conflict.name}: ${conflict.rounds.join(', ')}`
            );
    };

    const [editApplicationRounds] = useMutation(editUserApplicationRounds, {
        onSuccess: (response) => {
            enqueueSnackbar(response.message(), { variant: 'success' });
            handleClose();
        },
        onError: (e) => {
            enqueueSnackbar(e.message(), { variant: 'error' });
        },
    });

    const persist = (data) => {
        editApplicationRounds({
            ...user,
            applicationRounds: data?.applicationRounds || newApplicationRounds,
        });
    };

    const handleFormSubmit = (data) => {
        const conflicts = getContactConflicts(data.applicationRounds);

        if (!conflicts.length) {
            return persist(data);
        }

        setNewApplicationRounds(data.applicationRounds);
        setOpenConfirm(true);
        setContactConflicts(conflicts);
    };

    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth="sm"
                fullWidth
                role="main"
                PaperProps={{
                    'aria-label': 'Antragsrunden zuordnen Dialog',
                }}
            >
                <form onSubmit={handleSubmit(handleFormSubmit)} noValidate>
                    <DialogTitle>{teamTexts.editApplicationRound}</DialogTitle>
                    <DialogContent>
                        <RHFSelect
                            label={'Antragsrunde (Mehrfachauswahl möglich)'}
                            control={control}
                            name={'applicationRounds'}
                            options={applicationRounds}
                            error={errors.applicationRounds}
                            multiple={true}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" onClick={handleClose}>
                            {texts.cancel}
                        </Button>
                        <Button
                            type="submit"
                            variant="contained"
                            disableElevation
                            color="primary"
                        >
                            {texts.save}
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>

            <DialogWithConfirmation
                closeFunc={handleClose}
                content={
                    <ol>
                        {contactConflicts.map((conflict, i) => (
                            <li key={i}>{conflict}</li>
                        ))}
                    </ol>
                }
                title={teamTexts.confirmNewContactPerson}
                open={openConfirm}
                confirmFunc={persist}
            />
        </>
    );
};

TeamMemberApplicationRoundEditDialog.propTypes = {
    open: PropTypes.bool,
    handleClose: PropTypes.func,
    submitCallback: PropTypes.func,
    user: PropTypes.object,
};

export default TeamMemberApplicationRoundEditDialog;
