import React from 'react';
import PropTypes from 'prop-types';
import DialogTitle from '@material-ui/core/DialogTitle';
import { teamTexts as tt } from '../../../texts/components/Team/TeamTexts';
import { texts } from '../../../texts/GeneralTexts';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { useForm } from 'react-hook-form';
import TeamMemberForm from './TeamMemberForm';
import CircularProgress from '@material-ui/core/CircularProgress';
import TeamMemberError from './TeamMemberError';

const TeamMemberDialogRender = (props) => {
    const { handleClose, user, create, createStatus, refetch } = props;
    const { error: createError, isLoading: createIsLoading } = createStatus;

    const { handleSubmit, register, errors, control } = useForm({
        defaultValues: user,
    });

    const isNew = !Boolean(user);

    const submitCallback = async (users) => {
        await create({ ...users, isNew });
        refetch();
    };

    return (
        <form onSubmit={handleSubmit(submitCallback)} noValidate>
            <DialogTitle>
                {isNew ? tt.addDialog.titleAdd : tt.addDialog.titleEdit}
            </DialogTitle>
            <DialogContent>
                <TeamMemberError error={createError} />
                <TeamMemberForm
                    register={register}
                    errors={errors}
                    control={control}
                />
            </DialogContent>
            <DialogActions>
                {createIsLoading && <CircularProgress size="1rem" />}
                <Button
                    variant="outlined"
                    onClick={handleClose}
                    disabled={createIsLoading}
                >
                    {texts.cancel}
                </Button>
                <Button
                    type="submit"
                    variant="contained"
                    disableElevation
                    color="primary"
                    disabled={createIsLoading}
                >
                    {texts.save}
                </Button>
            </DialogActions>
        </form>
    );
};

TeamMemberDialogRender.propTypes = {
    handleClose: PropTypes.func,
    user: PropTypes.object,
    mutate: PropTypes.func,
    isLoading: PropTypes.func,
    error: PropTypes.object,
    refetch: PropTypes.func,
};
export default TeamMemberDialogRender;
