import moment from 'moment';

export const texts = {
    login: 'Anmelden',
    logout: 'Abmelden',
    cancel: 'Abbrechen',
    save: 'Speichern',
    saveAndNext: 'Speichern & Weiter',
    next: 'Weiter',
    send: 'Abschicken',
    actions: 'Aktionen',
    firstName: 'Vorname',
    lastName: 'Nachname',
    email: 'E-Mail',
    role: 'Rolle',
    phone: 'Telefon',
    fax: 'Fax',
    assigned: 'Zugewiesen',
    applicationRound: 'Antragsrunde',
    none: 'keine',
    block: 'Zugang deaktivieren',
    unblock: 'Zugang aktivieren',
    error: 'Fehler',
    retry: 'Nochmal versuchen',
    register: 'Registrieren',
    dashboard: 'Startseite',
    round: (round) => {
        if (round) {
            return `nächste Antragsfrist: ${moment(round.end).format(
                'DD.MM.yyyy'
            )}, 13 Uhr mittags`;
        } else {
            return 'aktuell findet keine Antragsrunde statt';
        }
    },
    awwAcronym: 'AWW',
    close: 'Schließen',
    reload: 'Neuladen',
    back: 'Zurück',
    turnIn: 'Einreichen',
    edit: 'Bearbeiten',
    actionSuccessful: 'Aktion erfolgreich ausgeführt',
    actionError: 'Aktion fehlgeschlagen',
};
