export const dataFilter = (searchTerm, data, fieldsToSearch) => {
    const s = searchTerm.toLowerCase();
    return s === ''
        ? data
        : data.filter((d) => {
              return fieldsToSearch.reduce(
                  (acc, newField) =>
                      d[newField].toLowerCase(s).indexOf(s) >= 0 || acc,
                  false
              );
          });
};
