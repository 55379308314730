import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import CheckboxWithInfoText from '../../Partials/Form/CheckboxWithInfoText';
import { finalizationFormTexts } from '../../../texts/Forms/FinalizationFormTexts';
import makeStyles from '@material-ui/core/styles/makeStyles';
import FinalizationProjectPdf from '../../../container/Forms/Finalization/FinalizationProjectPdf';
import Typography from '@material-ui/core/Typography';
import { Link as RouterLink, useParams } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import { useWizard } from '../../../stores/Wizard.store';
import { useHistory } from 'react-router';
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles({
    blockMargins: {
        marginTop: '1rem',
        marginBottom: '1.25rem',
    },
});

const FinalizationForm = (props) => {
    const { save, defaultValues, error } = props;

    const classes = useStyles();
    const history = useHistory();

    const { proposalId } = useParams();
    const setFinished = useWizard((state) => state.setFinished);

    const { handleSubmit, setError, errors, register } = useForm({
        defaultValues,
    });

    useEffect(() => {
        if (error) {
            const violations = error.d?.response?.data?.violations;
            if (violations) {
                violations.forEach((violation) => {
                    setError(violation.propertyPath, {
                        type: 'manual',
                        message: violation.message,
                    });
                });
            }
        }
    }, [error, setError]);

    const onCompleteCallback = () => {};

    const submitCallback = (values) => {
        save({ proposalId, ...values }).then(() => {
            setFinished(true);
            history.push('/antrag');
        });
    };

    const shouldEnableFinalization = Boolean(
        defaultValues.company &&
            defaultValues.company?.signaturePerson &&
            !defaultValues.company?.hasPendingChangeRequest &&
            !defaultValues.company?.dataUpdateRequired
    );

    const enableContinueButton = useWizard(
        (state) => state.enableContinueButton
    );
    const disableContinueButton = useWizard(
        (state) => state.disableContinueButton
    );
    useEffect(() => {
        if (!defaultValues) {
            return;
        }
        if (shouldEnableFinalization) {
            enableContinueButton();
        } else {
            disableContinueButton();
        }
    }, [
        defaultValues,
        shouldEnableFinalization,
        enableContinueButton,
        disableContinueButton,
    ]);

    return (
        <form id="mainForm" onSubmit={handleSubmit(submitCallback)} noValidate>
            {shouldEnableFinalization && (
                <>
                    <div className={classes.blockMargins}>
                        <Typography gutterBottom>
                            {finalizationFormTexts.info}
                        </Typography>
                    </div>
                    <div className={classes.blockMargins}>
                        <FinalizationProjectPdf
                            onComplete={onCompleteCallback}
                            data={defaultValues}
                        />
                    </div>
                    <div className={classes.blockMargins}>
                        <Typography gutterBottom>
                            {finalizationFormTexts.info2}
                        </Typography>
                        <CheckboxWithInfoText
                            register={register}
                            validation={{
                                required:
                                    finalizationFormTexts.isVerified.error,
                            }}
                            errors={errors.isVerified}
                            label={finalizationFormTexts.isVerified.label}
                            defaultChecked={defaultValues.isVerified}
                            name="isVerified"
                        />
                    </div>
                </>
            )}
            {!shouldEnableFinalization && (
                <div>
                    <Alert severity="warning">
                        {defaultValues.company?.hasPendingChangeRequest && (
                            <>
                                <div>{finalizationFormTexts.changeRequest}</div>
                            </>
                        )}
                        {(!defaultValues.company?.signaturePerson ||
                            defaultValues.company?.dataUpdateRequired) && (
                            <>
                                <div style={{ display: 'inline' }}>
                                    {finalizationFormTexts.coreDataNotComplete}
                                </div>
                                <Link
                                    component={RouterLink}
                                    to="/stammdaten/edit"
                                >
                                    hier
                                </Link>
                                .
                            </>
                        )}
                        <div>{finalizationFormTexts.changeRequestSupport}</div>
                    </Alert>
                </div>
            )}
        </form>
    );
};

FinalizationForm.propTypes = {
    save: PropTypes.func.isRequired,
    defaultValues: PropTypes.object.isRequired,
};

FinalizationForm.defaultProps = {
    defaultValues: {},
};

export default FinalizationForm;
