import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { useWizard } from '../../stores/Wizard.store';
import { useWizardFormButtonsStyles } from '../../styles/components/Wizard/WizardFormButtonsStyles';
import CircularProgress from '@material-ui/core/CircularProgress';
import WizardContinueButton from './WizardContinueButton';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';

const WizardButtons = (props) => {
    const { currentStep } = props;
    const classes = useWizardFormButtonsStyles();

    const { button, setShouldContinue, isSaving, isTransitioning } = useWizard(
        React.useCallback(
            (state) => ({
                button: state.buttons[currentStep],
                setShouldContinue: state.setShouldContinue,
                isSaving: state.isSaving,
                isTransitioning: state.isTransitioning,
            }),
            [currentStep]
        )
    );

    const handleSaveAndContinue = () => {
        setShouldContinue(true);
    };

    const saveButtonDisabled = React.useMemo(() => {
        return button?.save.disabled || isSaving || isTransitioning;
    }, [button, isSaving, isTransitioning]);

    const continueButtonDisabled = React.useMemo(() => {
        return button?.continue.disabled || isSaving || isTransitioning;
    }, [button, isSaving, isTransitioning]);

    if (!button) return null;

    return (
        <div className={classes.root}>
            {button.link && (
                <Link component={RouterLink} to={button.link.href}>
                    <Button variant="outlined" type="button">
                        {button.link.text}
                    </Button>
                </Link>
            )}
            {button.save.display && (
                <Button
                    variant="outlined"
                    form="mainForm"
                    type="submit"
                    disabled={saveButtonDisabled}
                >
                    {button.save.text}
                </Button>
            )}
            <div style={{ position: 'relative' }}>
                {button.continue.display && (
                    <WizardContinueButton
                        func={handleSaveAndContinue}
                        disabled={continueButtonDisabled}
                        className={
                            button.save.display || button.link
                                ? classes.spacing
                                : undefined
                        }
                        confirm={button.continue.confirm}
                    >
                        {button.continue.text}
                    </WizardContinueButton>
                )}
                {isSaving && (
                    <div className={classes.saving}>
                        <CircularProgress size={24} />
                    </div>
                )}
            </div>
        </div>
    );
};

WizardButtons.propTypes = {
    currentStep: PropTypes.number,
};

export default WizardButtons;
