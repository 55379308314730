import React from 'react';
import { Switch } from 'react-router-dom';
import ProposalListContainer from '../../container/Proposal/ProposalListContainer';
import WizardView from '../../views/Wizard/WizardView';
import RoleRoute from '../../components/PrivateRoute/RoleRoute';
import ProposalOverview from '../../components/ProposalOverview/ProposalOverview';

const ProposalRoutes = () => {
    return (
        <Switch>
            <RoleRoute
                roles={['ROLE_MANAGER', 'ROLE_CONTACT']}
                path="/antrag"
                exact
            >
                <ProposalListContainer render={ProposalOverview} />
            </RoleRoute>
            <RoleRoute
                roles={['ROLE_MANAGER', 'ROLE_CONTACT']}
                path="/antrag/bearbeiten/:proposalId"
            >
                <WizardView />
            </RoleRoute>
        </Switch>
    );
};

export default ProposalRoutes;
