import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import RHFSelect from './RHFSelect';
import { useQuery } from 'react-query';

const AsyncSelect = (props) => {
    const { config, control, errors, ...rest } = props;
    const { data, isFetching, isLoading } = useQuery(
        `${config.name}_select`,
        config.options,
        {
            initialData: [],
            initialStale: true,
            refetchOnWindowFocus: false,
        }
    );

    return (
        <RHFSelect
            label={config.label}
            control={control}
            name={config.name}
            options={isFetching || isLoading ? [] : data}
            error={_.get(errors, config.name)}
            validation={config.validation}
            {...rest}
        />
    );
};

AsyncSelect.propTypes = {
    control: PropTypes.object.isRequired,
    config: PropTypes.object.isRequired,
    errors: PropTypes.object,
};

export default AsyncSelect;
