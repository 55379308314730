import { Response } from '../../../util/DataTypes/Response';
import { getMutator, mutateMobility, saveMutator } from './MobilityMutators';
import {
    buildResponseError,
    handleError,
} from '../../../util/Error/HandleError';
import { api } from '../AxiosConfig/Config';

const fakeAsync = (data = undefined) =>
    new Promise((resolve) =>
        setTimeout(() => {
            resolve(data);
        }, 1000)
    );

let countries = [];

export function fetchMobilityData(proposalId) {
    return function () {
        return new Promise(async (resolve, reject) => {
            try {
                const { data } = await api().get('/financial_plans');
                countries = data['hydra:member']
                    .map((el) => ({
                        ...el,
                        label: el.country,
                        value: el['@id'],
                    }))
                    .sort((a, b) => a.order - b.order);
                resolve({
                    list: countries,
                    allowance: {
                        processing: 15000,
                        orga: 25000,
                    },
                });
            } catch (e) {
                reject(
                    handleError(e, {
                        generic:
                            'Beim Laden der benötigten Daten ist ein Fehler aufgetreten.',
                        401: 'Sie haben nicht die Berechtigung, die Formulardaten zu laden.',
                        403: 'Sie haben nicht die Erlaubnis, die Formulardaten zu laden.',
                        404: 'Die benötigten Formulardaten können nicht gefunden werden.',
                        500: 'Beim Versuch die Formulardaten zu laden ist ein Fehler aufgetreten.',
                        502: 'Beim Laden der Formulardaten gab es eine Zeitüberschreitung',
                        503: 'Beim Laden der Formulardaten gab es eine Zeitüberschreitung',
                        504: 'Beim Laden der Formulardaten gab es eine Zeitüberschreitung',
                    })
                );
            }
        });
    };
}

export function persistMobilityData(proposalId, data) {
    return new Promise(async (resolve, reject) => {
        await fakeAsync();
        resolve(Response.Success('Die Daten wurden gespeichert'));
    });
}

export function getMobilities(proposalId) {
    return function () {
        return new Promise(async (resolve, reject) => {
            try {
                const { data } = await api().get(
                    `/projects/${proposalId}/mobilities`
                );
                resolve(getMutator(data['hydra:member'], countries));
            } catch (e) {
                reject(
                    handleError(e, {
                        generic: 'Konnte keine Mobilitäten laden.',
                        401: 'Sie haben nicht die Berechtigung die Mobilitäten zu laden.',
                        403: 'Sie haben nicht die Erlaubnis die Mobilitäten zu laden.',
                        404: 'Es konnten keine Mobilitäten gefunden werden.',
                        500: 'Beim Laden der Mobilitäten ist ein Fehler aufgetreten.',
                        502: 'Beim Laden der Mobilitäten gab es eine Zeitüberschreitung.',
                        503: 'Beim Laden der Mobilitäten gab es eine Zeitüberschreitung.',
                        504: 'Beim Laden der Mobilitäten gab es eine Zeitüberschreitung.',
                    })
                );
            }
        });
    };
}

export function deleteMobility({ iri, index }) {
    return new Promise(async (resolve, reject) => {
        try {
            await api().delete(iri.slice('/api'.length));
            resolve({ message: 'Deleted', index, iri });
        } catch (e) {
            reject(
                handleError(e, {
                    generic: 'Die Mobilität konnte nicht entfernt werden.',
                    401: 'Sie haben nicht die Berechtigung die Mobilität zu entfernen.',
                    403: 'Sie haben nicht die Erlaubnis die Mobilität zu entfernen.',
                    404: 'Die zu entfernende Mobilität konnte nicht gefunden werden.',
                    500: 'Beim Entfernen der Mobilität ist ein Fehler aufgetreten.',
                    502: 'Beim Entfernen der Mobilität gab es eine Zeitüberschreitung',
                    503: 'Beim Entfernen der Mobilität gab es eine Zeitüberschreitung',
                    504: 'Beim Entfernen der Mobilität gab es eine Zeitüberschreitung',
                })
            );
        }
    });
}

export function putMobility({ values }) {
    return new Promise(async (resolve, reject) => {
        try {
            const { data } = await api().put(
                values['@id'].slice('/api'.length),
                saveMutator(values)
            );

            resolve({
                type: 'put',
                destination: values.financialPlan.label,
                newData: {
                    ...mutateMobility(data),
                    financialPlan: {
                        ...countries.find(
                            (country) => country['@id'] === data.financialPlan
                        ),
                    },
                },
            });
        } catch (e) {
            reject(
                buildResponseError(e, {
                    generic: 'Beim Speichern ist ein Fehler aufgetreten',
                })
            );
        }
    });
}

export function createMobility({ values, proposalId }) {
    return new Promise(async (resolve, reject) => {
        try {
            const destination = values.financialPlan.label;
            const { data } = await api().post('/mobilities', {
                ...saveMutator(values),
                project: `/api/projects/${proposalId}`,
            });
            resolve({
                type: 'post',
                destination: destination,
                newData: {
                    ...mutateMobility(data),
                    financialPlan: {
                        ...countries.find(
                            (country) => country['@id'] === data.financialPlan
                        ),
                    },
                },
            });
        } catch (e) {
            reject(
                buildResponseError(e, {
                    generic: 'Beim Speichern ist ein Fehler aufgetreten',
                })
            );
        }
    });
}
