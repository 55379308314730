import React from 'react';
import PropTypes from 'prop-types';
import { steps } from '../../config/WizardSteps';
import WizardDisplay from './WizardDisplay';
import FormContainer from '../../container/WizardNew/FormContainer';
import { Typography } from '@material-ui/core';

const WizardForms = (props) => {
    const { currentStep, proposalId } = props;
    return (
        <>
            {steps.map((step, id) => (
                <WizardDisplay
                    unmount={currentStep !== id}
                    key={step.name + '_' + id}
                >
                    <Typography variant="h4" component="h1" gutterBottom>
                        {step.name}
                    </Typography>
                    <FormContainer
                        component={step.component}
                        saveFunc={step.saveFunc}
                        proposalId={proposalId}
                    />
                </WizardDisplay>
            ))}
        </>
    );
};

WizardForms.propTypes = {
    currentStep: PropTypes.number,
    proposalId: PropTypes.string,
};

export default WizardForms;
