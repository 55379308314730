import makeStyles from '@material-ui/core/styles/makeStyles';

export const useLoginButtonStyles = makeStyles(() => ({
    root: {
        color: 'white',
        padding: '0.5rem 1rem',
        marginRight: '1rem',
        '&:last-child': {
            marginRight: '0',
        },
        '&:hover': {
            backgroundColor: '#FFFFFF',
            color: '#004F80',
        },
        '&:active': {
            backgroundColor: '#FFFFFF',
            color: '#004F80',
        },
    },
}));
