import makeStyles from '@material-ui/core/styles/makeStyles';

export const useWizardTransitioningStyles = makeStyles((theme) => ({
    progress: {
        position: 'absolute',
        left: 0,
        right: 0,
    },
    top: {
        top: 0,
    },
    bottom: {
        bottom: 0,
    },
    hidden: {
        display: 'none',
    },
}));
