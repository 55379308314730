import { api } from '../AxiosConfig/Config';
import { handleError } from '../../../util/Error/HandleError';

export function changeWizardStep(proposalId, to) {
    return new Promise(async (resolve, reject) => {
        try {
            await api().put(`/action/project/${proposalId}/apply-transition`, {
                transition: to,
            });
            resolve();
        } catch (e) {
            reject(
                'Es ist ein Fehler aufgetreten, bitte kontrollieren Sie Ihre Formulareingaben.'
            );
        }
    });
}

//TODO: Error messages
export function isStepComplete(step, proposalId) {
    return new Promise(async (resolve, reject) => {
        try {
            const { data } = await api().get(
                `/action/project/${proposalId}/wizard-state`
            );
            const stepString = `to_step_${parseInt(step) + 1}`;
            resolve(
                data.enabledTransitions.findIndex(
                    (transition) => transition.name === stepString
                ) >= 0
            );
        } catch (e) {
            reject(
                handleError(e, {
                    generic: 'Überprüfung fehlgeschlagen',
                })
            );
        }
    });
}

/*
export function getWizardState(proposalId) {
    return function () {
        return new Promise(async (resolve, reject) => {
            try {
                const { data } = await api().get(
                    `/action/proposal/${proposalId}/wizard-state`
                );
                resolve(getWizardStateMutator(data));
            } catch (e) {
                reject(
                    Response.Error(
                        'Der Aktuelle Stand des Antrags konnte nicht geladen werden',
                        e
                    )
                );
            }
        });
    };
}

export function wizardTransition(proposalId, toStep) {
    return new Promise(async (resolve, reject) => {
        try {
            await api().put(`/action/proposal/${proposalId}/apply-transition`, {
                transition: `${toStep}`,
            });
            resolve(Response.Success('Wizardstep erfolgreich gewechselt'));
        } catch (e) {
            reject(
                Response.Error(
                    'Der Wizardstep konnte nicht gewechselt werden',
                    e
                )
            );
        }
    });
}

export function isStepComplete(proposalId, step) {
    return new Promise(async (resolve, reject) => {
        try {
            const { data } = await api().get(
                `/action/proposal/${proposalId}/wizard-state`
            );

            if (
                0 <=
                data.enabledTransitions.findIndex(
                    (el) => el.name === `to_step_${step + 1}`
                )
            ) {
                resolve(true);
            }

            resolve(false);
        } catch (e) {
            reject(Response.Error('Fehler beim laden der Information', e));
        }
    });
}

export function isStepCompleteNew(proposalId, step) {
    return function () {
        return new Promise(async (resolve, reject) => {
            try {
                const { data } = await api().get(
                    `/action/proposal/${proposalId}/wizard-state`
                );

                if (
                    0 <=
                    data.enabledTransitions.findIndex(
                        (el) => el.name === `to_step_${step + 1}`
                    )
                ) {
                    resolve(true);
                }

                resolve(false);
            } catch (e) {
                reject(Response.Error('Fehler beim laden der Information', e));
            }
        });
    };
}
*/
