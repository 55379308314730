import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import { DialogContentText } from '@material-ui/core';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { Alert } from '@material-ui/lab';

const WizardContinueButton = (props) => {
    const { confirm, func, children, disabled, ...rest } = props;

    const [open, setOpen] = React.useState(false);

    const handleClick = () => {
        if (Boolean(confirm)) {
            setOpen(true);
            return;
        }
        func();
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleAccept = () => {
        func();
        handleClose();
    };

    return (
        <>
            <Button
                {...rest}
                variant="contained"
                disableElevation
                color="primary"
                form="mainForm"
                type={!Boolean(confirm) ? 'submit' : undefined}
                onClick={handleClick}
                disabled={disabled}
            >
                {children}
            </Button>
            {Boolean(confirm) && (
                <Dialog
                    role="main"
                    PaperProps={{
                        'aria-label': confirm.title,
                    }}
                    open={open}
                    onClose={handleClose}
                >
                    <DialogTitle>{confirm.title}</DialogTitle>
                    <DialogContent>
                        <DialogContentText color="textPrimary">
                            {confirm.body}
                        </DialogContentText>
                        {confirm.info && (
                            <Alert severity="info">{confirm.info}</Alert>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" onClick={handleClose}>
                            {confirm.cancel}
                        </Button>
                        <Button
                            variant="contained"
                            disableElevation
                            onClick={handleAccept}
                            type="submit"
                            form="mainForm"
                            color="primary"
                        >
                            {confirm.accept}
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </>
    );
};

WizardContinueButton.propTypes = {
    func: PropTypes.func.isRequired,
    confirm: PropTypes.object,
    disabled: PropTypes.bool,
    className: PropTypes.string,
    children: PropTypes.node,
};

WizardContinueButton.defaultProps = {
    confirm: undefined,
};

export default WizardContinueButton;
