import moment from 'moment';
import { mobilityFormTexts } from '../../texts/Forms/MobilityFormTexts';
import Dinero from 'dinero.js';
import { diffDuration } from '../Validation/ValidateMobilityDuration';
import { STAY_LENGTHS_BY_TYPE, STAY_TYPES } from '../constants/States';

const TRAINEE_DURATION_SHORT = 21;
const TRAINEE_DURATION_LONG = 90;

export function calculateTraineeStay(days, amountShort, amountLong) {
    if (days <= TRAINEE_DURATION_SHORT) {
        return days * parseInt(amountShort);
    }

    return (
        TRAINEE_DURATION_SHORT * parseInt(amountShort) +
        (days > TRAINEE_DURATION_LONG
            ? TRAINEE_DURATION_LONG - TRAINEE_DURATION_SHORT
            : days - TRAINEE_DURATION_SHORT) *
            parseInt(amountLong)
    );
}

export function getProposalDuration(currentApplicationRound) {
    return {
        start: moment(currentApplicationRound.implementationBegin).add(1, 'd'),
        end: moment(currentApplicationRound.implementationEnd).subtract(1, 'd'),
    };
}

export function calculateTravelAllowance({ attendees, financialPlan }) {
    if (!attendees) return Dinero();
    if (!financialPlan) return Dinero();
    let calculation = parseInt(attendees) * parseInt(financialPlan.travelUnit);
    if (calculation < 0) {
        calculation = 0;
    }
    return Dinero({
        amount: calculation,
    });
}

export function calculateResidenceAllowance({
    attendees,
    financialPlan,
    start,
    end,
    type,
}) {
    if (!attendees) return Dinero();
    if (!financialPlan) return Dinero();

    let duration = {
        start,
        end,
    };

    const days = diffDuration(duration);
    if (!days) return Dinero();

    const stay =
        type !== STAY_TYPES.instructor
            ? calculateTraineeStay(
                  days,
                  financialPlan.traineeShortUnit,
                  financialPlan.traineeLongUnit
              )
            : days <= STAY_LENGTHS_BY_TYPE.instructor.max
            ? days * parseInt(financialPlan.trainerUnit)
            : STAY_LENGTHS_BY_TYPE.instructor.max *
              parseInt(financialPlan.trainerUnit);

    let calculation = attendees * stay;
    if (calculation < 0) {
        calculation = 0;
    }

    return Dinero({ amount: calculation });
}

export function calculateOrgaAllowance({ attendees, type }, allowance) {
    if (!attendees) return Dinero();
    if (!type || type === STAY_TYPES.companion) return Dinero();

    let calculation = parseInt(attendees) * allowance;
    if (calculation < 0) {
        calculation = 0;
    }

    return Dinero({ amount: calculation });
}

export function calculatePrePostAllowance({ attendees }, allowance) {
    if (!attendees) return Dinero();

    let calculation = parseInt(attendees) * allowance;
    if (calculation < 0) {
        calculation = 0;
    }

    return Dinero({ amount: calculation });
}

export function calculateSumWithoutOrga(data, allowance) {
    const travel =
        data[mobilityFormTexts.fields.reqTravelAllowance.name] === '1'
            ? calculateTravelAllowance(data)
            : new Dinero();
    const residence =
        data[mobilityFormTexts.fields.reqResidenceAllowance.name] === '1'
            ? calculateResidenceAllowance(data)
            : new Dinero();
    const prePost =
        data[mobilityFormTexts.fields.reqPrePostProcessing.name] === '1'
            ? calculatePrePostAllowance(data, allowance.processing)
            : new Dinero();

    return travel.add(residence).add(prePost);
}

export function generateMobilitySaveText(result, financialPlan) {
    if (result === 'post') {
        return `Die Mobilität nach '${financialPlan}' wurde angelegt`;
    }
    return `Die Mobilität nach '${financialPlan}' wurde gespeichert`;
}

export function changeMobility(oldData, index, newData) {
    const tmp = [...oldData];
    tmp[index] = newData;
    return tmp;
}

export function getPickerDefaultValues(currentApplicationRound, duration, now) {
    if (duration.start === null) {
        const proposalTime = getProposalDuration(currentApplicationRound);
        const start =
            moment(proposalTime.start) < now.startOf('day')
                ? now.startOf('day')
                : moment(proposalTime.start);

        return { start, end: null };
    }
    return { start: moment(duration.start), end: moment(duration.end) };
}
