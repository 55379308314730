import React from 'react';
import PropTypes from 'prop-types';
import { texts as pft } from '../../../texts/Forms/ProjectFormTexts';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useForm } from 'react-hook-form';
import Typography from '@material-ui/core/Typography';
import RHFTextField from '../../Partials/Form/RHFTextField';
import TextfieldWithCounter from '../../Partials/Form/TextfieldWithCounter';
import CheckboxWithInfoText from '../../Partials/Form/CheckboxWithInfoText';
import TextBlock from '../../TextBlock/TextBlock';
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
    marginBot: {
        marginBottom: theme.spacing(2),
    },
}));

const ProjectForm = (props) => {
    const { defaultValues, error, saving, submitFunc, proposalId } = props;
    const classes = useStyles();
    const { control, setError, handleSubmit, errors, register } = useForm({
        defaultValues: defaultValues,
    });
    const [isFirstProposal, setIsFirstProposal] = React.useState(
        defaultValues.isFirstProject
    );

    React.useEffect(() => {
        if (!error) return;
        const violations = error.response?.data?.violations;
        if (violations) {
            violations.forEach((violation) => {
                setError(violation.propertyPath, {
                    type: 'manual',
                    message: violation.message,
                });
            });
        }
    }, [error, setError]);

    const handleSubmitCallback = (data) => {
        submitFunc({ proposalId, data });
    };

    return (
        <>
            <Typography variant="h5" component="h2" gutterBottom>
                {pft.title}
            </Typography>
            <form
                id="mainForm"
                onSubmit={handleSubmit(handleSubmitCallback)}
                noValidate
            >
                <RHFTextField
                    className={classes.marginBot}
                    label={pft.fields.title.label}
                    control={control}
                    name="title"
                    disabled={saving}
                    variant={'outlined'}
                    error={errors.title}
                    validation={{ required: pft.fields.title.error }}
                />
                <CheckboxWithInfoText
                    onChange={(event, checked) => {
                        setIsFirstProposal(checked);
                    }}
                    register={register}
                    label={pft.fields.isFirstProposal.label}
                    name="isFirstProject"
                    defaultChecked={defaultValues.isFirstProject}
                    gutterBottom
                />
                {isFirstProposal && (
                    <RHFTextField
                        className={classes.marginBot}
                        label={pft.fields.heardAbout.label}
                        control={control}
                        name="heardAbout"
                        variant={'outlined'}
                        error={errors.title}
                        validation={{
                            required: pft.fields.heardAbout.error,
                        }}
                    />
                )}
                <Alert severity="info">{pft.info.duration}</Alert>
                <Typography style={{ fontWeight: 'bold' }}>
                    {pft.headlines.duration}:
                </Typography>
                <Typography gutterBottom style={{ marginLeft: '20px' }}>
                    {pft.duration(defaultValues)}
                </Typography>
                <Typography style={{ fontWeight: 'bold' }}>
                    {pft.headlines.projectNumber}:
                </Typography>
                <Typography gutterBottom style={{ marginLeft: '20px' }}>
                    {defaultValues.projectId}
                </Typography>
                <Typography variant="h5" component="h2" gutterBottom>
                    {pft.headlines.relevance}
                </Typography>
                <Typography
                    className={classes.mt}
                    variant="h6"
                    component="h3"
                    style={{ marginTop: '16px' }}
                    gutterBottom
                >
                    {pft.fields.partnership.title}
                </Typography>
                <TextfieldWithCounter
                    multiline
                    variant="outlined"
                    rows={6}
                    name="partnership"
                    label={
                        <TextBlock textBlock={pft.fields.partnership.label} />
                    }
                    register={register}
                    max={pft.fields.partnership.maxLength}
                    defaultValue={defaultValues.partnership}
                    validation={{
                        required: pft.fields.partnership.error,
                    }}
                    error={errors.partnership}
                />
                <Typography
                    className={classes.mt}
                    variant="h6"
                    component="h3"
                    style={{ marginTop: '16px' }}
                    gutterBottom
                >
                    {pft.fields.participant.title}
                </Typography>
                <TextfieldWithCounter
                    style={{ marginBottom: '1rem' }}
                    multiline
                    variant="outlined"
                    rows={6}
                    name="participant"
                    register={register}
                    label={
                        <TextBlock textBlock={pft.fields.participant.label} />
                    }
                    max={pft.fields.participant.maxLength}
                    defaultValue={defaultValues.participant}
                    validation={{
                        required: pft.fields.participant.error,
                    }}
                    error={errors.participant}
                />
                <Typography variant="h5" component="h2" gutterBottom>
                    {pft.headlines.quality}
                </Typography>
                <TextfieldWithCounter
                    style={{ marginBottom: '1rem' }}
                    multiline
                    variant="outlined"
                    rows={5}
                    name="preparation"
                    register={register}
                    placeholder={pft.placeholder.preparation_only}
                    label={
                        <TextBlock textBlock={pft.fields.preparation.label} />
                    }
                    max={pft.fields.preparation.maxLength}
                    defaultValue={defaultValues.preparation}
                    validation={{
                        required: pft.fields.preparation.error,
                    }}
                    error={errors.preparation}
                />
                <TextfieldWithCounter
                    style={{ marginBottom: '1rem' }}
                    multiline
                    variant="outlined"
                    rows={5}
                    name="stay"
                    register={register}
                    placeholder={pft.placeholder.preparation_only}
                    label={<TextBlock textBlock={pft.fields.stay.label} />}
                    max={pft.fields.stay.maxLength}
                    defaultValue={defaultValues.stay}
                    validation={{
                        required: pft.fields.stay.error,
                    }}
                    error={errors.stay}
                />
                <TextfieldWithCounter
                    style={{ marginBottom: '1rem' }}
                    multiline
                    variant="outlined"
                    rows={4}
                    name="support"
                    register={register}
                    placeholder={pft.placeholder.preparation_only}
                    label={<TextBlock textBlock={pft.fields.support.label} />}
                    max={pft.fields.support.maxLength}
                    defaultValue={defaultValues.support}
                    validation={{
                        required: pft.fields.support.error,
                    }}
                    error={errors.support}
                />
                <TextfieldWithCounter
                    style={{ marginBottom: '1rem' }}
                    multiline
                    variant="outlined"
                    rows={4}
                    name="responsibility"
                    register={register}
                    placeholder={pft.placeholder.preparation_only}
                    label={
                        <TextBlock
                            textBlock={pft.fields.responsibility.label}
                        />
                    }
                    max={pft.fields.responsibility.maxLength}
                    defaultValue={defaultValues.responsibility}
                    validation={{
                        required: pft.fields.responsibility.error,
                    }}
                    error={errors.responsibility}
                />
                <TextfieldWithCounter
                    style={{ marginBottom: '1rem' }}
                    multiline
                    variant="outlined"
                    rows={4}
                    name="preparationProject"
                    register={register}
                    placeholder={pft.placeholder.when_required}
                    label={
                        <TextBlock
                            textBlock={pft.fields.preparationProposal.label}
                        />
                    }
                    max={pft.fields.preparationProposal.maxLength}
                    defaultValue={defaultValues.preparationProject}
                    error={errors.preparationProposal}
                />
                <TextfieldWithCounter
                    style={{ marginBottom: '1rem' }}
                    multiline
                    variant="outlined"
                    rows={4}
                    name="accompany"
                    register={register}
                    placeholder={pft.placeholder.when_required}
                    label={<TextBlock textBlock={pft.fields.accompany.label} />}
                    max={pft.fields.accompany.maxLength}
                    defaultValue={defaultValues.accompany}
                    error={errors.accompany}
                />
                <Typography variant="h5" component="h2" gutterBottom>
                    {pft.headlines.cause}
                </Typography>
                <TextfieldWithCounter
                    style={{ marginBottom: '1rem' }}
                    multiline
                    variant="outlined"
                    rows={4}
                    name="evaluation"
                    register={register}
                    label={
                        <TextBlock textBlock={pft.fields.evaluation.label} />
                    }
                    max={pft.fields.evaluation.maxLength}
                    defaultValue={defaultValues.evaluation}
                    validation={{
                        required: pft.fields.evaluation.error,
                    }}
                    error={errors.evaluation}
                />
                <TextfieldWithCounter
                    style={{ marginBottom: '1rem' }}
                    multiline
                    variant="outlined"
                    rows={4}
                    name="result"
                    register={register}
                    label={<TextBlock textBlock={pft.fields.result.label} />}
                    max={pft.fields.result.maxLength}
                    defaultValue={defaultValues.result}
                    validation={{
                        required: pft.fields.result.error,
                    }}
                    error={errors.result}
                />
                <TextfieldWithCounter
                    multiline
                    variant="outlined"
                    rows={4}
                    name="strategy"
                    register={register}
                    label={<TextBlock textBlock={pft.fields.strategy.label} />}
                    max={pft.fields.strategy.maxLength}
                    defaultValue={defaultValues.strategy}
                    validation={{
                        required: pft.fields.strategy.error,
                    }}
                    error={errors.strategy}
                />
                <input type="hidden" name="type" value="1" ref={register()} />
            </form>
        </>
    );
};

ProjectForm.propTypes = {
    submitFunc: PropTypes.func.isRequired,
    defaultValues: PropTypes.object,
    saving: PropTypes.bool,
    error: PropTypes.object,
    proposalId: PropTypes.string.isRequired,
};

ProjectForm.defaultProps = {
    defaultValues: {},
    error: null,
    saving: false,
};

export default ProjectForm;
