import makeStyles from '@material-ui/core/styles/makeStyles';

export const useMobilityListStyles = makeStyles((theme) => ({
    maxWidth: {
        width: '100%',
    },
    noWrap: {
        whiteSpace: 'nowrap',
    },
    table: {
        maxHeight: 500,
    },
}));
