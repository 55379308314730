import React from 'react';
import PropTypes from 'prop-types';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import EditIcon from '@material-ui/icons/Edit';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import moment from 'moment';
import Dinero from 'dinero.js';
import { mobilityFormTexts } from '../../../../texts/Forms/MobilityFormTexts';

const MobilityListRow = (props) => {
    const { data, index, disabled, onDelete, onEdit } = props;

    const handleEditClick = () => {
        onEdit(index);
    };

    const handleDeleteClick = () => {
        onDelete(index);
    };

    return (
        <TableRow key={data.id}>
            <TableCell>
                <ButtonGroup color="primary" size="small">
                    <Button
                        aria-label={`Mobilität ${index + 1} bearbeiten`}
                        onClick={handleEditClick}
                        disabled={disabled}
                    >
                        <EditIcon />
                    </Button>
                    <Button
                        aria-label={`Mobilität ${index + 1} löschen`}
                        onClick={handleDeleteClick}
                        disabled={disabled}
                    >
                        <DeleteIcon />
                    </Button>
                </ButtonGroup>
            </TableCell>
            <TableCell>{data.financialPlan.country}</TableCell>
            <TableCell>
                {mobilityFormTexts.fields.type.options[data.type].label}
            </TableCell>
            <TableCell>{data.attendees}</TableCell>
            <TableCell>
                {moment(data.duration[0]).format('DD.MM.yyyy')}
            </TableCell>
            <TableCell>
                {moment(data.duration[1]).format('DD.MM.yyyy')}
            </TableCell>
            <TableCell>
                {Dinero({ amount: parseInt(data.total) }).toFormat()}
            </TableCell>
        </TableRow>
    );
};

MobilityListRow.propTypes = {
    data: PropTypes.object.isRequired,
    index: PropTypes.number,
    disabled: PropTypes.bool,
    onEdit: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
};

export default MobilityListRow;
