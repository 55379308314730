import { Response } from '../../../util/DataTypes/Response';
import { api } from '../AxiosConfig/Config';
import {
    teamFetchMutator,
    userCreateMutator,
    userPutMutator,
} from './TeamMutators';
import { queryCache } from '../../../QueryCache';
import { serverErrorTexts } from '../../../texts/ServerErrorTexts';

export function getTeam(company) {
    return function () {
        return new Promise(async (resolve, reject) => {
            try {
                const { data } = await api().get(`/companies/${company}/users`);
                resolve(
                    data['hydra:member'].map((user) => teamFetchMutator(user))
                );
            } catch (e) {
                reject(Response.Error('SomeError'));
            }
        });
    };
}

export function createMember(values) {
    return new Promise(async (resolve, reject) => {
        try {
            const { data } = await api().post(
                '/action/create-contact',
                userCreateMutator(values)
            );

            const team = queryCache.getQueryData('team') || [];
            queryCache.setQueryData(
                ['team'],
                [
                    ...team.filter((member) => member.id !== values.id),
                    teamFetchMutator(data),
                ]
            );

            resolve(
                Response.Success('Der neue Benutzer wurde erfolgreich angelegt')
            );
        } catch (e) {
            const message = getServerErrorMessage(
                e,
                'Der neue Benutzer konnte nicht angelegt werden'
            );
            reject(Response.Error(message));
        }
    });
}

function getServerErrorMessage(exception, defaultMessage) {
    let message = defaultMessage;
    if (exception.response?.data?.violations?.length > 0) {
        message = '';
        const violations = exception.response.data.violations;
        for (const violation of violations) {
            message += `${serverErrorTexts[violation.propertyPath]}: ${
                violation.message
            }, `;
        }
        message = message.slice(0, -2);
    }
    return message;
}

export function putMember(values) {
    return new Promise(async (resolve, reject) => {
        try {
            const { data } = await api().put(
                `/users/${values.id}`,
                userPutMutator(values)
            );
            const team = queryCache.getQueryData('team') || [];
            queryCache.setQueryData(
                ['team'],
                [
                    ...team.filter((member) => member.id !== values.id),
                    teamFetchMutator(data),
                ]
            );

            resolve(
                Response.Success('Der Benutzer wurde erfolgreich bearbeitet')
            );
        } catch (e) {
            const message = getServerErrorMessage(
                e,
                'Der Benutzer konnte nicht gespeichert werden'
            );
            reject(Response.Error(message));
        }
    });
}

export function editUserApplicationRounds({ id, applicationRounds, company }) {
    return new Promise(async (resolve, reject) => {
        try {
            await api().put(`/users/${id}`, { applicationRounds });
            queryCache.setQueryData(['team'], await getTeam(company.id)());

            resolve(
                Response.Success(
                    'Die Antragsrunde(n) wurde(n) erfolgreich bearbeitet'
                )
            );
        } catch (e) {
            const message = getServerErrorMessage(
                e,
                'Die Antragsrunde(n) konnten nicht gespeichert werden'
            );
            reject(Response.Error(message));
        }
    });
}

export function handleFormSubmit(values) {
    return new Promise(async (resolve, reject) => {
        try {
            if (values.isNew) {
                const res = await createMember(values);
                resolve(res);
                return;
            }

            const res = await putMember(values);
            resolve(res);
        } catch (e) {
            reject(e);
        }
    });
}

//TODO: Error messages
export function lockMember({ id, isActive }) {
    return new Promise(async (resolve, reject) => {
        try {
            const { data } = await api().put(`/users/${id}`, {
                isActive: !isActive,
            });
            const team = queryCache.getQueryData('team') || [];
            queryCache.setQueryData(
                ['team'],
                [
                    ...team.filter((member) => member.id !== id),
                    teamFetchMutator(data),
                ]
            );
            resolve(
                Response.Success(
                    `Der Zugang wurde erfolgreich ${
                        isActive ? 'deaktiviert' : 'aktiviert'
                    }`
                )
            );
        } catch (e) {
            const message = getServerErrorMessage(
                e,
                `Der Zugang konnte nicht ${
                    isActive ? 'deaktiviert' : 'aktiviert'
                } werden`
            );
            reject(Response.Error(message));
            reject(Response.Error('Konnte nicht sperren'));
        }
    });
}
