import React from 'react';
import DashboardAnon from '../../components/Layout/Dashboard/DashboardAnon';
import { useAuth } from '../../stores/Auth.store';
import { getUserState, UserState } from '../../util/Helper/UserState';
import DashboardError from '../../components/Layout/Dashboard/DashboardError';
import { texts as dt } from '../../texts/DashboardTexts';
import Dashboard from '../../components/Layout/Dashboard/Dashboard';

const DashboardContainer = () => {
    const user = useAuth((state) => state.user);

    if (!user) {
        return <DashboardAnon lines={dt.anonLines} />;
    }

    const userState = getUserState(user);
    if (userState === UserState.NOT_ACTIVE) {
        return <DashboardError {...dt.deactivated} />;
    }

    if (userState === UserState.NOT_CONFIRMED) {
        return <DashboardError {...dt.notConfirmed} />;
    }

    if (userState === UserState.NOT_APPROVED) {
        return <DashboardError {...dt.notApproved} />;
    }

    if (userState === UserState.MUST_CHANGE_PASSWORD) {
        return <DashboardError {...dt.passwordChange} />;
    }

    return <Dashboard />;
};

export default DashboardContainer;
