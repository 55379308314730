import React from 'react';
import PropTypes from 'prop-types';
import uniqid from 'uniqid';
import TableRow from '@material-ui/core/TableRow';
import { TableCell } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

const TableRowLoading = (props) => {
    const { rows } = props;

    const rndId = uniqid();

    return (
        <>
            {Array(rows)
                .fill(0)
                .map((_, id) => (
                    <TableRow key={`${rndId}_${id}`}>
                        <TableCell colSpan={1000}>
                            <Skeleton />
                        </TableCell>
                    </TableRow>
                ))}
        </>
    );
};

TableRowLoading.propTypes = {
    rows: PropTypes.number,
};

TableRowLoading.defaultProps = {
    rows: 5,
};

export default React.memo(TableRowLoading);
