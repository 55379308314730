import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from 'react-query';
import FullContentOverlayLoader from '../../../components/Partials/Loader/FullContentOverlayLoader';
import WizardFormError from '../../../components/Partials/Error/WizardFormError';
import ContactPersonForm from '../../../components/Forms/ContactPerson/ContactPersonForm';
import { getTeam } from '../../../lib/api/Team/TeamActions';
import { useAuth } from '../../../stores/Auth.store';

const ContactPersonFormContainer = (props) => {
    const { proposalId } = props;
    const { company } = useAuth((state) => state.user);

    const { isLoading, data, error, refetch, isFetching } = useQuery(
        [proposalId, 'contactPersonForm'],
        getTeam(company.id),
        { initialStale: true, refetchOnWindowFocus: false }
    );

    if (isLoading || isFetching)
        return <FullContentOverlayLoader loading={true} />;

    if (error) return <WizardFormError error={error} refetch={refetch} />;

    return (
        <ContactPersonForm
            mutate={props.save}
            error={props.saveError}
            defaultValues={data}
            saving={props.isSaving}
        />
    );
};

ContactPersonFormContainer.propTypes = {
    proposalId: PropTypes.string,
};

export default ContactPersonFormContainer;
