import React from 'react';
import PropTypes from 'prop-types';

const NotAllowed = (props) => {
    //TODO: Component

    return <p>NOT ALLOWED</p>;
};

//TODO: Check if needed after rework
NotAllowed.propTypes = {
    tmp: PropTypes.object,
};

export default NotAllowed;
