import makeStyles from '@material-ui/core/styles/makeStyles';

export const useTeamTableRowStyles = makeStyles((theme) => ({
    row: {
        textDecoration: 'line-through',
        backgroundColor: theme.palette.grey['300'],
        '&:hover': {
            textDecoration: 'none',
            backgroundColor: theme.palette.grey['100'],
        },
    },
}));
